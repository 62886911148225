<template>
  <div>
    <!-- 基础信息   -->
    <ECard
      :title="$t('order.report.info')"
      :isShowIcon="false"
      class="base-info"
    >
      <a-form-model
        layout="inline"
        :labelCol="formItemLayout.labelCol"
        :wrapperCol="formItemLayout.wrapperCol"
      >
        <a-row :gutter="[16, 16]">
          <!--客户名称-->
          <a-col :span="8">
            <a-form-item
              :label="$t('order.detail.info.custom')"
              class="base-info-row"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                v-model="companyId"
                size="large"
                @change="onCompanySelect"
                v-if="multipleCompany"
                :disabled="
                  ![
                    BACK_ORDER_PROGRESS[0].key,
                    BACK_ORDER_PROGRESS[1].key
                  ].includes(progressActive)
                "
              >
                <a-select-option
                  v-for="item in companyList"
                  :key="item.companyId"
                  :value="item.companyId"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-input
                size="large"
                :value="info.companyName"
                readOnly
                v-else
              />
            </a-form-item>
          </a-col>
          
          <!--报告发送邮箱-->
          <a-col :span="8" v-for="i in baseInfo" :key="i.label">
            <a-form-item :label="i.label" class="base-info-row">
              <a-input size="large" :value="info[i.key]" readOnly />
            </a-form-item>
          </a-col>

          <!--抄送邮箱-->
          <a-col :span="8">
            <a-form-item
              :label="$t('order.detail.info.cc')"
              class="base-info-row"
            >
              <a-select
                class="base-info-top-cc-email-selector"
                :getPopupContainer="trigger => trigger.parentNode"
                v-model="address"
                mode="multiple"
                size="large"
                @change="onSelectCCEmail"
                :disabled="[BACK_ORDER_PROGRESS[3].key].includes(progressActive)"
                :showSearch="false"
              >
                <div slot="dropdownRender" slot-scope="menu">
                  <v-nodes :vnodes="menu" />
                  <a-divider style="margin: 4px 0" />
                  <div
                    style="padding: 4px 8px; cursor: pointer;font-size: 16px;"
                    @mousedown="e => e.preventDefault()"
                    @click="addItem"
                  >
                    <a-icon type="plus" /> {{ $t("order.detail.info.addcc") }}
                  </div>
                </div>
                <a-select-option
                  v-for="item in ccEmailList"
                  :key="item.email"
                  :value="item.email"
                >
                  {{ item.email }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-model>
    </ECard>
    <!--  抄送地址弹窗  -->
    <AddressModal
      :tableList="ccEmailList"
      :isShowModal.sync="isAddressModal"
      v-if="isAddressModal"
      @change="getCcEmailList"
    ></AddressModal>
  </div>
</template>

<script>
import ECard from "@/components/ECard";
import AddressModal from "./AddressModal";
import { CREATE_TYPE } from "@/pages/home/order/config";
import { mapGetters, mapState } from "vuex";
import { BACK_ORDER_PROGRESS } from "@/pages/workspace/backgroundCheck/order/config";
import { langMixin } from "@/mixins";

export default {
  name: "BaseInfo",
  components: {
    AddressModal,
    ECard,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
    type: {
      type: String,
      required: true,
      default: CREATE_TYPE.TRADITION
    }
  },
  mixins: [langMixin],
  data() {
    return {
      BACK_ORDER_PROGRESS,
      baseInfo: [
        {
          label: this.$t("order.detail.info.address"),
          key: "sendReportEmail"
        }
      ],
      isAddressModal: false,
      info: {}
    };
  },
  computed: {
    ...mapGetters("user", ["multipleCompany", "companyList"]),
    ...mapState("back", ["progressActive", "ccEmailList"]),
    address: {
      get() {
        return this.$store.state.back.ccEmailSelectedList;
      },
      set(val) {
        this.$store.commit("back/setCcEmailSelectedList", val);
      }
    },
    companyId: {
      get() {
        return this.$store.state.back.companyId;
      },
      set(val) {
        this.$store.commit("back/setCompanyId", val);
      }
    },
    formItemLayout() {
      const xl = this.calcFormItemLayout([9, 9]);
      const xxl = this.calcFormItemLayout([8, 9]);
      return {
        labelCol: { xl: xl.labelCol.span, xxl: xxl.labelCol.span },
        wrapperCol: { xl: xl.wrapperCol.span, xxl: xxl.wrapperCol.span }
      };
    }
  },
  methods: {
    // 添加抄送地址
    addItem() {
      this.isAddressModal = true;
    },
    async getInfo() {
      this.info = {
        companyName: this.$store.state.user.info.currentCompany.name,
        sendReportEmail: this.$store.state.user.info.reportReceiveEmailAddress,
      };

      if (!this.info.sendReportEmail) {
        this.info.sendReportEmail = this.$store.state.user.info.emailAddress
      }

      if (this.multipleCompany) {
        let defaultCompanyId = this.companyList[0]?.companyId;
        if (
          this.companyId &&
          this.companyList.some(it => it.companyId === this.companyId)
        ) {
          defaultCompanyId = this.companyId;
        }
        if (defaultCompanyId) {
          this.onCompanySelect(defaultCompanyId);
          this.companyId = defaultCompanyId;
        }
      }
      this.$store.dispatch("back/getBasicData", {
        companyId: this.companyId
      });
    },
    // 新增抄送邮箱确定事件
    getCcEmailList(list) {
      this.$store.commit("back/setCcEmailList", list);
      this.address = list.map(it => it.email);
    },
    getParams() {
      return { ...this.info, address: this.address };
    },
    onSelectCCEmail() {},
    onCompanySelect(value) {
      const curr = this.companyList.find(it => it.companyId === value);
      this.info.companyName = curr?.name ?? null;
      // 切换客户名称 清空所选模板
      this.$store.commit("back/setSelectedTemplate", {});
      this.$store.dispatch("back/getBasicData", {
        companyId: value
      });
    }
  },
  mounted() {
    this.getInfo();
    setTimeout(() => {
      const selector = document.querySelector(".base-info-top-cc-email-selector");
      if (!selector) return;
      const field = selector.querySelector(".ant-select-search__field");
      if (!field) return;
      field.setAttribute("readonly", "true");
    }, 0);
  }
};
</script>

<style lang="less" scoped>
.base-info {
  &-row {
    display: flex;
    align-items: center;
  }
}
/deep/.ant-form-item-control-wrapper {
  flex: 1;
}
</style>
