<template>
  <div class="candidate-list">
    <!--<a-tabs @change="tabChange" v-model="currentTabKey">
      <a-tab-pane
        :tab="i.title"
        v-for="(i, idx) in tabList"
        :key="i.key"
        class="tab-item"
      >-->
    <div v-for="(i, idx) in tabList" :key="i.key">
      <!-- 筛选区 -->
      <ListFilter
        :form-data.sync="currentFilterData"
        :form-list="i.filterList"
        :title="''"
        class="list-filter can-manage-list-filter"
        @reset="() => onResetFilter(idx)"
        @filter="form => onFilter(i.key, form)"
      >
        <!--#region 所属机构-->
        <a-form-model-item slot="companyId" :label="$t('bill.filter.org')">
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            :value="candidateTabFilters[idx].filters.targetCompanyId"
            @change="
              companyId =>
                onFilter(
                  i.key,
                  { ...currentFilterData, targetCompanyId: companyId },
                  true
                )
            "
            :placeholder="$t('bill.filter.org')"
            allowClear
          >
            <a-select-option
              v-for="op in companyList"
              :key="op.companyId"
              :value="op.companyId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
        <!--#region 委托HR-->
        <a-form-model-item slot="targetUserId" :label="$t('order.filter.hr')">
          <a-select
            show-search
            :filter-option="(input, option)=>{
              return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
              )}"
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            :value="candidateTabFilters[idx].filters.targetUserId"
            @change="
              targetUserId =>
                onFilter(i.key, { ...currentFilterData, targetUserId }, true)
            "
            :placeholder="$t('order.filter.hr')"
            allowClear
          >
            <a-select-option v-for="op in hrList" :key="op.id" :value="op.id">
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
        <!--#region 记录来源-->
        <a-form-model-item
          slot="registrationSource"
          :label="$t('backCheck.registrationSource')"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            :value="candidateTabFilters[idx].filters.registrationSource"
            @change="
              registrationSource =>
                onFilter(
                  i.key,
                  { ...currentFilterData, registrationSource },
                  true
                )
            "
            :placeholder="
              `${$t('select')}${$t('backCheck.registrationSource')}`
            "
            allowClear
          >
            <a-select-option
              v-for="op in registrationSourceList"
              :key="op.key"
              :value="op.key"
            >
              {{ op.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
        <!--#region 报告版本:已废弃-->
        <a-form-model-item
          slot="packageName"
          :label="$t('backCheck.merged.packageName')"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            :value="candidateTabFilters[idx].filters.packageName"
            @change="
              packageName =>
                onFilter(i.key, { ...currentFilterData, packageName }, true)
            "
            :placeholder="$t('backCheck.merged.packageName')"
            allowClear
          >
            <a-select-option
              v-for="op in packageList"
              :key="op.id"
              :value="op.name"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
      </ListFilter>
      <!-- 表格 -->
      <ECTable
        :columns="i.tableColumns"
        :data-source="i.tableList"
        bordered
        showTitle
        title=" "
        :loading="loading"
        :pagination="{ ...candidateTabFilters[idx].page }"
        :scroll="{ x: true }"
        :row-selection="
          ![1, 3, 4].includes(i.key)
            ? {
                selectedRowKeys: i.selectedIds,
                onChange: (ids, rows) => onSelectChange(ids, rows, idx)
              }
            : {}
        "
        @change="page => onTableChange(i, page)"
      >
        <!-- 表头操作区 -->
        <div slot="tableHeaderRight" class="button-flex">
          <!-- 全部候选人 -->
          <a-button type="primary" @click="add" v-if="i.key === 1">
            {{ $t("button.add") }}
          </a-button>
          <!-- 到场记录 -->
          <template v-if="i.key === 2">
            <!-- 新建到场记录 -->
            <a-button type="primary" @click="addPresent">
              {{ `${$t("button.add")}${$t("backCheck.dctitle")}` }}
            </a-button>
            <!-- 批量导入到场 -->
            <a-button type="primary" @click="batchImport">
              {{ $t("backCheck.hbatchdc") }}
            </a-button>
          </template>
          <!-- 易查记录-->
          <template v-if="i.key === 3">
            <!-- 新建快速 -->
            <a-button type="primary" @click="addBacktone">
              {{ $t("backCheck.order") }}
            </a-button>
            <!--<a-button type="primary" @click="batchImport">
                  {{ $t("button.addBatchQuick") }}
                </a-button>-->
          </template>
          <!-- 委托记录-->
          <template v-if="i.key === 4">
            <!-- 新建委托 -->
            <a-button type="primary" @click="addOrder">
              {{ $t("backCheck.order") }}
            </a-button>
            <!-- 批量新建 -->
            <!--<a-button type="primary" @click="batchImport">
                  {{ $t("button.batchAdd") }}
                </a-button>-->
          </template>
          <!-- 导出 -->
          <a-button class="export-btn" @click="handleExport">{{
            $t("button.sexport")
          }}</a-button>
        </div>
        <!-- 到场记录-授权状态 -->
        <template slot="authStatus" slot-scope="text, record">
          <div
            v-if="record.authFileId || record.authorizationTime"
            class="ec-color-success"
          >
            {{ $t("backCheck.done") }}
          </div>
          <div v-else class="ec-color-danger">
            {{ $t("backCheck.undo") }}
          </div>
        </template>
        <!-- 委托记录-报告级别 -->
        <template slot="reportLevel" slot-scope="text, record">
          <span
            class="ec-report-report-level"
            :class="`ec-report-report-level-${record.reportLevelCode}`"
            :title="record.reportLevelName"
          />
        </template>
        <!-- 自定义表格 footer -->
        <div slot="footerCustom" v-if="i.key !== 1">
          <!-- 批量委托 -->
          <!--<a-button
                @click="batchOrder"
                type="primary"
                ghost
                v-show="i.key === 3"
              >
                {{ $t("backCheck.hbatchorder") }}
              </a-button>-->
          <!-- 批量背调 -->
          <a-button
            type="primary"
            ghost
            v-show="i.key === 2"
            @click="batchBack"
          >
            {{ $t("backCheck.order") }}
          </a-button>
        </div>
        <!-- 电话 -->
        <template slot="phone" slot-scope="text, record">
          {{
            record.phoneDialingCode
              ? `+${record.phoneDialingCode} ${record.candidateMobileNumber}`
              : record.candidateMobileNumber
          }}
        </template>
        <!-- 候选人姓名 -->
        <template slot="candidateName" slot-scope="text, record">
          <router-link :to="`/message/schedule/${record.taskNo}`">
            {{ record.candidateName }}
          </router-link>
        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="text, record">
          <!--  slot-scope="text, record" -->
          <div class="editable-row-operations">
            <a-dropdown :trigger="['click', 'contextmenu']">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                {{ $t("action") }}
              </a>
              <!--#region 全部候选人-->
              <template v-if="i.key === 1">
                <a-menu
                  slot="overlay"
                  @click="e => handleMenuClick(e, record, i.key)"
                >
                  <a-menu-item
                    :key="menu.key"
                    v-for="menu in [tableActions[0], tableActions[2]]"
                  >
                    {{ menu.name }}
                  </a-menu-item>
                </a-menu>
              </template>
              <!--#endregion-->
              <!--#region 到场记录-->
              <template v-if="i.key === 2">
                <a-menu
                  slot="overlay"
                  @click="e => handleMenuClick(e, record, i.key)"
                >
                  <a-menu-item
                    :key="menu.key"
                    v-for="menu in [tableActions[1], tableActions[2]]"
                  >
                    {{ menu.name }}
                  </a-menu-item>
                </a-menu>
              </template>
              <!--#endregion-->
              <!--#region 易查记录 委托列表-->
              <template v-if="[3, 4].includes(i.key)">
                <a-menu
                  slot="overlay"
                  @click="e => handleMenuClick(e, record, i.key)"
                >
                  <a-menu-item :key="tableActions[3].key">
                    {{ tableActions[3].name }}
                  </a-menu-item>
                  <a-menu-item
                    :key="tableActions[4].key"
                    v-if="
                      [
                        CssTaskProgress[5].value,
                        CssTaskProgress[6].value
                      ].includes(record.taskProgressId)
                    "
                  >
                    {{ tableActions[4].name }}
                  </a-menu-item>
                  <a-menu-item
                    :key="tableActions[5].key"
                    v-if="
                      [
                        CssTaskProgress[0].value,
                        CssTaskProgress[1].value,
                        CssTaskProgress[2].value
                      ].includes(record.taskProgressId) &&
                        record.taskStateId !== 287
                    "
                  >
                    {{ tableActions[5].name }}
                  </a-menu-item>
                  <a-menu-item
                    :key="tableActions[6].key"
                    v-if="i.key === 4 && isGrantGiftBtnVisible(record)"
                  >
                    {{ tableActions[6].name }}
                  </a-menu-item>
                </a-menu>
              </template>
              <!--#endregion-->
            </a-dropdown>
          </div>
        </template>
      </ECTable>
    </div>
    <!--</a-tab-pane>
    </a-tabs>-->
    <!-- 批量导入候选人 -->
    <BatchModal
      @success="getList"
      :is-batch-modal.sync="isBatchModal"
      v-if="isBatchModal"
      :submit="onBatchSubmit"
      :uploadUrl="files.uploadUrl"
      :downloadUrl="files.downloadUrl"
      :uploadData="files.orderType ? { orderType: files.orderType } : null"
    />
    <!-- 短信验证 -->
    <SMSModal :isHrModal.sync="isHrModal" :params="exportParams" />
    <!-- 授权 modal -->
    <a-modal
      :width="560"
      @cancel="authorizeCancel"
      @ok="onAuthorizeOk"
      class="authorize-modal"
      :visible="isAuthorizeModal"
      :title="$t('backCheck.hbatchbd')"
    >
      <a-form-model
        ref="authorize"
        :model="authorize"
        :rules="authorizeRules"
        layout="inline"
      >
        <a-form-model-item :label="$t('backCheck.hauthtype')" prop="type">
          <div class="authorize-method">
            <div
              class="authorize-method-item"
              v-for="i in authorizeTypeList"
              :key="i.value"
              @click="changeAuthorizeSelect(i.value)"
            >
              <i
                :class="[
                  `iconfont ${
                    authorize.type === i.value
                      ? 'icon-check-select'
                      : 'icon-check-default'
                  }`
                ]"
              />
              <span class="authorize-method-item-text">{{ i.title }}</span>
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 候选人资料管理 modal -->
    <CandidateFileModal
      :record="fileModalData"
      :visible.sync="fileModalVisible"
    />
    <StopCheckModal
      :isShowModal.sync="isStopModal"
      :record="currentItem"
      @reload="getList"
    />
    <!--入职礼发放弹窗-->
    <GrantGiftModal :visible.sync="isGrantGiftVisible" />
    <!--操作指引 - 合并易查记录和委托记录 key=1 由于再次迭代，删除合并tab的操作指引-->
    <!--<div class="guidance-mask candidate-list-1" :class="lang" v-if="guidanceShow1">
      <div class="highlight">
        <a-tabs class="guidance-tabs" v-model="currentTabKey">
          <a-tab-pane
            :tab="i.title"
            v-for="i in tabList"
            :key="i.key"
            class="guidance-tab"
            disabled
          ></a-tab-pane>
        </a-tabs>
      </div>
      <a-icon class="guidance-icon" type="arrow-up" />
      <div class="guidance-content">
        <div class="guidance-tips">{{ $t("backCheck.merged.guidance") }}</div>
        <a-button type="default" size="large" shape="round" ghost @click="onGuidanceClose('1')">
          {{ $t("backCheck.merged.guidanceOk") }}
        </a-button>
      </div>
    </div>-->
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter";
import BatchModal from "@/pages/home/components/BatchModal";
import { reportLevel } from "@/pages/home/data";
import ECTable from "@/components/ECTable";
import SMSModal from "@/pages/home/components/SMSModal";
import Vue from "vue";
import StopCheckModal from "@/pages/home/components/StopCheckModal";
import {
  addInterviewBatch,
  interviewList,
  interviewSource
} from "@/pages/workspace/backgroundCheck/presentRecord/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { cloneDeep, trim } from "lodash";
import { getCandidateList, getEcheckRecord, listHr } from "@/pages/home/api";
import { orderListPage, orderListSearch } from "@/pages/home/order/api";
import { getFakeId, listDicFormatter, rangeDateFormatter } from "@/utils/tools";
import {
  // CandidateStatus,
  CREATE_TYPE,
  CssStatusList,
  CssTaskProgress,
  ReportLevel
} from "@/pages/home/order/config";
import { mapGetters, mapState } from "vuex";
import CandidateFileModal from "@/pages/home/components/CandidateFile/Modal.vue";
import GrantGiftModal from "@/pages/workspace/backgroundCheck/gift/Grant/Modal.vue";
import { langMixin } from "@/mixins";

// 全部候选人
const columns1 = [
  {
    title: Vue.prototype.$t("backCheck.cname"),
    dataIndex: "candidateName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.cidcard"),
    dataIndex: "idNumber",
    align: "left",
    width: 160,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hconcat"),
    dataIndex: "phone",
    align: "left",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "phone" }
  },
  {
    title: Vue.prototype.$t("backCheck.hr"),
    dataIndex: "hrName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.time"),
    dataIndex: "createTime",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.status"),
    dataIndex: "statusNo",
    align: "center",
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: "authStatus" }
  },
  {
    title: Vue.prototype.$t("message.report.process.title"),
    dataIndex: "cssTaskProgress",
    align: "center",
    width: 140,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.signType"),
    dataIndex: "reportLevel",
    width: 120,
    ellipsis: true,
    align: "center",
    scopedSlots: { customRender: "reportLevel" }
  },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right"
  }
];
// 到场记录
const columns2 = [
  {
    title: Vue.prototype.$t("backCheck.cname"),
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.cidcard"),
    dataIndex: "identityNum",
    align: "left",
    width: 160,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hconcat"),
    dataIndex: "mobile",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.registrationSource"),
    dataIndex: "registrationSourceName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hr"),
    dataIndex: "hrName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.time"),
    dataIndex: "registrationTime",
    width: 140,
    align: "center",
    ellipsis: true
  },
  // {
  //   title: Vue.prototype.$t("backCheck.stepStatus"),
  //   dataIndex: "statusNoName",
  //   width: 120,
  //   align: "center",
  //   ellipsis: true
  // },
  {
    title: Vue.prototype.$t("backCheck.status"),
    dataIndex: "statusNo",
    scopedSlots: { customRender: "authStatus" },
    align: "center",
    width: 100,
    ellipsis: true
  },
  // {
  //   title: Vue.prototype.$t("message.report.process.title"),
  //   dataIndex: "taskProgress",
  //   align: "center",
  //   width: 120,
  //   ellipsis: true
  // },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 100
  }
];
// 易查记录
// const columns3 = [
//   {
//     title: Vue.prototype.$t("backCheck.cname"),
//     dataIndex: "candidateName",
//     align: "left",
//     width: 120,
//     ellipsis: true,
//     scopedSlots: { customRender: "candidateName" }
//   },
//   {
//     title: Vue.prototype.$t("backCheck.cidcard"),
//     dataIndex: "idNumber",
//     align: "left",
//     width: 160,
//     ellipsis: true
//   },
//   {
//     title: Vue.prototype.$t("backCheck.hconcat"),
//     dataIndex: "phone",
//     align: "left",
//     width: 120,
//     ellipsis: true,
//     scopedSlots: { customRender: "phone" }
//   },
//   {
//     title: Vue.prototype.$t("backCheck.hr"),
//     dataIndex: "hrName",
//     align: "left",
//     width: 120,
//     ellipsis: true
//   },
//   {
//     title: Vue.prototype.$t("order.filter.time"),
//     dataIndex: "consignTime",
//     align: "center",
//     width: 140,
//     ellipsis: true
//   },
//   {
//     title: Vue.prototype.$t("backCheck.hcs"),
//     dataIndex: "changeState",
//     align: "center",
//     width: 120,
//     ellipsis: true
//   },
//   {
//     title: Vue.prototype.$t("backCheck.signType"),
//     dataIndex: "reportLevel",
//     width: 120,
//     ellipsis: true,
//     align: "center",
//     scopedSlots: { customRender: "reportLevel" }
//   },
//   {
//     title: Vue.prototype.$t("message.report.process.title"),
//     dataIndex: "cssTaskProgress",
//     align: "center",
//     width: 120,
//     ellipsis: true
//   },
//   {
//     title: Vue.prototype.$t("order.filter.number"),
//     dataIndex: "reportNumbering",
//     align: "left",
//     width: 120,
//     ellipsis: true
//   },
//   {
//     title: Vue.prototype.$t("action"),
//     dataIndex: "action",
//     key: "action",
//     align: "center",
//     scopedSlots: { customRender: "action" },
//     width: 100,
//     fixed: "right"
//   }
// ];
// 背调记录
const columns4 = [
  {
    title: Vue.prototype.$t("backCheck.cname"),
    dataIndex: "candidateName",
    align: "left",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "candidateName" }
  },
  {
    title: Vue.prototype.$t("backCheck.merged.packageName"),
    dataIndex: "templateName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.cidcard"),
    dataIndex: "candidateId",
    align: "left",
    width: 160,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hconcat"),
    dataIndex: "candidateMobileNumber",
    align: "left",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "phone" }
  },
  {
    title: Vue.prototype.$t("backCheck.merged.consignTime"),
    dataIndex: "orderTime",
    align: "center",
    width: 140,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.confirm"),
    dataIndex: "candidateInfoConfirmTime",
    align: "center",
    width: 140,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hprt"),
    dataIndex: "reportTimeStage",
    align: "center",
    width: 150,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.level"),
    dataIndex: "reportLevel",
    align: "center",
    scopedSlots: { customRender: "reportLevel" },
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.number"),
    dataIndex: "taskNumber",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.process"),
    dataIndex: "taskProgressName",
    align: "center",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hcs"),
    dataIndex: "taskStateName",
    align: "center",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.hr"),
    dataIndex: "hrUserName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right"
  }
];

export default {
  name: "CandidateList",
  components: {
    GrantGiftModal,
    CandidateFileModal,
    ListFilter,
    BatchModal,
    ECTable,
    SMSModal,
    StopCheckModal
  },
  mixins: [langMixin],
  data() {
    return {
      tabList: [
        // {
        //   key: 1,
        //   title: this.$t("backCheck.hc"),
        //   tableTitle: this.$t("backCheck.hc"),
        //   filterList: [
        //     {
        //       label: this.$t("backCheck.hname"),
        //       key: "candidateName",
        //       type: "input"
        //     },
        //     {
        //       label: this.$t("backCheck.hphoneShort"),
        //       key: "candidatePhone",
        //       type: "input"
        //     },
        //     {
        //       label: this.$t("backCheck.hstatus"),
        //       key: "status",
        //       type: "select",
        //       options: CandidateStatus
        //     },
        //     {
        //       label: this.$t("order.filter.level"),
        //       key: "reportLevel",
        //       type: "select",
        //       options: ReportLevel
        //     },
        //     {
        //       label: this.$t("message.report.process.title"),
        //       key: "cssTaskProgressId",
        //       type: "select",
        //       options: CssTaskProgress
        //     }
        //     // {
        //     //   label: this.$t("backCheck.hflowup"),
        //     //   key: "cssTaskProgressId",
        //     //   type: "select",
        //     //   options: CssTaskProgress,
        //     // }
        //   ],
        //   tableColumns: columns1,
        //   tableList: [],
        //   selectedRows: [],
        //   selectedIds: [],
        //   files: {
        //     uploadUrl: "",
        //     downloadUrl: ""
        //   }
        // },
        // // {
        // //   key: 2,
        // //   title: this.$t("backCheck.dctitle"),
        // //   filterList: [
        // //     {
        // //       label: this.$t("backCheck.hname"),
        // //       key: "candidateName",
        // //       type: "input"
        // //     },
        // //     {
        // //       label: this.$t("backCheck.hphoneShort"),
        // //       key: "mobile",
        // //       type: "input"
        // //     },
        // //     {
        // //       label: this.$t("echeck.filter.time"),
        // //       key: "registrationTime",
        // //       type: "timeRange"
        // //     },
        // //     {
        // //       label: this.$t("backCheck.registrationSource"),
        // //       slotName: "registrationSource",
        // //       type: "slot",
        // //       key: "registrationSource",
        // //     }
        // //   ],
        // //   tableTitle: this.$t("backCheck.hc"),
        // //   tableColumns: columns2,
        // //   tableList: [],
        // //   selectedRows: [],
        // //   selectedIds: [],
        // //   files: {
        // //     uploadUrl: "/interview/importInterview",
        // //     downloadUrl: "/interview/template"
        // //   }
        // // },
        // // {
        // //   key: 3,
        // //   title: this.$t("backCheck.hecheckr"),
        // //   filterList: [
        // //     {
        // //       label: this.$t("order.filter.hr"),
        // //       slotName: "targetUserId",
        // //       type: "slot",
        // //       key: "targetUserId",
        // //     },
        // //     {
        // //       label: this.$t("backCheck.cname"),
        // //       key: "candidateName",
        // //       type: "input"
        // //     },
        // //     {
        // //       label: this.$t("backCheck.hqt"),
        // //       key: "consignTime",
        // //       type: "timeRange"
        // //     }
        // //   ],
        // //   tableList: [],
        // //   tableColumns: columns3,
        // //   tableTitle: this.$t("backCheck.hc"),
        // //   selectedRows: [],
        // //   selectedIds: [],
        // //   files: {
        // //     uploadUrl: "/commonMgt/upload/candidate",
        // //     downloadUrl: "/commonMgt/candidate/template",
        // //     orderType: CREATE_TYPE.QUICK
        // //   }
        // // },
        {
          key: 4,
          title: this.$t("backCheck.merged.tabTitle"),
          filterList: [
            {
              label: this.$t("backCheck.cname"),
              key: "candidateName",
              type: "input"
            },
            {
              label: this.$t("backCheck.merged.packageName"),
              key: "templateName",
              type: "input"
            },
            {
              label: this.$t("backCheck.merged.consignTime"),
              key: "consignTime",
              type: "timeRange"
            },
            {
              label: this.$t("order.filter.confirm"),
              key: "confirmTime",
              type: "timeRange"
            },
            {
              label: this.$t("order.filter.level"),
              key: "reportLevelId",
              type: "select",
              options: ReportLevel
            },
            {
              label: this.$t("order.filter.number"),
              key: "taskNumber",
              type: "input"
            },
            {
              label: this.$t("order.filter.process"),
              key: "taskProgressId",
              type: "select",
              options: CssTaskProgress
            },
            {
              label: this.$t("order.filter.hr"),
              slotName: "targetUserId",
              type: "slot",
              key: "targetUserId"
            }
          ],
          tableTitle: this.$t("backCheck.hc"),
          tableList: [],
          tableColumns: columns4,
          selectedRows: [],
          selectedIds: [],
          files: {
            uploadUrl: "/commonMgt/upload/candidate",
            downloadUrl: "/commonMgt/candidate/template",
            orderType: CREATE_TYPE.TRADITION
          }
        }
      ],
      tableActions: [
        // 0 查看详情
        {
          key: 1,
          name: this.$t("button.detail"),
          routerName: "MessageProcess"
        },
        // 1 查看网页录入详情
        {
          key: 11,
          name: this.$t("button.detailWeb"),
          routerName: "candidateInfo"
        },
        // 2 新建订单
        {
          key: 2,
          name: this.$t("backCheck.order"),
          routerName: "BackOrderAddTemplate"
        },
        // 3 资料管理
        {
          key: 4,
          name: this.$t("order.table.data")
        },
        // 4 报告详情
        {
          key: 5,
          name: this.$t("order.table.detail")
        },
        // 5 停止核实
        {
          key: 6,
          name: this.$t("order.table.stop")
        },
        // 6 发放入职礼
        {
          key: 7,
          name: this.$t("order.gift.grant")
        }
      ],
      canAddList: [
        {
          label: "面试登记",
          value: 1,
          url: "/present/add"
        },
        {
          label: "新建委托",
          value: 2,
          url: "/order/add"
        },
        {
          label: "新建快速背调",
          value: 3,
          url: "/echeck/add"
        }
      ],
      dcVisible: false,
      isBatchModal: false,
      isHrModal: false,
      exportParams: {},
      // 授权 modal
      isAuthorizeModal: false,
      authorize: {
        type: 1
      },
      authorizeRules: {
        type: [
          {
            required: true,
            message: this.$t("select") + this.$t("backType"),
            trigger: "blur"
          }
        ]
      },
      authorizeTypeList: [
        // 新建到场
        {
          title: this.$t("backCheck.dctitle"),
          value: 3,
          routerPath: "/workspace/background/present-add"
        },
        // 新建订单
        {
          title: this.$t("backCheck.order"),
          value: 1,
          routerPath: "/workspace/background/add"
        }
      ],
      fileModalVisible: false,
      fileModalData: {},
      isStopModal: false,
      isGrantGiftVisible: false,
      currentIndex: "",
      loading: false,
      currentItem: {},
      fileList: [],
      currentFilterData: {},
      CssTaskProgress: cloneDeep(CssTaskProgress),
      hrList: [],
      registrationSourceList: [],
      guidanceShow1: false,
      packageList: []
    };
  },
  computed: {
    currentTabKey: {
      get() {
        return this.$store.state.back.candidateTab;
      },
      set(newValue) {
        this.$store.commit("back/setCandidateTab", newValue);
        this.currentIndex = this.tabList.findIndex(i => i.key === newValue);
      }
    },
    files() {
      return this.tabList.find(i => i.key === this.currentTabKey)?.files || {};
    },
    // 导出id 默认当前页，否则是选择的项
    idList() {
      const { selectedRows, tableList } = this.tabList.find(
        i => i.key === this.currentTabKey
      );
      let selectIds = "";
      if (selectedRows.length) {
        selectIds = selectedRows.map(i => i.indexNo).toString();
      }
      return selectIds.length
        ? selectIds
        : tableList.map(i => i.indexNo).toString();
    },
    ...mapState({
      candidateTabFilters: state => state.back.candidateTabFilters
    }),
    ...mapGetters("user", ["multipleCompany", "companyList"])
  },
  methods: {
    // tab 改变
    async tabChange(key) {
      this.currentTabKey = key;
      const idx = this.tabList.findIndex(it => it.key === key);
      this.candidateTabFilters[idx].filters = this.formatEntrustListParams(
        this.candidateTabFilters[idx].filters
      );
      this.currentFilterData = {
        ...this.candidateTabFilters[idx].filters
      };
      await this.getList(key);
    },
    async onBatchSubmit(list) {
      if (this.currentTabKey === 2) {
        return await addInterviewBatch(list);
      }
      if (this.currentTabKey === 4) {
        return { flag: FETCH_CODE.SUCCESS.KEY, msg: "succeed" };
      }
    },
    // 获取列表
    async getList(currentTabKey) {
      this.loading = true;
      switch (currentTabKey || this.currentTabKey) {
        case 1:
          await this.getCanList();
          break;
        case 2:
          await this.getPresentList();
          break;
        case 3:
          await this.getEcheckList();
          break;
        case 4:
          await this.getEntrustList();
          break;
      }
      this.loading = false;
    },
    // 菜单点击事件
    async handleMenuClick(e, item, tabKey) {
      this.currentItem = item;
      const currMenu = this.tableActions.find(it => it.key === e.key);

      // 资料管理
      if (e.key === this.tableActions[3].key) {
        this.openFiles(item);
        return;
      }
      // 报告详情
      if (e.key === this.tableActions[4].key) {
        this.$router.push({
          name: "OrderReport",
          params: {
            id: this.currentItem.taskNo,
            status: item.taskProgressId
          }
        });
        return;
      }
      // 停止核实
      if (e.key === this.tableActions[5].key) {
        if (item.sourceId && item.sourceId === 814) {
          showMsg({ flag: 2, title: this.$t('order.table.stop'), msg: this.$t("member.bind.feishu.cancelOperateBlockedMessage") });
          return;
        }

        this.isStopModal = true;
        return;
      }
      // 发放入职礼
      if (e.key === this.tableActions[6].key) {
        this.isGrantGiftVisible = true;
        return;
      }

      // 查看详情
      // 查看网页录入详情
      if (this.tableActions[0].key === e.key) {
        // 查看详情-全部候选人
        if (item.taskNo > 0) {
          this.$router.push({
            name: "MessageProcess",
            params: {
              id: item.taskNo
            }
          });
        } else {
          this.$router.push({
            name: "candidateInfo",
            params: {
              id: item.interviewNo
            }
          });
        }
        return;
      }
      if ([this.tableActions[1].key].includes(e.key)) {
        // 查看网页录入详情-到场记录
        this.$router.push({
          name: currMenu?.routerName,
          params: {
            id: item.indexNo
          }
        });
        return;
      }

      // 快速核验
      // 进行委托
      const candidateList = [
        {
          ...item,
          key: undefined,
          authorizationRequired: true,
          isContact: true,
          phoneDialingCode: "86"
        }
      ];
      // 全部候选人
      if (this.currentTabKey === 1) {
        candidateList[0].name = item.candidateName;
        candidateList[0].email = item.candidateEmail;
        candidateList[0].idNumbering = item.idNumber;
      }
      // 到场记录
      if (this.currentTabKey === 2) {
        candidateList[0].phone = item.mobile;
        candidateList[0].idNumbering = item.identityNum;
      }
      // 新建委托
      // if (e.key === this.tableActions[3].key) {
      //   this.$store.commit("back/setOrderCandidateList", candidateList);
      // }
      // 易查宝
      // if (e.key === this.tableActions[2].key) {
      //   this.$store.commit("back/setEcheckCandidateList", candidateList);
      // }
      // 新建订单合并
      if (e.key === this.tableActions[2].key) {
        this.$store.commit("back/setCandidateList", candidateList);
      }
      this.$router.push({
        name: currMenu?.routerName,
        params: { type: "single" },
        query: { selected: "1" }
      });
    },
    // 全部候选人列表
    async getCanList() {
      const { filters } = this.candidateTabFilters[this.currentIndex];
      const params = {
        pageSize: this.candidateTabFilters[this.currentIndex].page.pageSize,
        pageIndex: this.candidateTabFilters[this.currentIndex].page.current,
        ...filters
      };
      const res = await getCandidateList(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      this.$store.commit("back/setCandidateTabFilters", {
        index: this.currentIndex,
        pageTotal: res.data?.total || 0
      });
      const list = res.data?.list || [];
      list.forEach(it => {
        it.key = it.taskNo || getFakeId();
      });
      this.tabList[this.currentIndex].tableList = list;
    },
    // 获取候选人资料
    async openFiles(item) {
      this.fileModalData = {
        taskId: item.taskNo,
        candidateName: item.candidateName,
        // 报告编号
        reportNumbering: item.taskNumber
      };
      this.fileModalVisible = true;
    },
    onResetFilter(idx) {
      this.$store.commit("back/setCandidateTabFilters", {
        index: idx,
        filters: false
      });
      this.currentFilterData = {};
    },
    // 查询事件
    onFilter(key, form, isSkipRequest) {
      this.currentFilterData = { ...form };
      switch (key) {
        // 全部候选人
        // case 1:
        //   this.$store.commit("back/setCandidateTabFilters", {
        //     index: 0,
        //     filters: {
        //       ...form,
        //       candidateName: trim(form.candidateName || ""),
        //       candidatePhone: trim(form.candidatePhone || "")
        //     }
        //   });
        //   break;
        // 到场记录
        // case 2:
        //   this.$store.commit("back/setCandidateTabFilters", {
        //     index: 1,
        //     filters: {
        //       ...form,
        //       registrationTimeStart: rangeDateFormatter(form.registrationTime)
        //         .start,
        //       registrationTimeEnd: rangeDateFormatter(form.registrationTime)
        //         .end,
        //       mobile: trim(form.mobile || ""),
        //       candidateName: trim(form.candidateName || "")
        //     }
        //   });
        //   break;
        // 易查记录
        // case 3:
        //   this.$store.commit("back/setCandidateTabFilters", {
        //     index: 2,
        //     filters: {
        //       ...form,
        //       candidateName: trim(form.candidateName),
        //       consignBeginTime: rangeDateFormatter(form.consignTime).start,
        //       consignEndTime: rangeDateFormatter(form.consignTime).end
        //     }
        //   });
        //   break;
        // 委托记录
        case 4:
          this.$store.commit("back/setCandidateTabFilters", {
            index: 0,
            filters: this.formatEntrustListParams(form),
            // fix#https://echeck-china.coding.net/p/echeck-platform/requirements/issues/204/detail
            pageCurrent: 1
          });
          break;
        default:
          break;
      }

      if (isSkipRequest) {
        return;
      }

      this.getList();
    },
    // 复选框选中事件
    onSelectChange(selection, selectRows, idx) {
      this.tabList[idx].selectedIds = selection;
      this.tabList[idx].selectedRows = selectRows;
    },
    // 新建委托
    addOrder() {
      this.$router.push({ name: "BackOrderAdd" });
    },
    // 新建快速背调
    addBacktone() {
      this.$router.push({ name: "BackOrderAdd" });
    },
    // 新建到场
    addPresent() {
      this.$router.push({ name: "AddPresent" });
    },
    // 批量委托
    batchOrder() {
      this.$router.push("/present/tbverify");
    },
    // 批量导入
    batchImport() {
      this.isBatchModal = true;
    },
    // 导出
    handleExport() {
      this.exportParams = {
        size: this.candidateTabFilters[this.currentIndex].page.pageSize,
        index: this.candidateTabFilters[this.currentIndex].page.current,
        ...this.currentFilterData
      };
      // if (this.currentTabKey === 2) {
      //   this.exportParams.candidatePhone = this.currentFilterData.mobile;
      // }
      // if (this.currentTabKey === 3) {
      //   this.exportParams.consignTime = undefined;
      // }
      if (this.currentTabKey === 4) {
        this.exportParams = this.formatEntrustListParams(this.exportParams);
        this.exportParams.consignTime = undefined;
        this.exportParams.confirmTime = undefined;
      }
      this.isHrModal = true;
    },
    // 新建背调
    add() {
      this.isAuthorizeModal = true;
    },
    // 批量背调
    batchBack() {
      // 选择的数据大于1条小于10条，
      if (!this.tabList[this.currentIndex].selectedIds.length) {
        showMsg({ flag: 1, msg: this.$t("backCheck.oneitem", { num: 1 }) });
        return;
      }
      if (this.tabList[this.currentIndex].selectedIds.length > 10) {
        showMsg({ flag: 1, msg: this.$t("backCheck.moreitem", { num: 10 }) });
        return;
      }
      this.authorizeOk();
    },
    authorizeCancel() {
      this.isAuthorizeModal = false;
    },
    // 批量背调确认事件
    authorizeOk() {
      const ls = cloneDeep(this.tabList[this.currentIndex].selectedRows);
      ls.forEach(it => {
        it.key = getFakeId();
      });
      const candidateList = ls.map(it => {
        return {
          ...it,
          authorizationRequired: true,
          isContact: true,
          phoneDialingCode: "86",
          phone: it.mobile,
          idNumbering: it.identityNum
        };
      });
      this.$store.commit("back/setCandidateList", candidateList);
      this.$router.push({
        name: "BackOrderAddTemplate",
        params: { type: "multi" },
        query: { batch: "1" }
      });
    },
    // 新建入场、背调确认事件
    onAuthorizeOk() {
      const curr = this.authorizeTypeList.find(
        it => it.value === this.authorize.type
      );
      if (!curr) return;
      this.$router.push(curr.routerPath);
    },
    changeAuthorizeSelect(value) {
      this.authorize.type = value;
    },
    // 到场记录
    async getPresentList() {
      const { filters } = this.candidateTabFilters[this.currentIndex];
      const params = {
        pageSize: this.candidateTabFilters[this.currentIndex].page.pageSize,
        pageNum: this.candidateTabFilters[this.currentIndex].page.current,
        ...filters,
        registrationTime: undefined
      };
      const res = await interviewList(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      let list = res.data?.list || [];
      list.forEach(it => {
        it.key = it.indexNo;
      });
      list = await listDicFormatter(list, [
        { code: CssTaskProgress, key: "taskProgressNo", local: true },
        { code: CssStatusList, key: "statusNo", local: true }
      ]);
      this.tabList[this.currentIndex].tableList = list;
      this.$store.commit("back/setCandidateTabFilters", {
        index: this.currentIndex,
        pageTotal: res.data.total
      });
    },
    // 易查记录列表
    async getEcheckList() {
      const { filters } = this.candidateTabFilters[this.currentIndex];
      const params = {
        pageSize: this.candidateTabFilters[this.currentIndex].page.pageSize,
        pageIndex: this.candidateTabFilters[this.currentIndex].page.current,
        ...filters,
        consignTime: undefined
      };
      const res = await getEcheckRecord(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      let list = res.data?.list || [];
      list.forEach(it => {
        it.key = it.orderId;
      });
      list = await listDicFormatter(list, [
        { code: CssTaskProgress, key: "taskProgressNo", local: true }
      ]);
      this.tabList[this.currentIndex].tableList = list;
      this.$store.commit("back/setCandidateTabFilters", {
        index: this.currentIndex,
        pageTotal: res.data.total
      });
    },
    // 委托列表入参格式化
    formatEntrustListParams(params) {
      return {
        ...params,
        // 委托时间起始
        createOrderDateBegin: rangeDateFormatter(params.consignTime).start,
        // 委托时间截止
        createOrderDateEnd: rangeDateFormatter(params.consignTime).end,
        // 信息确认时间起始
        collectionTableConfirmDateBegin: rangeDateFormatter(params.confirmTime)
          .start,
        // 信息确认时间截止
        collectionTableConfirmDateEnd: rangeDateFormatter(params.confirmTime)
          .end,
        // 候选人姓名
        candidateName: trim(params.candidateName),
        // 报告编号
        taskNumber: trim(params.taskNumber)
      };
    },
    // 委托列表 - 已经和易查列表合并
    async getEntrustList() {
      const { filters } = this.candidateTabFilters[this.currentIndex];
      const params = {
        size: this.candidateTabFilters[this.currentIndex].page.pageSize,
        index: this.candidateTabFilters[this.currentIndex].page.current,
        ...filters,
        consignTime: undefined,
        confirmTime: undefined
      };
      const res = await orderListPage(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      let list = res.data?.list || [];
      list.forEach(it => {
        it.key = it.orderId;
      });
      list = await listDicFormatter(list, [
        { code: CssTaskProgress, key: "taskProgressNo", local: true }
      ]);
      this.tabList[this.currentIndex].tableList = list;
      this.$store.commit("back/setCandidateTabFilters", {
        index: this.currentIndex,
        pageTotal: res.data.total
      });
    },
    onTableChange(tab, page) {
      this.$store.commit("back/setCandidateTabFilters", {
        index: this.currentIndex,
        pageCurrent: page.current,
        pageSize: page.pageSize
      });
      this.getList();
    },
    // HR列表
    async getStaff() {
      const res = await orderListSearch({});
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      this.hrList = res.data?.user || [];
      this.packageList = res.data?.packages || [];
    },
    // 发放入职礼按钮是否显示
    isGrantGiftBtnVisible(record) {
      return false;
      // 变更状态为空、终版报告、入职礼字段为空或者暂不发放时，显示“发放入职礼”按钮
      // return (
      //   !record.changeState &&
      //   record.taskProgressId === CssTaskProgress[6].dictCode &&
      //   !record.joinGift
      // );
    },
    // 获取当前页面的操作指引
    getGuidance() {
      this.$store.dispatch("guidance/getRead", { key: "1" }).then(read => {
        this.guidanceShow1 = !read;
      });
    },
    // 关闭操作指引
    onGuidanceClose(key) {
      this.$store.commit("guidance/setRead", {
        key,
        userId: this.$store.state.user.info.userId
      });
      this.getGuidance();
    }
  },
  mounted() {
    this.getGuidance();

    if (this.multipleCompany) {
      this.tabList.forEach(tabItem => {
        if ([1, 2].includes(tabItem.key)) {
          return;
        }
        tabItem.filterList.unshift({
          label: this.$t("bill.filter.org"),
          slotName: "companyId",
          type: "slot"
        });
      });
    }
    this.getStaff();
    this.tabChange(this.currentTabKey);
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-tabs-bar {
  margin-bottom: 0;
}
.candidate-list {
  background-color: #fff;
  min-height: 100%;
  .tab-item {
    padding: @ec-gutter * 2;
    padding-top: 0;
  }
  .edit-select {
    width: 120px;
  }
  .list-filter {
    /deep/.list-card {
      border-bottom: 1px solid #e9e9e9;
    }
  }
}
/deep/.list-card .list-header .list-title {
  margin-bottom: 12px !important;
}
.export-btn {
  background: @secondary-color;
  color: #fff;
  &:hover,
  &:focus {
    border: 1px solid @secondary-color;
  }
}
.authorize-modal {
  /deep/.ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  /deep/.icon-tip {
    width: 70px;
    height: 70px;
    margin-top: 70px;
    margin-bottom: 18px;
  }
  .authorize-title {
    font-size: 18px;
    color: @font-color;
    text-align: center;
  }
  .authorize-text {
    font-size: 18px;
    color: @font-color;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  .authorize-tip {
    color: @sub-color;
    font-size: @font-size-large;
  }
  .authorize-method {
    display: flex;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 20px;
        .pointer();
      }
      &-text {
        font-size: @font-size-large;
        color: @font-color;
        margin-left: 8px;
        margin-right: @ec-gutter20;
      }
    }
  }
}
.button-flex {
  display: flex;
  gap: @ec-gutter20;
  flex-wrap: nowrap;
}
/deep/.can-manage-list-filter {
  .filter-form-inner {
    .ant-col:nth-child(2),
    .ant-col:nth-child(5),
    .ant-col:nth-child(8) {
      .ant-form-item-label {
        width: 40%;
      }
    }
  }
}
</style>
