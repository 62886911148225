// 报告状态
import Vue from "vue";

export const reportStatus = [
  {
      label: Vue.prototype.$t('homeModal.statusList.have'),
      value: '1'
  },
  {
      label: Vue.prototype.$t('homeModal.statusList.unread'),
      value: '2'
  },
]
// 报告进度
export const reportProgress = [
    {
        label: '客户委托',
        value: '1'
    },
    {
        label: '资料收集',
        value: '2'
    },
    {
        label: '资料审核',
        value: '3'
    },
    {
        label: '报告制作',
        value: '4'
    },
    {
        label: '阶段报告',
        value: '5'
    },
    {
        label: '终版报告',
        value: '6'
    },
    {
        label: '核算完成',
        value: '7'
    },
    {
        label: '候选人身份核实未通过',
        value: '8'
    },
]

// 报告级别
export const reportLevel = [
    {
        label: '红盾',
        value: '1'
    },
    {
        label: '黄盾',
        value: '2'
    },
    {
        label: '绿盾',
        value: '3'
    },
    {
        label: '蓝盾',
        value: '4'
    }
]

// 性别
export const sexList = [
    { label: Vue.prototype.$t('homeModal.sexList.unknown'), value: 1 },
    { label:  Vue.prototype.$t('homeModal.sexList.male'), value: 'Pear' },
    { label:  Vue.prototype.$t('homeModal.sexList.female'), value: 'Orange' }
]
