<template>
  <a-modal
    :visible="isSourceModal"
    :title="title"
    @cancel="cancel"
    @ok="confirm"
    :maskClosable="false"
    destroyOnClose
  >
    <a-form-model
      :model="form"
      :rules="rules"
      ref="formRef"
      :labelCol="{span: 6}"
      :wrapperCol="{span: 14 }"
    >
      <a-row>
        <a-col :span="24">
          <a-form-model-item
            :label="$t('homeModal.source.dictCode')"
            prop="dictCode"
          >
            <a-input
              v-model="form.dictCode"
              :placeholder="$t('homeModal.source.dictCodePlaceholder')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item
            :label="$t('homeModal.source.dictName')"
            prop="dictName"
          >
            <a-input
              v-model="form.dictName"
              :placeholder="$t('homeModal.source.dictNamePlaceholder')"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { saveDic } from "@/pages/system/api";

const Empty = {
  dictType: "recruit_source",
  dictCode: null,
  dictName: null,
};

export default {
  name: 'SourceModal',
  props: {
    isSourceModal: Boolean
  },
  data () {
    return {
      title: this.$t('order.detail.source'),
      form: {
        ...Empty,
      },
      rules: {
        dictCode: [
          { required: true, message: this.$t("homeModal.source.dictCodeRequired") },
          { max: 100, message: this.$t("homeModal.source.dictCodeMax", { length: 100 }) },
        ],
        dictName: [
          { required: true, message: this.$t("homeModal.source.dictNameRequired") },
          { max: 100, message: this.$t("homeModal.source.dictNameMax", { length: 100 }) },
        ],
      },
      loading: false,
    }
  },
  methods: {
    cancel () {
      this.closeModal()
    },
    confirm () {
      if (this.loading) return;
      this.loading = true;
      this.$refs.formRef.validate(async (valid, errors) => {
        if (!valid) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: Object.values(errors)[0]?.[0]?.message,
          })
          this.loading = false;
          return;
        }
        const res = await saveDic({
          ...this.form,
          state: true,
          dictIcon: null,
          sort: 1,
          remark: this.$t("homeModal.source.remark"),
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.loading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.add"),
        });
        this.loading = false;
        // 关闭
        this.cancel();
      });
    },
    closeModal () {
      this.$emit('update:isSourceModal', false);
      this.$emit('reload');
    },
  },
  mounted() {
  },
}
</script>
