<template>
  <div class="list-card">
    <div class="list-header">
      <div class="list-title" v-show="title">
        <span class="list-title-real">{{title}}</span>
        <div class="icon-tips" />
      </div>
      <div class="list-header-right">
        <slot name="right"></slot>
      </div>
  </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListTitleCard',
  props: {
    title: String
  }
}
</script>
<style lang="less" scoped>
.list-card {
  width: 100%;
  background: #fff;
  padding: @ec-gutter;
  &:not(&:first-child) {
    margin-top: @ec-gutter;
  }
  .list-header {
    .relative();
    .list-title {
      margin-bottom: @ec-gutter;
      font-size: 18px;
      color: @font-color;
      &:before {
      display: block;
      content: " ";
      width: 3px;
      height: 18px;
      border-radius: 8px;
      background: @primary-color;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
      &-real {
         margin-left: 15px;
      }
    }
    .list-title > * {
      margin-right: @ec-gutter;
    }
    .list-header-right {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
    .list-header-right > * {
      margin-left: @ec-gutter;
    }
  }
  .content {
    padding: @ec-gutter;
  }
}
</style>
