<template>
  <!--  选择套餐  -->
  <div class="ec-card ec-card-nhover package-select">
    <div class="ec-card-header">
      <div class="ec-card-title">
        {{ $t("echeck.detail.package") }}
      </div>
      <div class="icon-tip"></div>
    </div>
    <div class="package-select-card">
      <div
        class="package-select-inner"
        v-for="i in list"
        :key="i.module"
      >
        <div
          class="package-select-item package-selected-item"
          :class="selectedIds.includes(i.module) ? 'selected' : ''"
          @click="onSelect(i)"
        >
        <div
            class="select-must"
            v-if="i.openBtnFlag === 'False'"
          >
            {{ $t("echeck.detail.must") }}
          </div>
          <i class="iconfont icon-check-select" v-if="selectedIds.includes(i.module)"></i>
          <i class="iconfont icon-check-default" v-else></i>
          <div class="item-label">{{ i.name }}</div>
          <div class="item-value">
            {{ $t("echeck.detail.last") }}&nbsp;
            <span class="item-light">{{ i.stock || 0 }}</span>&nbsp;
            {{ $t("echeck.detail.times") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackageSelect",
  components: {},
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    selectedIds() {
      return this.selected.map((it) => it.module);
    },
  },
  methods: {
    onSelect(item) {
      const index = this.selected.findIndex((it) => it.module === item.module);
      if (index > -1) {
        if (item.openBtnFlag === "False") {
          return;
        }
        this.selected.splice(index, 1);
        return;
      }
      this.selected.push({ ...item });
    },
    onSelectBatch(selected) {
      this.selected = selected;
    },
  },
};
</script>

<style lang="less" scoped>
.package-select {
  &-card {
    display: flex;
    flex-wrap: wrap;
  }
  &-inner {
    flex: 0 0 20%;
  }
  &-item {
    i {
      font-size: 20px;
      position: absolute;
      top: 10px;
      right: 20px;
    }
    .relative();
    margin: 6px;
    &:hover {
      box-shadow: 0px 4px 4px 1px rgba(15, 113, 222, 0.06);
    }
    padding-top: 35px;
    padding-bottom: @ec-gutter20;
    border-radius: 8px;
    border: 1px solid #ccd8f0;
    .single-hidden();
    text-align: center;
    .item-label {
      font-size: @font-size-large;
      color: @font-color;
      margin-bottom: 5px;
    }
    .item-value {
      color: @sub-color;
    }
    .item-light {
      color: @primary-color;
      font-size: @font-size-large;
    }
    .select-must {
      position: absolute;
      top: 0;
      left: 0;
      padding: 4px @ec-gutter;
      background: @primary-color;
      box-shadow: 0px 4px 4px 1px rgba(15, 113, 222, 0.06);
      border-radius: 8px 0px 8px 0px;
      color: #fff;
      font-size: @font-size-small;
    }
  }
}
.package-selected-item {
  border: 1px solid #CCD8F0;
  cursor: pointer;

  &.selected {
    border-color: @primary-color;
  }
}
</style>
