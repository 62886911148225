<template>
  <a-form-model
    :colon="isShowColon"
    :layout="model"
    labelAlign="right"
    class="detail-form"
    :model="form"
    ref="form"
    :labelCol="{ span: labelCol }"
    :wrapperCol="{ span: wrapperCol }"
    :rules="rules"
  >
    <a-row>
      <a-col :span="span || i.span" v-for="i in formList" :key="i.key">
        <a-form-model-item :label="i.label" :prop="i.key">
          <span v-if="i.type === 'text'">{{ form[i.key] }}</span>
          <a-input
            size="large"
            v-model="form[i.key]"
            v-if="i.type === 'input'"
            :placeholder="`${i.label}`"
            allowClear
            v-support="`${form[i.key]}`"
          >
          </a-input>
          <UploadFile v-if="i.type === 'upload'"></UploadFile>
          <a-input
            size="large"
            v-model="form[i.key]"
            type="textarea"
            :autoSize="{ minRows: 3 }"
            v-if="i.type === 'textarea'"
            :placeholder="`${i.label}`"
            v-support="`${form[i.key]}`"
          >
          </a-input>
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            v-if="i.type === 'select'"
            v-model="form[i.key]"
            :placeholder="`${i.label}`"
            allowClear
          >
            <a-select-option
              v-for="op in i.options"
              :key="op.value"
              :value="op.value"
            >
              {{ op.label }}
            </a-select-option>
          </a-select>
          <a-radio-group
            v-model="form[i.key]"
            :options="i.options"
            v-if="i.type === 'radio'"
          />
          <a-range-picker
            size="large"
            separator="-"
            show-today
            :allowClear="false"
            v-if="i.type === 'timeRange'"
            v-model="form[i.key]"
            v-support="`${form[i.key]}`"
          />
          <a-date-picker
            size="large"
            show-time
            :allowClear="true"
            v-if="i.type === 'time'"
            v-model="form[i.key]"
            class="width"
            v-support="`${form[i.key]}`"
          ></a-date-picker>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import UploadFile from "./UploadFile";
import {cloneDeep} from "lodash";
export default {
  name: "DetailFom",
  props: {
    formList: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    span: {
      type: Number,
      default: 9,
    },
    labelCol: {
      type: Number,
      default: 6,
    },
    wrapperCol: {
      type: Number,
      default: 12,
    },
    model: {
      type: String,
      default: "horizontal",
    },
    isShowColon: {
      type: Boolean,
      default: false,
    },
    formValue: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    UploadFile,
  },
  data() {
    return {
      form: this.formValue || {},
    };
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.$emit('update:formValue', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    valid() {
      let isValid;
      this.$refs.form.validate((valid) => {
        isValid = valid;
        return valid;
      });
      return isValid ? this.form : false;
    },
    clear() {
      this.$refs.form.resetFields();
      this.form = {}
    },
    serialize() {
      let form = {};
      this.formList.map((f) => {
        form[f.key] = "";
        if (f.value !== "" && f.value !== null && f.value !== undefined) {
          form = Object.assign(form, { [f.key]: f.value });
        }
      });
      this.form = form;
    },
    getForm () {
      this.$emit('update:formValue', this.form)
    },
    setForm (values) {
      this.form = cloneDeep(values || this.formValue);
    },
  },
  mounted() {
    this.serialize();
  },
};
</script>

<style lang="less" scoped>
</style>
