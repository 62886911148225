import Vue from "vue";

// 授权方式
export const AuthorizationRequired = [
  {
    id: 1,
    dictType: "authorization_required",
    value: true,
    dictCode: true,
    label: Vue.prototype.$t("backCheck.authOnline"),
    dictName: Vue.prototype.$t("backCheck.authOnline")
  },
  {
    id: 2,
    dictType: "authorization_required",
    value: false,
    dictCode: false,
    label: Vue.prototype.$t("backCheck.authOffline"),
    dictName: Vue.prototype.$t("backCheck.authOffline")
  }
];
