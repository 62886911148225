<template>
  <div>
    <a-modal
      :title="$t('button.batchImport')"
      :visible="isBatchModal"
      :width="600"
      @cancel="cancel"
      :maskClosable="false"
      @ok="confirm"
      class="batch-modal"
    >
      <a-spin :spinning="uploadLoading">
        <a-steps v-model="current" size="small" class="upload-steps">
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            disabled
          />
        </a-steps>
        <!-- 第一步 -->
        <div v-show="current === 0">
          <!-- 上传 -->
          <div v-if="!isUploadError">
            <div class="upload-list-first" v-for="i in data" :key="i.type">
              <div class="upload-list-left">
                <div class="upload-list-title">{{ i.title }}</div>
                <div class="upload-list-desc">{{ i.desc }}</div>
                <div
                  class="ec-color-primary"
                  v-if="i.type === 2 && excelFileName"
                >
                  {{ excelFileName }}
                </div>
              </div>
              <div class="upload-list-right" @click="handleStepOne(i.type)">
                <div
                  class="right-icon"
                  :style="{ backgroundImage: `url(${i.icon})` }"
                />
                <div class="right-title" v-if="i.type === 1">
                  {{ i.action }}
                </div>
                <div class="right-title" v-else>
                  <span>
                    <input
                      type="file"
                      ref="uploadFile"
                      :accept="fileAccept"
                      @change="onUploadFile"
                      class="upload-list-uploader"
                    />
                    {{ i.action }}
                  </span>
                </div>
              </div>
            </div>
            <a-alert
              :message="$t('homeModal.import.step1.upload.message.title')"
              banner
              :description="$t('homeModal.import.step1.upload.message.content')"
            />
          </div>
          <!-- 上传失败 -->
          <div class="upload-first" v-else>
            <div class="icon-error first-error"></div>
            <div>
              {{ $t("homeModal.import.step1.fail") }}
              <span class="fail-text">
                {{ errorData.length }}{{ $t("tiao") }}
              </span>
            </div>
            <a-card class="upload-error">
              <p>{{ $t("homeModal.import.step1.tip") }}</p>
              <p v-for="i in errorData" :key="i">{{ i }}</p>
            </a-card>
          </div>
        </div>
        <!-- 第二步 -->
        <div class="upload-list" v-show="current === 1">
          <ECTable
            bordered
            :columns="columns || tableColumns"
            :data-source="tableData"
            :pagination="false"
            :scroll="{ x: true }"
          >
            <template slot="authorizationRequired" slot-scope="text, record">
              {{
                record.authorizationRequired
                  ? authorizationRequired[0].label
                  : authorizationRequired[1].label
              }}
            </template>
          </ECTable>
        </div>
        <!-- 第三步 -->
        <div class="upload-last" v-show="current === 2">
          <!-- 正在导入 -->
          <div class="uploading" v-show="isImporting">
            <a-progress :percent="30" :showInfo="false" />
            <div class="uploading-text">
              {{ $t("homeModal.import.step3.importing") }}
            </div>
          </div>
          <!-- 导入结果 -->
          <div class="success-result" v-show="!isImporting">
            <div class="icon-success"></div>
            <div class="success-title">
              {{ $t("homeModal.import.step3.success") }}
            </div>
            <div class="success-subtitle">
              {{ $t("homeModal.import.step3.successCount") }}
              {{ tableData.length }}{{ $t("tiao") }}
            </div>
          </div>
        </div>
      </a-spin>
      <!-- 按钮 -->
      <template slot="footer">
        <template v-if="current === 0">
          <a-button @click="cancel" v-show="!isUploadError">
            {{ $t("button.cancel") }}
          </a-button>
          <a-button @click="reUpload" v-show="isUploadError" type="primary">
            {{ $t("button.upload") }}
          </a-button>
        </template>
        <a-button v-if="current !== 0" type="primary" @click="prev">
          {{ $t("button.previous") }}
        </a-button>
        <a-button
          type="primary"
          @click="next"
          v-if="current !== steps.length - 1"
        >
          {{ $t("button.next") }}
        </a-button>
        <template v-if="current === steps.length - 1">
          <a-button type="primary" @click="onFinished">
            {{ $t("button.done") }}
          </a-button>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { FETCH_CODE } from "@/config";
import ECTable from "@/components/ECTable";
import { showMsg } from "@/utils";
import api from "@/utils/fetch";
import { cloneDeep } from "lodash";
import { AuthorizationRequired } from "@/pages/home/config";

export default {
  name: "BatchModal",
  props: {
    isBatchModal: Boolean,
    // 处理批量背调人列表方法
    // 请返回 { flag: number, msg: string }
    submit: {
      type: Function,
      default: () => {}
    },
    downloadUrl: {
      type: String,
      required: true
    },
    downloadMethod: {
      type: String,
      default: "GET"
    },
    downloadLocal: Boolean,
    // 下载文件名, 当 downloadLocal 时必传
    downloadName: String,
    uploadUrl: {
      type: String,
      required: true
    },
    uploadData: Object,
    // 自定义表格列
    columns: Array
  },
  components: {
    ECTable
  },
  data() {
    return {
      current: 0,
      steps: [
        {
          title: this.$t("homeModal.import.step1.title")
        },
        {
          title: this.$t("homeModal.import.step2.title")
        },
        {
          title: this.$t("homeModal.import.step3.title")
        }
      ],
      data: [
        {
          type: 1,
          title: this.$t("homeModal.import.step1.download.title"),
          desc: this.$t("homeModal.import.step1.download.desc"),
          action: this.$t("homeModal.import.step1.download.action"),
          icon: require("@/assets/images/icon-excel.png")
        },
        {
          type: 2,
          title: this.$t("homeModal.import.step1.upload.title"),
          desc: this.$t("homeModal.import.step1.upload.desc"),
          action: this.$t("homeModal.import.step1.upload.action"),
          icon: require("@/assets/images/icon-upload.png")
        }
      ],
      actions: [
        { type: "star-o", text: "156" },
        { type: "like-o", text: "156" },
        { type: "message", text: "2" }
      ],
      result: {},
      errorData: [],
      isUploadError: false,
      tableColumns: [
        {
          title: this.$t("backCheck.cname"),
          dataIndex: "name"
        },
        {
          title: this.$t("backCheck.cidcard"),
          dataIndex: "identityNum"
        },
        {
          title: this.$t("backCheck.cphone"),
          dataIndex: "mobile"
        },
        {
          title: this.$t("backCheck.source"),
          dataIndex: "recruitSource"
        }
      ],
      tableData: [],
      isImporting: false,
      fileList: [],
      excelFileName: "",
      // 支持xls xlsx格式
      fileAccept:
        "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      downloadLoading: false,
      uploadLoading: false,
      isImportSuccess: false,
      authorizationRequired: cloneDeep(AuthorizationRequired)
    };
  },
  methods: {
    async next() {
      if (this.current >= this.steps.length - 1) {
        return;
      }
      if (this.current === 0) {
        if (!this.excelFileName) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("homeModal.import.step1.tips")
          });
          return;
        }
        this.current++;
        return;
      }
      if (this.current === 1) {
        const succeed = await this.save();
        if (succeed) {
          this.current++;
        }
      }
    },
    prev() {
      if (this.current <= 0) {
        return;
      }
      this.current--;
    },
    cancel() {
      this.$emit("update:isBatchModal", false);
    },
    // type 1 - 下载 2-上传
    handleStepOne(type) {
      if (type === 1) {
        this.getTemplate();
        return;
      }
      this.$refs.uploadFile[0].click();
    },
    confirm() {},
    reUpload() {
      this.isUploadError = false;
      this.excelFileName = "";
      this.$refs.uploadFile[0].click();
    },
    async onUploadFile(event) {
      if (this.uploadLoading) return;
      this.uploadLoading = true;
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        this.excelFileName = "";
        this.uploadLoading = false;
        return;
      }
      if (files[0].size > 1024 * 1024 * 10) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("homeModal.import.step1.limitSize", { size: "10M" })
        });
        this.excelFileName = "";
        this.uploadLoading = false;
        return;
      }
      this.excelFileName = `${+new Date()}.${files[0].name}`;

      const params = new FormData();
      if (this.uploadData) {
        for (const key in this.uploadData) {
          params.append(key, this.uploadData[key]);
        }
      }
      params.append("file", files[0]);

      const res = await api.post(this.uploadUrl, params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.excelFileName = "";
        this.$refs.uploadFile[0].value = "";
        this.isUploadError = true;
        this.errorData = res.msg.split(";");
        this.uploadLoading = false;
        return;
      }
      this.$refs.uploadFile[0].value = "";
      this.tableData = res.data;
      this.isUploadError = false;
      this.next();
      this.uploadLoading = false;
    },
    async getTemplate() {
      if (this.downloadLoading) return;
      this.downloadLoading = true;
      const params = {};
      if (this.downloadLocal) {
        const link = document.createElement("a");
        link.style.cssText = "display: none;";
        link.href = this.downloadUrl;
        link.setAttribute("download", this.downloadName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.downloadLoading = false;
        return;
      }
      const res = await api.download(this.downloadUrl, params, {
        method: this.downloadMethod
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.downloadLoading = false;
        return;
      }
      this.downloadLoading = false;
    },
    async save() {
      if (this.isImporting) {
        return false;
      }
      this.isImporting = true;
      const list = this.tableData.map(it => {
        return { ...it };
      });
      const res = await this.submit(list);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        await showMsg({
          ...res,
          flag: FETCH_CODE.ERROR.KEY
        });
        this.isImporting = false;
        this.isImportSuccess = false;
        return false;
      }
      this.isImportSuccess = true;
      this.isImporting = false;
      return true;
    },
    onFinished() {
      this.cancel();
      if (this.isImportSuccess) {
        this.$emit("success");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.batch-modal {
  .upload-steps {
    margin-bottom: @ec-gutter * 2;
  }
  .upload-list {
    margin-top: @ec-gutter;
    &-item {
      border-radius: 4px;
      border: 1px solid #ccd8f0;
      padding: 0;
      margin-bottom: @ec-gutter20;
      /deep/.ant-list-item-meta {
        padding: @ec-gutter * 2;
      }
    }
    .upload-img {
      width: 120px;
      height: 120px;
      background: @primary-color;
      float: right;
    }
    .upload-title {
      font-weight: bold;
    }
    .upload-btn {
      padding: 0;
    }
  }
  .upload-first {
    text-align: center;

    .first-error {
      margin: @ec-gutter auto;
    }
    .fail-text {
      color: @warning-color;
      font-size: 20px;
    }
    .success-text {
      color: #52c41a;
    }
    .upload-error {
      margin-top: @ec-gutter * 2;
      background: rgba(#ccd8f0, 0.2);
      border-radius: 4px;
      border: 1px solid #ccd8f0;
      p {
        line-height: 2;
        &:not(&:first-child) {
          color: @sub-color;
        }
        margin-bottom: 0;
        &:first-child {
          // font-weight: bold;
          font-size: 18px;
          margin-bottom: @ec-gutter;
        }
      }
    }
  }
  .upload-last {
    margin-top: @ec-gutter20 * 2;
    .uploading-text {
      margin-top: @ec-gutter;
      color: @sub-color;
    }
    .success-result {
      text-align: center;
      .success-title {
        font-size: 20px;
        color: @font-color;
        margin: @ec-gutter20 auto @ec-gutter auto;
      }
      .success-subtitle {
        color: @sub-color;
      }
    }
  }
  .upload-list-first {
    height: 160px;
    display: flex;
    border-radius: 4px;
    border: 1px solid #ccd8f0;
    margin-bottom: @ec-gutter20;
    .upload-list-left {
      padding: @ec-gutter20;
      flex: 1;
      .upload-list-title {
        font-size: 20px;
        color: @font-color;
        margin-bottom: @ec-gutter;
      }
      .upload-list-content {
        font-size: @font-size-large;
        color: @tip-color;
      }
    }
    .upload-list-right {
      flex-shrink: 0;
      width: 140px;
      height: 100%;
      background: @primary-color;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @font-size-base;
      flex-direction: column;
      cursor: pointer;
      transition: all 0.3s linear;
      position: relative;

      &:hover {
        background: darken(@primary-color, 15%);
      }
      .right-icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
        margin-bottom: 4px;
      }
    }
    .upload-list-uploader {
      position: absolute;
      z-index: -1;
      display: block;
      width: 0;
      height: 0;
      opacity: 0;
      background: rgba(255, 255, 255, 0);
      border: 0;
      left: 0;
      top: 0;
      cursor: pointer;
    }
  }
}
</style>
