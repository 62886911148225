<template>
  <a-modal
    :title="$t('order.dataForm.title')"
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="data-modal"
    :width="700"
    :maskClosable="false"
    destroyOnClose
  >
    <CandidateFile :record="record" />
  </a-modal>
</template>

<script>
import CandidateFile from "@/pages/home/components/CandidateFile/Index.vue";

export default {
  name: "CandidateFileModal",
  components: { CandidateFile },
  props: {
    visible: Boolean,
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
    },
    onOk() {
      this.onCancel();
    }
  },
  created() {}
};
</script>

<style lang="less" scoped></style>
