<template>
  <div class="echeck-detail">
    <!-- 基础信息   -->
    <BaseInfo ref="baseInfoRef" :type="CREATE_TYPE.QUICK" />
    <!-- 选择套餐  -->
    <PackageSelect :list="packageList" ref="packageRef" />
    <!-- 新建委托  -->
    <AddOrder ref="orderRef" :type="createType" />
    <div class="detail-btn">
      <a-button type="primary" @click="onCreate">
        {{ $t("echeck.detail.create") }}
      </a-button>
      <a-button @click="onBack">
        {{ $t("button.back") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import BaseInfo from "@/pages/home/components/BaseInfo";
import PackageSelect from "@/pages/home/components/PackageSelect";
import {
  echeckList,
  submitOrder
} from "@/pages/workspace/backgroundCheck/presentRecord/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { CREATE_SOURCE, CREATE_TYPE } from "@/pages/home/order/config";
import { cloneDeep } from "lodash";
import AddOrder from "@/pages/home/components/AddOrder.vue";

export default {
  name: "ECheckDetail",
  components: {
    AddOrder,
    BaseInfo,
    PackageSelect
  },
  data() {
    return {
      createType: CREATE_TYPE.QUICK,
      packageList: [],
      pending: false
    };
  },
  computed: {
    CREATE_TYPE() {
      return CREATE_TYPE;
    }
  },
  methods: {
    // 创建易查订单
    async onCreate() {
      if (this.pending) return;
      this.pending = true;
      if (!this.$refs.packageRef.selected.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("echeck.detail.emptyModule")
        });
        this.pending = false;
        return;
      }
      const orderValid = this.$refs.orderRef.onValid();
      if (!orderValid) {
        this.pending = false;
        return;
      }
      const params = {
        // 创建订单的基本信息，用于CSS向API传递
        ...cloneDeep(this.$refs.baseInfoRef.info),
        // 订单创建来源
        createSource: CREATE_SOURCE.CSS_PC,
        // 候选人信息
        cssCandidateInfo: cloneDeep(orderValid),
        // 套餐信息
        hardwaeQuery: this.$refs.packageRef.selected
        // 用于提交的核查模版
        // surveyTemplate: [],
      };
      // fix#69 如果账号为非多机构企业，相关请求参数的companyId请留空，后端将默认为当前企业id
      // if (!this.$store.getters["user/multipleCompany"]) {
      //   params.basicInfo.companyId = null;
      //   params.basicInfo.company = null;
      //   params.basicInfo.companyArea = null;
      // }
      const res = await submitOrder(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        await showMsg({
          flag: FETCH_CODE.ERROR.KEY,
          msg: res.msg
        });
        this.pending = false;
        return;
      }
      // 提交成功
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.submit")
      });
      // 创建成功 重置所有表单
      this.$refs.orderRef.onReset();
      this.pending = false;
      // 跳转到列表页
      this.$store.commit("back/setCandidateTab", 3);
      this.$router.replace({
        path: "/workspace/background/candidate-manage"
      });
    },
    // 返回
    onBack() {
      this.$router.back();
    },
    // 获取套餐
    async getPackageList() {
      const res = await echeckList({
        companyId: this.$store.state.user.info.currentCompany.companyId
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      this.packageList = res.data || [];
      // 必选的自动选中
      const defaultSelected = [];
      this.packageList.forEach((it) => {
        if (it.openBtnFlag === "False") {
          defaultSelected.push({ ...it });
        }
      });
      this.$nextTick(() => {
        this.$refs.packageRef.onSelectBatch(defaultSelected);
      });
    }
  },
  mounted() {
    this.getPackageList();
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-form-inline {
  width: 100%;
}
</style>
