<template>
  <div class="ec-card">
    <div class="ec-card-header" v-if="isShowIcon">
      <div class="ec-card-title">{{ title }}</div>
      <div class="icon-tip" />
    </div>
    <div class="ec-card-title" v-else>{{ title }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "ECard",
  props: {
    title: String,
    isShowIcon: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="less" scoped>
.ec-card {
  box-shadow: 0 4px 4px 0 rgba(15, 113, 222, 0.06);
}
</style>
