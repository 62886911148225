<template>
  <a-modal
    :title="
      `${selected.name}${$t('backCheck.module')}`
    "
    v-model="visibleInner"
    class="template-modules-modal"
    :width="1002"
    :maskClosable="false"
    destroyOnClose
    :closable="false"
  >
    <template slot="footer">
      <a-button type="primary" @click="onClose">
        {{ $t("button.confirm") }}
      </a-button>
    </template>
    <ul class="template-modules-content">
      <TemplateModule
        :type="selected.type"
        :selected="selected.moduleIds.includes(it.id)"
        :values="it"
        v-for="it in moduleList"
        :key="it.id"
      />
    </ul>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import TemplateModule from "@/pages/workspace/backgroundCheck/order/components/Module.vue";
import { CREATE_TYPE } from "@/pages/home/order/config";

export default {
  name: "TemplateModuleModal",
  components: { TemplateModule },
  props: { visible: Boolean },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selected: state => state.back.selectedTemplate
    }),
    moduleList() {
      const { module = [], type, moduleIds = [] } = this.selected;
      // 易查模板只显示已选的模块
      if (type === CREATE_TYPE.QUICK) {
        return module.filter(it => moduleIds.includes(it.id));
      }
      return module || [];
    },
    visibleInner: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less" scoped>
.template-modules-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
</style>
