<template>
  <div class="candidate-info">
    <div class="candidate-info-title">
      {{ $t("backCheck.interview.detail.title") }}
    </div>
    <div class="candidate-info-title">
      {{ $t("backCheck.interview.detail.subTitle") }}
    </div>
    <div class="list-item">
      <div class="body">
        <div
          class="avatar"
          :style="{ backgroundImage: `url(${info.idPicture})` }"
        >
          <div v-if="!info.idPicture" class="avatar-text">
            {{ $t("backCheck.noPic") }}
          </div>
        </div>
        <div class="info">
          <a-descriptions :column="2" class="info-descriptions" :colon="false">
            <a-descriptions-item
              :label="i.label"
              v-for="i in list"
              :key="i.label"
              class="info-descriptions-item"
            >
              {{ info[i.key] || "--" }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>
    </div>
    <div class="candidate-info-title second-item-title">
      {{ $t("backCheck.siteInfo") }}
    </div>
    <!--      现场比对 -->
    <!--      <div class="list-item">-->
    <!--        <div class="title">现场信息</div>-->
    <!--        <div class="body">-->
    <!--          <div class="avatar left"></div>-->
    <!--          <div class="info left">-->
    <!--            <a-descriptions :column="1">-->
    <!--              <a-descriptions-item :label="i.label" v-for="i in deviceList" :key="i.label">-->
    <!--                {{i.value}}-->
    <!--              </a-descriptions-item>-->
    <!--            </a-descriptions>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    网页授权  -->
    <div class="list-item second-item" v-if="isAuthorize">
      <div class="body">
        <div
          class="avatar"
          :style="{ backgroundImage: `url(${info.devicePicture})` }"
        >
          <div v-if="!info.devicePicture" class="avatar-text">
            {{ $t("backCheck.noPic") }}
          </div>
        </div>
        <div class="info">
          <a-descriptions :column="1" :colon="false" class="info-descriptions">
            <a-descriptions-item
              :label="i.label"
              v-for="i in statusList"
              :key="i.label"
            >
              <template v-if="i.type === 1">
                <template v-if="info.faceRecognition">
                  <span class="info-face">{{ $t("button.pass") }}</span>
                  <a-icon type="check-circle" theme="filled" />
                </template>
                <template v-else>
                  <span class="info-face failed">{{
                    $t("button.reject")
                  }}</span>
                  <a-icon type="close-circle" theme="filled" class="icon" />
                </template>
              </template>
              <template v-if="i.type === 2">
                <div
                  v-if="info.signPicture"
                  class="sign"
                  :style="{ backgroundImage: `url(${info.signPicture})` }"
                />
                <div v-else>{{ $t("backCheck.noSign") }}</div>
              </template>
              <template v-if="i.type === 3">
                <template v-if="!info.authFilePath">
                  <a-spin :spinning="downloading">
                    <div class="icon-pdf" @click="onDownload" />
                  </a-spin>
                </template>
                <div v-else>{{ $t("button.none") }}</div>
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>
    </div>
    <a-alert
      class="info-alert"
      v-else
      banner
      type="warning"
      :message="$t('button.tips')"
      :description="$t('backCheck.noAuth')"
    />
    <div class="detail-btn">
      <a-button type="primary" @click="goToCheck">
        {{ $t("backCheck.order") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import {
  interviewDetail,
  singleFileDownload
} from "@/pages/workspace/backgroundCheck/presentRecord/api";
import { DateFormat, FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { getFakeId } from "@/utils/tools";
import moment from "moment";

export default {
  name: "ViewBaseInfo",
  components: {},
  computed: {
    isAuthorize() {
      return !!this.info.authFileId;
    }
  },
  data() {
    return {
      list: [
        {
          key: "candidateName",
          label: this.$t("backCheck.cname")
        },
        {
          key: "birthday",
          label: this.$t("present.info.birth")
        },
        {
          key: "genderTxt",
          label: this.$t("order.detail.sex")
        },
        {
          key: "addr",
          label: this.$t("present.info.hometown")
        },
        {
          key: "nation",
          label: this.$t("present.info.nation")
        },
        {
          key: "idExpiry",
          label: this.$t("present.info.max")
        },
        {
          key: "mobile",
          label: this.$t("backCheck.cphone")
        },
        {
          key: "identityNum",
          label: this.$t("backCheck.cidcard")
        },
        {
          key: "recruitSource",
          label: this.$t("backCheck.source")
        },
        {
          key: "registrationSourceName",
          label: this.$t("backCheck.registrationSource")
        }
      ],
      deviceList: [
        {
          label: "人脸比对",
          value: "通过"
        },
        {
          label: "授权签名显示",
          value: "王嘉尔"
        },
        {
          label: "文件下载",
          value: "xxx.pdf"
        }
      ],
      statusList: [
        {
          label: this.$t("present.info.faceRecognition"),
          type: 1
        },
        {
          label: this.$t("present.info.signPicture"),
          type: 2
        },
        {
          label: this.$t("present.info.authFile"),
          type: 3
        }
      ],
      info: {
        idExpiryStart: "",
        idExpiryEnd: "",
        idExpiry: "",
        genderTxt: "",
        birthday: "",
        addr: "",
        candidateName: "",
        mobile: "",
        identityNum: "",
        recruitSource: "",
      },
      downloading: false
    };
  },
  methods: {
    // 新建订单
    goToCheck() {
      const candidateList = [
        {
          ...this.info,
          key: undefined,
          name: this.info.candidateName,
          phone: this.info.mobile,
          idNumbering: this.info.identityNum,
          authorizationRequired: true,
          isContact: true,
          phoneDialingCode: "86",
        }
      ];
      this.$store.commit("back/setCandidateList", candidateList);
      this.$router.push({
        name: "BackOrderAddTemplate",
        params: { type: "single" },
        query: { selected: "1" },
      });
    },
    async getInfo() {
      const res = await interviewDetail({ id: this.$route.params.id });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      this.info = res.data || {};
      this.info.idExpiry = `${this.info.idExpiryStart || ""} - ${this.info
        .idExpiryEnd || ""}`;
      this.info.birthday = this.info.birthday
        ? moment(this.info.birthday).format(DateFormat)
        : "";
    },
    // 下载授权书
    async onDownload() {
      if (this.downloading) return;
      this.downloading = true;
      const res = await singleFileDownload({ fileNo: this.info.authFileId });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.downloading = false;
        return;
      }
      this.downloading = false;
    }
  },
  mounted() {
    this.getInfo();
  }
};
</script>

<style lang="less" scoped>
@padding: @ec-gutter * 3;
.candidate-info {
  &-title {
    font-size: 18px;
    color: @font-color;
    margin-bottom: 35px;
  }
}
.list-item {
  padding-bottom: 55px;
  border-bottom: 1px solid #e9e9e9;
  .title {
    font-weight: bold;
    margin-bottom: @ec-gutter;
  }
  .body {
    display: flex;
    width: 80%;
    margin: 0 auto;
    .avatar {
      width: 140px;
      height: 160px;
      border: 1px solid #ddd;
      margin-right: @padding;
      flex-shrink: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      .avatar-text {
        text-align: center;
        line-height: 160px;
      }
    }
    .info {
      &-descriptions {
        /deep/.ant-descriptions-item {
          margin-bottom: 40px;
        }
        /deep/.ant-descriptions-item-label {
          color: @sub-color;
          font-size: @font-size-large;
          margin-right: @ec-gutter;
        }
        /deep/.ant-descriptions-item-content {
          font-size: @font-size-large;
          color: @font-color;
        }
        .info-face {
          color: @secondary-color;

          + .anticon {
            color: @secondary-color;
            margin-left: 5px;
          }

          &.failed {
            color: @error-color;

            + .anticon {
              color: @error-color;
            }
          }
        }
        .sign {
          width: 156px;
          height: 90px;
          border: 1px solid #ddd;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        .icon-pdf {
          cursor: pointer;
        }
      }
    }
    .info-alert {
      display: block;
      width: 50%;
      margin: 0 auto;
    }
  }
}
.second-item {
  border-bottom: none;
  &-title {
    margin-top: 35px;
  }
  .info-descriptions {
    /deep/.ant-descriptions-item {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
    /deep/.ant-descriptions-item-content {
      display: flex;
      align-items: center;
    }
  }
}
</style>
