<template>
  <div class="ec-detail order-detail">
    <!-- 基础信息   -->
    <BaseInfo ref="baseInfoRef" :type="CREATE_TYPE.TRADITION" />
    <!-- 选择套餐  -->
    <TemplateSelect ref="templateRef" @change="changeTemplate" />
    <!-- 新建委托  -->
    <AddOrder ref="orderRef" />
    <div class="detail-btn">
      <a-button type="primary" @click="createOrder">
        {{ $t("order.button.add") }}
      </a-button>
      <a-button @click="back">
        {{ $t("button.back") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import BaseInfo from "@/pages/home/components/BaseInfo";
import TemplateSelect from "@/pages/home/components/TemplateSelect";
import AddOrder from "@/pages/home/components/AddOrder";
import { submitOrderInfo } from "@/pages/home/order/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { CREATE_SOURCE, CREATE_TYPE } from "@/pages/home/order/config";
import { cloneDeep } from "lodash";

export default {
  name: "OrderDetail",
  computed: {
    CREATE_TYPE() {
      return CREATE_TYPE
    }
  },
  components: {
    BaseInfo,
    TemplateSelect,
    AddOrder
  },
  data() {
    return {
      pending: false,
    };
  },
  methods: {
    // 创建
    async createOrder() {
      if (this.pending) return;
      this.pending = true;
      const orderValid = this.$refs.orderRef.onValid();
      if (!orderValid) {
        this.pending = false;
        return;
      }
      const templateValid = this.$refs.templateRef.onValid();
      if (!orderValid || !templateValid) {
        this.pending = false;
        return;
      }
      const params = {
        model: {
          // 创建订单的基本信息，用于CSS向API传递
          ...cloneDeep(this.$refs.baseInfoRef.info),
          // 订单创建来源
          createSource: CREATE_SOURCE.CSS_PC,
          // 候选人信息
          cssCandidateInfo: cloneDeep(orderValid),
          // 套餐信息
          hardwaeQuery: [],
          // 用于提交的核查模版
          surveyTemplate: cloneDeep(templateValid),
        },
        performanceInfo: [],
      };
      // fix#69 如果账号为非多机构企业，相关请求参数的companyId请留空，后端将默认为当前企业id
      // if (!this.$store.getters["user/multipleCompany"]) {
      //   params.model.basicInfo.companyId = null;
      //   params.model.basicInfo.company = null;
      //   params.model.basicInfo.companyArea = null;
      // }
      const res = await submitOrderInfo(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({
          flag: FETCH_CODE.ERROR.KEY,
          msg: res.msg,
        });
        this.pending = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.submit"),
      });
      // 创建成功 重置所有表单
      this.$refs.orderRef.onReset();
      this.pending = false;
      // 跳转到列表页
      this.$store.commit("back/setCandidateTab", 4);
      this.$router.replace({
        path: "/workspace/background/candidate-manage",
      });
    },
    back() {
      this.$router.go(-1);
    },
    changeTemplate(item) {
      console.log(item);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.order-detail {
  .full-width {
    width: 100%;
  }
}
</style>
