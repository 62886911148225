<template>
  <div class="card-trend">
    <a-spin :spinning="loading">
      <CardHeader :title="title" :icon="'icon-bar'">
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="companyId"
          class="brief-select"
          @change="onCompanyChange"
          v-if="multipleCompany"
        >
          <a-select-option
            :value="i.companyId"
            v-for="i in companyList"
            :key="i.companyId"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
      </CardHeader>
      <Echarts
        ref="eChartsRef"
        :width="echarts.width"
        :height="echarts.height"
        :id="echarts.id"
        :options="echarts.options"
      />
    </a-spin>
  </div>
</template>

<script>
import Echarts from "@/components/Echarts";
import CardHeader from "./CardHeader.vue";
import { getOrderTrend } from "@/pages/workspace/backgroundCheck/index/api";
import moment from "moment";
import { FETCH_CODE } from "@/config";
import { mapGetters } from "vuex";

export default {
  name: "CardTrend",
  components: {
    Echarts,
    CardHeader,
  },
  data() {
    return {
      title: this.$t("index.wtdlqs", { year: moment().year() }),
      companyId: null,
      loading: false,
      list: [],
    };
  },
  computed: {
    echarts() {
      return {
        height: "300px",
        id: "trend",
        options: {
          tooltip: {
            trigger: "axis",
            formatter: (params) => {
              return `${params[0].name}<br/>${params[0].marker}：${params[0].value}${this.$t("index.unit")}`;
            },
            backgroundColor: "#808080",
            textStyle: {
              color: "#fff",
            },
          },
          xAxis: {
            type: "category",
            name: this.$t("datetime.unit.monthFull"),
            data: this.list.map((it) => it.name),
            nameGap: 0,
          },
          yAxis: {
            type: "value",
            name: this.$t("index.wtdl"),
            nameRotate: 90,
            nameGap: 15,
            axisNameGap: -15,
            axisLine: {
              show: true,
            },
            axisPointer: {
              snap: true,
            },
          },
          series: [
            {
              data: this.list.map((it) => it.value),
              type: "line",
              smooth: true,
              center: ["50%", "50%"],
            },
          ],
        },
      };
    },
    ...mapGetters("user", ["multipleCompany", "companyList"]),
  },
  methods: {
    async getData () {
      this.loading = true;
      const params = {
        companyId: this.companyId,
      };
      const res = await getOrderTrend(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
        try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
        return;
      }
      this.list = res.data;
      try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
      this.loading = false;
    },
    async getCompanyList() {
      this.companyId = this.companyList[0]?.companyId;
    },
    onCompanyChange(companyId) {
      this.companyId = companyId;
      this.getData();
    },
  },
  async mounted() {
    if (this.multipleCompany) {
      await this.getCompanyList();
    }
    this.getData();
  }
};
</script>

<style lang="less" scoped>
.card-trend {
  .brief-select {
    width: 192px;
    margin-left: 10px;
  }
}
</style>
