<template>
  <ListTitleCard
      :title="$t('menu.message.list')"
  >
    <a-table
        bordered
        :columns="columns"
        :data-source="list"
        :pagination="false"
    >
      <template slot="stateName" slot-scope="text,record">
        <div v-html="record.stateName"></div>
      </template>
    </a-table>
  </ListTitleCard>
</template>
<script>
import ListTitleCard from "@/pages/home/components/ListTitleCard";
import {messageList} from "@/pages/workspace/backgroundCheck/index/api";
import {FETCH_CODE} from "@/config";
import moment from "moment/moment";

export default {
  name: "MessageList",
  components: {
    ListTitleCard
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("message.content"),
          dataIndex: "stateName",
          key: "stateName",
          align: "center",
          scopedSlots: { customRender: "stateName" },
        },
        {
          title: this.$t("message.time"),
          dataIndex: "time",
          key: "time",
          align: "center"
        }
      ],
      list: []
    };
  },
  methods: {
    async getList () {
      const res = await messageList();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.list = res.data || [];
      this.list.map(l => {
        l.time = l.messageDateTime
            ? moment(l.messageDateTime).format("YYYY-MM-DD HH:mm:ss")
            : "";
      });
    }
  },
  mounted() {
    this.getList()
  }
};
</script>
<style lang="less" scoped>
/deep/.company {
  font-weight: bold;
}
</style>
