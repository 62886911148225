<template>
  <div class="form-add-btn">
    <a-button type="primary" @click="save">
      {{ $t("button.save") }}
    </a-button>
    <a-button type="primary" @click="add" :disabled="batchDisabled">
      {{ $t("button.newS") }}{{ $t("button.candidate") }}
    </a-button>
    <BatchModal
      :isBatchModal.sync="isUploadModal"
      v-if="isUploadModal"
      :submit="batchSubmit"
      @success="batchDone"
      :uploadUrl="uploadUrl"
      :downloadUrl="downloadUrl"
      :downloadMethod="downloadMethod"
      :downloadLocal="downloadLocal"
      :downloadName="downloadName"
      :uploadData="uploadData"
      :columns="columns"
    />
  </div>
</template>

<script>
import BatchModal from "@/pages/home/components/BatchModal";

export default {
  name: "ECardButton",
  components: {
    BatchModal,
  },
  props: {
    // 保存
    save: {
      type: Function,
      default: () => {},
    },
    // 处理批量候选人列表方法
    // 请返回 { flag: number, msg: string }
    batchSubmit: {
      type: Function,
      default: () => {},
    },
    // 批量导入候选人完成事件
    batchDone: {
      type: Function,
      default: () => {},
    },
    batchDisabled: Boolean,
    downloadUrl: {
      type: String,
      required: true,
    },
    downloadMethod: {
      type: String,
      default: "GET",
    },
    downloadLocal: Boolean,
    // 下载文件名, 当 downloadLocal 时必传
    downloadName: String,
    uploadUrl: {
      type: String,
      required: true,
    },
    uploadData: Object,
    columns: Array,
  },
  data() {
    return {
        isUploadModal: false
    };
  },
  methods: {
    add() {
      this.isUploadModal = true;
    },
  },
};
</script>

<style lang="less" scoped>
.form-add-btn {
  .btn-group(center);
  margin: 0 auto;
  display: block;
}
</style>
