<!-- 添加地址 modal -->
<template>
  <div>
    <a-modal
      :visible="isShowModal"
      @cancel="cancel"
      @ok="confirm"
      class="address-modal"
      :maskClosable="false"
      :title="title"
    >
      <!--  地址 table   -->
      <a-table
        :columns="columns"
        :data-source="dataList"
        bordered
        :pagination="false"
      >
        <template slot="action" slot-scope="text, record">
          <!-- 操作列 -->
          <a-button
            type="link"
            class="table-btn"
            @click="confirmDelete(record)"
          >
            <a-icon type="delete" /> {{ $t("button.remove") }}
          </a-button>
        </template>
      </a-table>
      <!--   添加地址   -->
      <div class="address-add">
        <a-form-model
          :model="form"
          ref="form"
          :colon="false"
          :rules="validation"
          class="address-add-form"
        >
          <a-form-model-item label="" prop="address" class="address-row">
            <a-input
              ref="inputRef"
              size="large"
              :placeholder="`${$t('homeModal.address.cc')}`"
              v-model="form.address"
              allowClear
              @keydown.enter="addAddress"
            />
          </a-form-model-item>
          <a-button type="primary" @click="addAddress">
            {{ $t("button.add") }}
          </a-button>
        </a-form-model>
      </div>
    </a-modal>
    <!--  抄送地址Modal  -->
    <a-modal
      :title="`${$t('button.edit')}${$t('homeModal.address.cc')}`"
      :visible="isModal"
      @cancel="cancelEdit"
      @ok="confirmEdit"
    >
      <a-form-model
        layout="inline"
        :model="form"
        ref="form"
        :rules="validation"
      >
        <a-form-model-item :label="$t('homeModal.address.cc')" prop="address">
          <a-input
            size="large"
            :placeholder="`${$t('input')}${$t('homeModal.address.cc')}`"
            v-model="form.address"
            allowClear
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { confirmModal, getFakeId } from "@/utils/tools";
import { REG_EMAIL } from "@/config";
import { trim } from "lodash";
import { langMixin } from "@/mixins";

export default {
  name: "AddressModal",
  props: {
    isShowModal: Boolean,
    tableList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [langMixin],
  data() {
    return {
      title: this.$t("homeModal.address.title"),
      columns: [
        {
          title: this.$t("homeModal.address.cc"),
          dataIndex: "email",
          align: "center",
          key: "email",
          width: 200
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ],
      validation: {
        address: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              const val = trim(value);
              if (!val) {
                callback(
                  this.messageFormatter(
                    this.$t("input"),
                    this.$t("homeModal.address.cc")
                  )
                );
                return;
              }
              if (!REG_EMAIL.test(val)) {
                callback(
                  this.messageFormatter(
                    this.$t("input"),
                    this.$t("right"),
                    this.$t("homeModal.address.cc")
                  )
                );
                return;
              }
              // 判重
              if (this.dataList.some(it => it.email === val)) {
                callback(
                  this.messageFormatter(this.$t("homeModal.address.ccUniq"))
                );
                return;
              }
              callback();
            }
          }
        ]
      },
      form: {
        address: ""
      },
      isModal: false,
      dataList: this.tableList
    };
  },
  methods: {
    cancel() {
      this.closeModal();
    },
    confirm() {
      this.isVisible = false;
      this.$emit("change", this.dataList);
      this.closeModal();
    },
    closeModal() {
      this.$emit("update:isShowModal", false);
    },
    // 添加地址
    addAddress() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const value = trim(this.form.address);
          this.dataList.push({
            id: getFakeId(),
            email: value
          });
          this.clearForm();
          // 自动聚焦到输入框
          setTimeout(() => {
            this.$refs.inputRef.focus();
          }, 0);
        }
      });
    },
    remove() {},
    // 表格里删除
    confirmDelete(record) {
      confirmModal(
        this.$t("order.detail.info.confirm"),
        "",
        () => {
          const index = this.dataList.findIndex(i => i.id === record.id);
          this.dataList.splice(index, 1);
        },
        () => {
          console.log("取消");
        }
      );
    },
    // 表格里编辑
    edit(index) {
      console.log(index);
      this.isModal = true;
    },
    cancelEdit() {
      this.isModal = false;
    },
    confirmEdit() {
      this.$refs.form.validate(valid => {
        console.log(valid);
      });
    },
    clearForm() {
      this.$refs.form.resetFields();
      this.form.address = "";
    }
  },
  mounted() {
    this.isVisible = this.isShowModal;
  },
  computed: {}
};
</script>

<style lang="less" scoped>
.table-btn {
  .btn-group();
  color: @error-color;
}
.address-modal {
  .address-add {
    margin-top: @ec-gutter * 2;
    &-form {
      display: flex;
      .address-row {
        margin-right: @ec-gutter;
        margin-bottom: 0;
      }
    }
  }
}
</style>
