<template>
  <div>
    <div
      :is="type ? 'a-upload-dragger' : 'a-upload'"
      :name="config.name"
      :multiple="false"
      :list-type="config.listType"
      :show-upload-list="config.showUploadList"
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      :headers="headers"
      @change="handleChange"
      :before-upload="beforeUpload"
      ref="upload"
      :file-list="uploadedFileList"
      @preview="preview"
    >
      <div v-if="type === 2" class="avatar">
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">
          {{ $t("person.avatar.select") }}
        </div>
      </div>
      <template v-else>
         <a-icon type="cloud-upload" :style="{ color: '#C0C4CC', fontSize: '55px' }"/>
        <p>将文件拖到此处，或<a-button type="link" class="upload-btn">点击上传</a-button></p>
      </template>
    </div>
    <div class="upload-tip">支持格式：.rar .zip .doc .docx .pdf ，单个文件不能超过20MB</div>
  </div>
</template>
<script>
export default {
  name: "UploadFile",
  props: {
    // 1 文件 2 头像
    type: {
      type: Number,
      default: 1,
    },
  },
  components: {},
  computed: {
    config() {
      return {
        name: this.type === 2 ? "avatar" : "file",
        listType: this.type === 2 ? "picture-card" : "text",
        showUploadList: this.type === 2,
        format: this.type === 2 ? this.imgFormat : this.format,
      };
    },
  },
  data() {
    return {
      format: ["pdf", "zip", "docx", "doc", "rar"],
      uploadedFileList: [],
      headers: {
        authorization: "authorization-text",
      },
      loading: false,
      maxSize: 20,
      imgFormat: ["jpg", "png", "JPG", "PNG"],
    };
  },
  methods: {
    // 上传前格式处理、文件大小限制处理
    beforeUpload(file) {
      if (this.config.format.indexOf(this.getFileType(file.name)) < 0) {
        this.$message.error(`仅支持${this.config.format.toString()}格式的文件`);
        this.uploadedFileList = [];
        return false;
      }
      if (file.size / 1024 / 1024 > this.maxSize) {
        this.$message.error(`文件大小不能超过${this.maxSize}M`);
        return false;
      }
    },
    // 获取文件类型
    getFileType(str) {
      const index = str.lastIndexOf(".");
      return str.substr(index + 1);
    },
    preview(file) {
      console.log(file);
    },

    // 上传文件改变
    handleChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@padding: @ec-gutter * 2;
/deep/.ant-upload.ant-upload-select-picture-card {
  float: none;
  margin: 0 auto @padding;
  width: 200px;
  height: 200px;
}
/deep/.ant-card-body {
  padding: 0;
}
.avatar {
  i {
    font-size: 60px;
  }
  .ant-upload-text {
    font-size: 18px;
    margin-top: @padding;
  }
}
.upload-btn {
  padding: 0;
}
/deep/.ant-upload-drag {
  background: #fff;
}
.upload-tip {
  color: @tip-color;
  margin-top: 4px;
}
</style>
