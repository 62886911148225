<template>
  <a-modal
    :title="$t('order.detail.joinGift')"
    v-model="visibleInner"
    class="join-gift-desc-modal"
    :width="650"
    :maskClosable="false"
    destroyOnClose
  >
    <template slot="footer">
      <a-button type="primary" @click="onClose">
        {{ $t("button.confirm") }}
      </a-button>
    </template>
    <div class="join-gift-desc-content">
      <h6>{{ $t("order.gift.description.t1") }}</h6>
      <p>{{ $t("order.gift.description.t1s1") }}</p>
      <p>{{ $t("order.gift.description.t1s2") }}</p>
      <h6>{{ $t("order.gift.description.t2") }}</h6>
      <h6>{{ $t("order.gift.description.t3") }}</h6>
      <h6>{{ $t("order.gift.description.t4") }}</h6>
      <strong>{{ $t("order.gift.description.preT") }}</strong>
      <p>{{ $t("order.gift.description.pre1") }}</p>
      <p>{{ $t("order.gift.description.pre2") }}</p>
      <p>{{ $t("order.gift.description.pre3") }}</p>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "GiftDescription",
  props: { visible: Boolean },
  data() {
    return {};
  },
  computed: {
    visibleInner: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less">
.join-gift-desc-content {
  h6 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0.5em;
  }
  strong {
    display: block;
    margin-top: 2em;
    margin-bottom: 0.5em;
  }
}
</style>
