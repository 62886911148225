<template>
  <!--  停止核实 modal  -->
  <a-modal
    :title="$t('order.table.stop')"
    :visible="isShowModal"
    @cancel="cancel"
    @ok="submit"
    class="data-modal"
  >
    <a-form-model
      :model="formData"
      :rules="validation"
      ref="verifyForm"
      :labelCol="{ span: 8 }"
      :wrapperCol="{ span: 16 }"
    >
      <a-form-model-item :label="$t('order.stopForm.desc')" prop="desc">
        <a-input
          :placeholder="`${$t('input')} ${$t('order.stopForm.desc')}`"
          type="textarea"
          v-model="formData.desc"
          :autoSize="{ minRows: 6, maxRows: 10 }"
          allowClear
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { changeTask } from "@/pages/home/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { trim } from "lodash";

export default {
  name: "StopCheckModal",
  props: {
    isShowModal: Boolean,
    record: Object,
  },
  data() {
    return {
      formData: {
        desc: "",
      },
      validation: {
        desc: [
          { required: true, message: this.$t("order.stopForm.descRequired") },
          { max: 255, message: this.$t("maxInput", { name: this.$t("order.stopForm.desc"), length: 255 }) },
        ],
      },
      loading: false,
    };
  },
  methods: {
    cancel() {
      this.formData = {};
      this.$emit("update:isShowModal", false);
    },
    async submit() {
      if (this.loading) return;
      this.loading = true;
      this.$refs.verifyForm.validate(async (valid) => {
        if (!valid) {
          this.loading = false;
          return;
        }
        const params = {
          changeTypeId: -1,
          changeWay: "撤销核实",
          remark: trim(this.formData.desc),
          taskId: this.record?.taskNo,
        };
        const res = await changeTask(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.operation"),
        });
        this.loading = false;
        this.cancel();
        this.$emit("reload");
      });
    }
  }
};
</script>
