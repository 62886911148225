<template>
  <a-modal
    :title="$t('order.detail.uploadHelper.pe24')"
    v-model="visibleInner"
    class="candidate-modal"
    :width="650"
    :maskClosable="false"
    destroyOnClose
    :closable="false"
    :keyboard="false"
  >
    <template slot="footer">
      <a-row type="flex" :gutter="20">
        <!--<a-col>
          <a-button type="default" @click="onEdit(false)" v-if="eidtable">
            {{ `${$t("button.confirmation")} ${$t("button.modify")}` }}
          </a-button>
          <a-button type="default" @click="onEdit(true)" v-else>
            {{ $t("button.edit") }}
          </a-button>
        </a-col>-->
        <a-col>
          <a-button type="primary" @click="onConfirm">
            {{ $t("button.confirm") }}
          </a-button>
        </a-col>
      </a-row>
    </template>
    <!--候选人表单-->
    <a-form-model
      ref="formRef"
      class="candidate-modal-form"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <!--候选人姓名-->
        <a-col :span="24">
          <a-form-model-item :label="$t('order.detail.name')" prop="name">
            <a-input
              v-model="form.name"
              :placeholder="`${$t('input')}${$t('order.detail.name')}`"
              :disabled="!eidtable"
            />
          </a-form-model-item>
        </a-col>
        <!--候选人手机号码-->
        <a-col :span="24">
          <a-form-model-item
            :label="$t('backCheck.hphone')"
            prop="phoneValue"
            :required="fieldsRequired.includes('phoneNumber')"
          >
            <EcMobile
              :value="form.phoneValue"
              @change="onMobileChange"
              :placeholder="`${$t('input')}${$t('backCheck.hphone')}`"
              :disabled="!eidtable"
            />
          </a-form-model-item>
        </a-col>
        <!-- 候选人证件号码-->
        <a-col :span="24">
          <a-form-model-item
            :label="$t('echeck.table.idCard')"
            prop="idNumbering"
          >
            <a-input
              v-model="form.idNumbering"
              :placeholder="`${$t('input')}${$t('echeck.table.idCard')}`"
              :disabled="!eidtable"
            />
          </a-form-model-item>
        </a-col>
        <!-- 候选人邮箱-->
        <a-col :span="24">
          <a-form-model-item :label="$t('order.detail.email')" prop="email">
            <a-input
              :placeholder="`${$t('input')}${$t('order.detail.email')}`"
              v-model="form.email"
              :disabled="!eidtable"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import EcMobile from "@/components/EcMobile.vue";
import { camelCase, cloneDeep, trim } from "lodash";
import { REG_EMAIL, REG_IDCARD, REG_MOBILE } from "@/config";
import { BACK_ORDER_TYPE } from "@/pages/workspace/backgroundCheck/order/config";
import { CREATE_TYPE } from "@/pages/home/order/config";
import { mapState } from "vuex";
import { showMsg } from "@/utils";

const formEmpty = {
  // 候选人姓名
  name: "",
  // 候选人手机号码
  phoneValue: {
    mobile: "",
    code: "86"
  },
  phone: "",
  phoneDialingCode: "",
  // 候选人证件号码
  idNumbering: "",
  // 候选人邮箱
  email: ""
};

export default {
  name: "CandidateModal",
  components: { EcMobile },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    // 新建委托订单-上传方式
    type: String,
    authorizationRequired: Boolean
  },
  data() {
    return {
      form: cloneDeep(formEmpty),
      labelCol: { xxl: 6, span: 7 },
      wrapperCol: { xxl: 18, span: 17 },
      BACK_ORDER_TYPE,
      eidtable: true,
    };
  },
  computed: {
    ...mapState({
      selected: state => state.back.selectedTemplate
    }),
    // 必填字段动态判断
    fieldsRequired() {
      // 字段对应关系：
      // name=候选人姓名
      // phoneNumber=候选人电话号码
      // idNumber=候选人证件号码
      // email=候选人邮箱
      const fields = [];
      const { module = [], type, moduleIds = [] } = this.selected;
      // 1. 工作单 【姓名】【电话】【邮箱】必填
      if (type === CREATE_TYPE.TRADITION) {
        fields.push("name");
        fields.push("phoneNumber");
        fields.push("email");
        return fields;
      }
      // 2. 易查单 根据授权方式和所选模块判断必填字段
      // 2.1 线上授权-【电话】【邮箱】始终必填 其它根据所选模块返回的必填状态判断
      if ([BACK_ORDER_TYPE[2].name].includes(this.type)) {
        if (this.authorizationRequired) {
          fields.push("phoneNumber");
          fields.push("email");
        }
      } else {
        if (this.authorizationRequired) {
          fields.push("phoneNumber");
          fields.push("email");
        }
      }
      // 2.2 线下授权-根据所选模块返回的必填状态判断
      const moduleList = module.filter(it => moduleIds.includes(it.id));
      for (const module of moduleList) {
        const { candidateDataRequirement = [] } = module;
        candidateDataRequirement.forEach(re => {
          if (!fields.includes(camelCase(re.code))) {
            fields.push(camelCase(re.code));
          }
        });
      }
      return fields;
    },
    rules() {
      // 手机号+区号验证
      const validatePhone = (rule, value, callback) => {
        if (this.fieldsRequired.includes("phoneNumber")) {
          if (!value.code) {
            callback(this.$t("select") + this.$t("order.detail.phoneCode"));
            return;
          }
          if (!trim(value.mobile)) {
            callback(this.$t("input") + this.$t("order.detail.phone"));
            return;
          }
        }
        // 仅验证中国大陆手机号
        if (
          value.code === "86" &&
          trim(value.mobile) &&
          !REG_MOBILE.test(trim(value.mobile))
        ) {
          callback(
            this.$t("input") + this.$t("right") + this.$t("order.detail.phone")
          );
          return;
        }
        callback();
      };

      return {
        // 候选人姓名
        name: [
          {
            required: this.fieldsRequired.includes("name"),
            message: this.$t("input") + this.$t("order.detail.name")
          },
          {
            max: 50,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.name"),
              length: 50
            })
          }
        ],
        // 候选人手机号码
        phoneValue: [{ validator: validatePhone }],
        // 候选人证件号码
        idNumbering: [
          {
            required: this.fieldsRequired.includes("idNumber"),
            pattern: REG_IDCARD,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("backCheck.report.idCard")
          }
        ],
        // 候选人邮箱
        email: [
          {
            required: this.fieldsRequired.includes("email"),
            pattern: REG_EMAIL,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("order.detail.email")
          },
          {
            max: 100,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.email"),
              length: 100
            })
          }
        ]
      };
    },
    visibleInner: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      }
    }
  },
  methods: {
    onValid() {
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            showMsg({
              flag: 1,
              msg: Object.values(errors)[0]?.[0]?.message,
            });
            resolve(false);
            return;
          }
          // 校验通过
          resolve(true);
        });
      });
    },
    async onEdit(eidtable) {
      if (!eidtable) {
        // 确认修改 进行表单校验
        const valid = await this.onValid();
        if (!valid) return;
      }
      this.eidtable = eidtable;
    },
    async onConfirm() {
      // 确定 实际就是关闭
      // 关闭前确保不是在编辑状态（先点确认修改）
      // if (this.eidtable) {
      //   showMsg({
      //     flag: 1,
      //     msg: this.$t("order.detail.uploadHelper.validBeforeEdit"),
      //   });
      //   return;
      // }
      // 确认修改 进行表单校验
      const valid = await this.onValid();
      if (!valid) return;
      this.onClose();
    },
    onClose() {
      this.visibleInner = false;
      this.$emit("close", {
        ...this.form,
        phoneDialingCode: this.form.phoneValue.code,
        phone: this.form.phoneValue.mobile,
      });
    },
    onMobileChange(values) {
      this.form.phoneValue = {
        ...values,
        phoneDialingCode: values.code,
        phone: values.mobile,
      };
    },
    setForm(values) {
      this.form = values;
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.candidate-modal {
  /deep/.ant-select-disabled .ant-select-selection,
  /deep/.ant-input[disabled] {
    border: 0;
    background: unset;
    cursor: default;
  }
}
</style>
