<template>
  <a-modal
    :title="$t('order.detail.uploadHelper.modalTitle')"
    v-model="visibleInner"
    class="upload-helper-modal"
    :width="1002"
    :maskClosable="false"
    destroyOnClose
    :closable="true"
    :keyboard="false"
  >
    <template slot="footer">
      <a-button type="default" @click="onClose">
        {{ $t("button.close") }}
      </a-button>
    </template>
    <nav class="helper-nav">
      <h6 class="helper-nav-title">
        {{$t("order.detail.uploadHelper.title")}}
      </h6>
      <ul class="helper-nav-list">
        <li class="helper-nav-item">
          <a class="helper-nav-link" href="#uploadHelperUseEmail">
            {{$t("order.detail.uploadHelper.email")}}
          </a>
        </li>
        <li class="helper-nav-item">
          <a class="helper-nav-link" href="#uploadHelperFallback">
            {{$t("order.detail.uploadHelper.fallback")}}
          </a>
        </li>
      </ul>
    </nav>
    <div class="helper-content">
      <div class="helper-top">
        <span class="text-danger">{{$t('order.detail.uploadFailedMsg', { name: option.name })}}</span>
        <span class="text-danger">{{$t('order.detail.uploadHelper.tips')}}</span>
      </div>
      <article class="helper-article">
        <section class="helper-article-section">
          <h4 class="helper-article-title" id="uploadHelperUseEmail">
            {{$t("order.detail.uploadHelper.email")}}
          </h4>
          <h5 class="helper-article-title sub">
            {{$t("order.detail.uploadHelper.way", { no: 1 })}}
          </h5>
          <p>{{$t("order.detail.uploadHelper.pe11")}}</p>
          <p>{{$t("order.detail.uploadHelper.pe12")}}</p>
          <p class="text-danger text-bold">{{$t("order.detail.uploadHelper.pe13")}}</p>
          <p class="helper-action send">
            <a href="javascript: void(0);" class="text-link" @click="onEmail">
              {{$t("order.detail.uploadHelper.pe14")}}
            </a>
          </p>
          <h5 class="helper-article-title sub">
            {{$t("order.detail.uploadHelper.way", { no: 2 })}}
          </h5>
          <p>{{$t("order.detail.uploadHelper.pe21")}}</p>
          <p>{{$t("order.detail.uploadHelper.pe22")}}</p>
          <p>{{$t("order.detail.uploadHelper.pe23")}}</p>
          <p class="helper-action review">
            <a href="javascript: void(0);" class="text-link" @click="onReview(1)">
              {{$t("order.detail.uploadHelper.pe24")}}
            </a>
            <a-alert
              class="review-alert"
              :message="$t('order.detail.uploadHelper.pe25')"
              type="error"
              v-if="!checked"
            />
          </p>
          <p>{{$t("order.detail.uploadHelper.pe26")}}</p>
          <!--#region 收件人-->
          <p>
            <span>{{$t("order.detail.uploadHelper.pe27")}}</span>
            <span>{{FILE_SEND_EMAIL}}</span>
            <a href="javascript: void(0);" class="text-copy" @click="onCopy(FILE_SEND_EMAIL)">
              {{$t("button.copy")}}
            </a>
          </p>
          <!--#endregion-->
          <!--#region 主题-->
          <p>
            <span>{{$t("order.detail.uploadHelper.pe28")}}</span>
            <span v-if="checked">
              {{emailTitle}}
            </span>
            <a
              href="javascript: void(0);"
              class="text-copy"
              v-if="checked"
              @click="onCopy(emailTitle)"
            >
              {{$t("button.copy")}}
            </a>
          </p>
          <!--#endregion-->
          <!--#region 正文-->
          <p class="email-main-content">
            <span>{{$t("order.detail.uploadHelper.pe29")}}</span>
            <a
              href="javascript: void(0);"
              class="text-copy"
              v-if="checked"
              @click="onCopy('main-content')"
            >
              {{$t("button.copy")}}
            </a>
            <span class="main-content">
              <template v-if="checked">
                <span v-for="con of emailContent" :key="con">{{con}}</span>
              </template>
            </span>
          </p>
          <!--#endregion-->
          <div class="demo">
            <img
              class="demo-img"
              ref="demoRef"
              src="@/assets/images/order/emailFileDemo.png"
              :alt="$t('order.detail.uploadHelper.demoImgAlt')"
              :title="$t('button.previewBig')"
              width="100%"
              v-viewer="{ navbar: false }"
            />
            <div class="demo-tips" @click="onDemoClick">
              {{$t("order.detail.uploadHelper.demoTips")}}
            </div>
          </div>
          <p class="text-danger text-bold">{{$t("order.detail.uploadHelper.pe210")}}</p>
          <p>{{$t("order.detail.uploadHelper.pe211")}}</p>
        </section>
        <section class="helper-article-section">
          <h4 class="helper-article-title" id="uploadHelperFallback">
            {{$t("order.detail.uploadHelper.fallback")}}
          </h4>
          <p>{{$t("order.detail.uploadHelper.fallbackTips")}}</p>
          <a-alert
            class="helper-alert"
            :message="$t('order.detail.uploadFailed')"
            :description="errorMsgFormatter()"
            type="error"
            show-icon
            :closable="false"
          />
        </section>
      </article>
    </div>
    <!--候选人上传附件失败后 核对信息弹窗-->
    <CandidateModal
      ref="candidateModalRef"
      :visible.sync="checkCandidateVisible"
      :authorizationRequired="authorizationRequired"
      :type="type"
      @close="onCandidateModalClose"
    />
  </a-modal>
</template>

<script>
import CandidateModal
  from "@/pages/workspace/backgroundCheck/order/components/CandidateModal.vue";
import { mapState } from "vuex";
import {
  CHECK_CANDIDATE_TYPES,
  FILE_SEND_EMAIL,
  FILE_TYPES
} from "@/pages/workspace/backgroundCheck/order/config";
import { showMsg } from "@/utils";

export default {
  name: "UploadHelper",
  components: { CandidateModal },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    option: {
      type: Object,
      default: () => {
        return {
          // 文件名
          name: "",
          // 上传失败信息
          msg: "",
          // 上传失败业务id
          tid: "",
        };
      },
    },
    // 授权方式
    authorizationRequired: Boolean,
    // 新建委托订单-上传方式
    type: String,
  },
  data() {
    return {
      checkCandidateVisible: false,
      checkCandidateType: CHECK_CANDIDATE_TYPES[0].key,
      checked: false,
      formData: {},
      FILE_TYPES,
      FILE_SEND_EMAIL,
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.info,
    }),
    visibleInner: {
      get() {
        return this.visible;
      },
      set(visible) {
        // 关闭前拦截
        if (!visible) {
          this.closeConfirm().then((closable) => {
            if (!closable) return;
            this.$emit("update:visible", visible);
          });
          return;
        }
        this.$emit("update:visible", visible);
      }
    },
    emailTitle() {
      return `${this.userInfo.currentCompany.name} ${this.userInfo.emailAddress} ${this.formData.name || ""}`;
    },
    emailContent() {
      const info = [
        `uid:${this.userInfo.userId || ""}`,
        `name:${this.formData.name || ""}`,
        `cid:${this.formData.idNumbering || ""}`,
        `mobileNumber:${this.formData.phone || ""}`,
      ];
      const encode = encodeURI(info.join("\n"));
      return [
        "==Begin==",
        btoa(encode),
        "==End==",
        "---------------",
        this.$t("order.detail.uploadHelper.emailContent1"),
        this.$t("order.detail.uploadHelper.emailContent2"),
        this.$t("order.detail.uploadHelper.emailContent3"),
        this.$t("order.detail.uploadHelper.emailContent4"),
        this.$t("order.detail.uploadHelper.emailContent5") + FILE_TYPES,
      ];
    }
  },
  methods: {
    closeConfirm(addon) {
      return new Promise((resolve) => {
        this.$confirm({
          title: `${this.$t("button.confirmation")}${this.$t("button.close")}`,
          content: () => (
            <div>
              <p>{this.$t("order.detail.uploadHelper.confirmCloseTips")}</p>
              {addon}
            </div>
          ),
          onOk: () => {
            resolve(true);
          },
          onCancel: () => {
            resolve(false);
          },
        });
      });
    },
    onClose() {
      this.visibleInner = false;
    },
    onDemoClick() {
      const viewer = this.$refs.demoRef.$viewer;
      this.$nextTick(() => {
        setTimeout(() => {
          viewer.show();
          viewer.zoom(3);
          viewer.move(0, 1300);
        }, 900);
      });
    },
    errorMsgFormatter() {
      const msgFmt = (msg) => {
        return `${this.$t("order.detail.uploadFailedMsg", { name: this.option.name })}${msg}`;
      };
      return (
        <div>
          <p>{msgFmt(this.option.msg)}</p>
          <p>{this.$t("order.detail.uploadFailedHelp")}</p>
          {
            this.option.tid ? (
              <p>{this.$t("order.detail.uploadFailedTid")}{this.option.tid}</p>
            ) : null
          }
        </div>
      );
    },
    onReview(idx) {
      this.$emit("review", (res) => {
        this.formData = res;
        this.checkCandidateType = CHECK_CANDIDATE_TYPES[idx].key;
        this.checkCandidateVisible = true;
        this.$refs.candidateModalRef.setForm(res);
      });
    },
    onCandidateModalClose(values) {
      this.checked = true;
      this.formData = values;
      // 如果是一键发送 调用一键发送方法
      if (this.checkCandidateType === CHECK_CANDIDATE_TYPES[0].key) {
        this.onEmail();
      }
    },
    onCopy(str) {
      // 正文从这里生成
      if (str === "main-content") {
        str = this.emailContent.join("\n");
      }
      // 其它的直接复制到剪贴板
      navigator.clipboard.writeText(str);
      showMsg({
        flag: 0,
        msg: this.$t("showMsg.success.copy"),
      });
    },
    // 一键发送
    async onEmail() {
      // 已经核对过直接拉起邮箱客户端
      if (this.checked) {
        const a = document.createElement("a");
        a.href = "mailto:" +
          FILE_SEND_EMAIL +
          `?subject=${this.emailTitle}` +
          `&body=${this.emailContent.join("%0d%0a")}`; // 换行 outlook 有效 foxmail无效
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        showMsg({
          flag: 0,
          msg: this.$t("order.detail.uploadHelper.callEmailClient"),
        });
        // 弹出询问框 让用户选择是否关闭
        // const confirmed = await this.closeConfirm(
        //   <p>
        //     <a href={a.href}>{this.$t("button.reSend")}</a>
        //   </p>
        // );
        // if (confirmed) {
        //   this.$emit("update:visible", false);
        // }
        return;
      }
      // 未核对过先弹窗核对信息
      this.onReview(0);
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
@border-card: 1px solid rgba(0, 0, 0, .3);

.upload-helper-modal {
  .helper-content {
    padding-right: 250px;
  }
  .helper-nav {
    position: sticky;
    z-index: 1;
    width: 200px;
    height: 0;
    left: 100%;
    top: 65px;

    &-title {
      font-size: 16px;
      font-weight: bold;
    }
    &-list {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      list-style: disc;
      gap: 5px;
    }
    &-item {}
    &-link {
      color: @primary-color;
    }
  }
  .helper-top {
    border-bottom: @border-card;
    padding: 10px 0;
  }
  .helper-article {
    padding: 10px 0;
    &-section {
      border-bottom: @border-card;
      padding: 10px 0;
      &:last-child {
        border-bottom: 0;
      }
    }
    &-title {
      font-weight: bold;
      margin-top: 10px;
      &.sub {
        margin-top: 1em;
      }
    }
    .helper-action {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      &.send {}
      &.review {
        .review-alert {
          display: inline-block;
          padding: 5px 100px;
          /deep/.ant-alert-message {
            color: @error-color;
            font-size: 14px;
          }
        }
      }
    }
    .email-main-content {
      .main-content {
        display: flex;
        background: rgba(0, 0, 0, .1);
        margin-top: 5px;
        padding: 5px;
        min-height: 150px;
        word-break: break-all;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        span,
        hr {
          width: 100%;
          flex: 1;
        }
      }
    }
    .demo {
      position: relative;
      width: 400px;
      height: 176px;
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: hidden;
      &-img {
        width: 100%;
        height: 100%;
      }
      &-tips {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.3);
        font-size: 50px;
        font-weight: bold;
        left: 0;
        top: 0;
      }
    }
  }
  .helper-alert {
    word-break: break-all;
    width: 410px;
    margin-top: 20px;
  }
  .text-danger {
    color: @error-color;
  }
  .text-link {
    color: @primary-color;
  }
  .text-copy {
    color: @primary-color;
    margin-left: 10px;
  }
  .text-bold {
    font-weight: bold;
  }
  p {
    margin-bottom: 0.5em;
  }
}
</style>
