<template>
  <div class="order-report ec-detail ec-card">
    <a-spin :spinning="loading">
      <!-- 基本信息 -->
      <div class="card-title">
        <span class="card-title-real">{{ $t("order.report.info") }}</span>
      </div>
      <div class="card-body">
        <a-descriptions bordered :column="2" class="order-report-base-info">
          <a-descriptions-item :label="i.label" v-for="i in list" :key="i.value">
            <template v-if="i.key === 'ReportLevelName'">
              <span
                  class="ec-report-report-level"
                  :class="`ec-report-report-level-${info.ReportLevelName}`"
                  :title="info[i.key]"
                  v-if="info.ReportLevelImagePath"
              />
            </template>
            <template v-else-if="i.key === 'TaskRecordFinish'">
              {{ info[i.key] === 1 ? $t("button.done") : $t("button.delay") }}
            </template>
            <span v-else>{{ info[i.key] || '--'}}</span>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 概述 -->
      <div class="card-title">
        <span class="card-title-real">{{ $t("order.report.view") }}</span>
      </div>
      <div class="card-body">
        <ECTable
          bordered
          :columns="columns"
          :data-source="info.TaskRecordModel"
          :pagination="false"
          class="report-table"
        >
          <template slot="show" slot-scope="text, record">
            {{ record.TaskRecordModuleGroups[0].IsShow ? $t('backCheck.report.show') : $t('backCheck.report.noShow') }}
          </template>
          <template slot="ShieldName" slot-scope="text, record">
            <span
                class="ec-report-report-level"
                :class="`ec-report-report-level-${record.ShieldNameEN}`"
                :title="record.ShieldName"
            />
          </template>
          <template slot="SummaryValue" slot-scope="text, record">
            <span v-if="record.ShieldNameEN.toLocaleLowerCase() === 'red'" class="ec-text-danger">{{ text }}</span>
            <span v-else-if="record.ShieldNameEN.toLocaleLowerCase() === 'green'" class="ec-text-success">{{ text }}</span>
            <span v-else-if="record.ShieldNameEN.toLocaleLowerCase() === 'yellow'" class="ec-text-warning">{{ text }}</span>
            <span v-else>{{ text }}</span>
          </template>
        </ECTable>
      </div>
      <div
          v-for="(model, modelIdx) in info.TaskRecordModel"
          :key="model.ProductID"
          class="order-report-model"
      >
        <div class="card-title">
          <span class="card-title-real" v-if="isEnglish">{{ model.ProductNameEN }}</span>
          <span class="card-title-real" v-else>{{ model.ProductName }}</span>
          <template v-if="modelIdx === 0 && model.ProductSpecallType === 406">
            <span class="card-title-real" v-if="isEnglish">——{{ info.CompanyName }}</span>
            <span class="card-title-real" v-else>——{{ info.CompanyNameEN }}</span>
          </template>
        </div>

        <div
            class="order-report-group"
            v-for="group in model.TaskRecordModuleGroups"
            :key="group.ProblemGroupID"
        >
          <a-table
              bordered
              :columns="group.tableColumns"
              :data-source="group.tableData.list"
              :pagination="false"
              class="order-report-group-table"
          />
        </div>
      </div>
      <div class="report-btn">
      <!--<a-button
            type="primary"
            :loading="downloadLoading"
            @click="onDownload"
        >
          {{ $t("button.downloadS") }}
        </a-button>-->
        <a-button @click="onBack">
          {{ $t("button.back") }}
        </a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {taskModuleRecord} from "@/pages/home/api";
import {FETCH_CODE} from "@/config";
import { showMsg } from "@/utils";
import ECTable from "@/components/ECTable";
import { getFakeId, getType, isJsonStr } from "@/utils/tools";
import { ReportKeys } from "@/pages/home/order/config";
import { cloneDeep } from "lodash";
import { langMixin } from "@/mixins";

export default {
  name: "OrderResult",
  components: { ECTable },
  mixins: [langMixin],
  data() {
    return {
      list: [
        {
          key: "CompanyName",
          label: this.$t('backCheck.report.client')
        },
        {
          key: "CandidateName",
          label: this.$t('backCheck.cname')
        },
        {
          key: "TaskNum",
          label: this.$t('order.filter.number')
        },
        {
          key: "CandidateSexName",
          label: this.$t('order.detail.sex')
        },
        {
          key: "CandidateIdNum",
          label: this.$t('backCheck.report.idCard')
        },
        {
          key: "CandidateBirth",
          label: this.$t('present.info.birth')
        },
        {
          key: "TaskRecordFinish",
          label: this.$t('order.filter.status')
        },
        {
          key: "ReportLevelName",
          label: this.$t('order.filter.level')
        },
        {
          key: "ReportSumbitDateValue",
          label: this.$t('backCheck.report.submitDate')
        },
      ],
      info: {
        CandidateName: "",
        CandidateSexName: "",
        CompanyName: "",
        ReportLevelName: "",
        ReportLevelImagePath: "",
        ReportSumbitDateValue: "",
        TaskNum: "",
        CandidateBirth: "",
        TaskRecordModel: []
      },
      data: [...new Array(5).keys()].map((i) => ({
        name: "有限民事诉讼记录查询" + i,
        key: i,
        status: "已验证",
        show: "显示",
        desc: "属实",
        dun: "绿盾",
      })),
      columns: [
        {
          title: this.$t("order.report.table.name"),
          dataIndex: "ProductName",
          key: "ProductName",
          width: 100,
          align: "center",
        },
        {
          title: this.$t("order.report.table.status"),
          dataIndex: "ParmSummaryStateName",
          key: "ParmSummaryStateName",
          width: 100,
          align: "center",
        },
        {
          title: this.$t("order.report.table.show"),
          dataIndex: "show",
          key: "show",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "show" },
        },
        {
          title: this.$t("order.report.table.desc"),
          dataIndex: "SummaryValue",
          key: "SummaryValue",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "SummaryValue" },
        },
        {
          title: this.$t("order.report.table.dp"),
          dataIndex: "ShieldName",
          key: "ShieldName",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "ShieldName" },
        },
      ],
      loading: false,
      downloadLoading: false,
      titleControlColumns: [
        {
          title: this.$t("backCheck.report.problemName"),
          dataIndex: "problemName",
          width: 100,
          align: "center",
        },
        {
          title: this.$t("backCheck.report.summaryValue"),
          dataIndex: "summaryValue",
          width: 100,
          align: "center",
          customRender: (text, row, idx) => {
            return {
              children: text,
              attrs: {
                colSpan: row.isFoot ? 2 : 1,
              },
            };
          },
        },
        {
          title: this.$t("backCheck.report.authenticity"),
          dataIndex: "authenticity",
          width: 100,
          align: "center",
          customRender: (text, row, idx) => {
            return {
              children: text,
              attrs: {
                colSpan: row.isFoot ? 0 : 1,
              },
            };
          },
        },
      ],
    };
  },
  methods: {
    async getReport() {
      this.loading = true;
      const { id, status } = this.$route.params;
      if (!id) {
        await showMsg({
          flag: FETCH_CODE.ERROR.KEY,
          msg: this.$t("order.detail.invalid"),
        });
        this.loading = false;
        this.onBack();
        return;
      }
      const res = await taskModuleRecord({
        taskId: id,
        taskState: status,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.loading = false;
        return
      }
      const info = res.data || {};
      info.CandidateBirth = info.CandidateBirth ? info.CandidateBirth.split("T")[0] : "";
      // 处理背调模块信息
      const { TaskRecordModel = [] } = info;
      for (const model of TaskRecordModel) {
        const { TaskRecordModuleGroups = [], TitleControl, ProductID } = model;
        for (let i = 0; i < TaskRecordModuleGroups.length; i++) {
          const group = TaskRecordModuleGroups[i];
          const { TaskRecordProblems = [] } = group;
          group.tableColumns = cloneDeep(this.titleControlColumns);
          group.tableData = { list: [] };
          TaskRecordProblems.forEach((problem, index) => {
            const { ProblemName } = problem;
            const obj = {
              problemName: problem.ProblemName,
              summaryValue: problem.SummaryValue,
              authenticity: "",
              childrenTable: [],
              isFixedSub: false, // 是否强制子表格
              isFoot: ProblemName === "核实时间",
            };
            if (this.isEnglish) {
              obj.problemName = problem.ProblemNameEN;
              obj.summaryValue = problem.SummaryValueEN || problem.SummaryValue;
            }

            // 空数据处理
            if (ProblemName === "专业资格证书") {
              if (!obj.summaryValue) {
                // const _summaryValue = [
                //   {
                //     occupation: "",
                //     level: "",
                //     submitOrgName: "",
                //     certificateID: "",
                //     BanZhengRiQi: "",
                //   },
                // ];
                // obj.summaryValue = JSON.stringify(_summaryValue);
              }
            }

            // 判断是否json
            if (obj.isFixedSub || (obj.summaryValue && isJsonStr(obj.summaryValue))) {
              const subData = JSON.parse(obj.summaryValue) || [];
              const child = {
                key: getFakeId(),
                columns: [],
                data: [],
              };
              let skipCols = [];
              let reportKeys = {};

              // 特殊数据处理
              if (ProblemName === "网贷黑名单检索") {
                reportKeys = {
                  sortTimeString: "贷款时间",
                  title: "来源",
                };
              }
              if (ProblemName === "失信公告检索") {
                reportKeys = {
                  sortTimeString: "立案时间",
                  title: "标题",
                };
              }
              if (ProblemName === "执行公告检索") {
                reportKeys = {
                  sortTimeString: "立案时间",
                  title: "内容概要",
                  body: "标题",
                };
              }
              if (ProblemName === "曝光台信息检索") {
                reportKeys = {
                  sortTimeString: "立案日期",
                  title: "法院名称",
                };
              }
              if (ProblemName === "开庭公告检索") {
                reportKeys = {
                  sortTimeString: "开庭日期",
                  title: "标题",
                };
              }
              if (ProblemName === "案件流程检索") {
                reportKeys = {
                  sortTimeString: "立案日期",
                  title: "标题",
                  body: "正文",
                };
              }
              if (ProblemName === "涉诉裁决检索") {
                reportKeys = {
                  sortTimeString: "审结时间",
                  court: "审理法院",
                  caseType: "文书类型",
                  title: "标题",
                  body: "内容概要",
                };
              }
              if (ProblemName === "法院公告检索") {
                reportKeys = {
                  sortTimeString: "立案日期",
                  title: "内容",
                  body: "标题",
                };
              }
              if (ProblemName === "专业资格证书") {
                skipCols = ["occupation", "level"];
                subData.forEach((it) => {
                  it.AuthenticationContent = `${it.occupation}--${it.level}`;
                  if (it.AuthenticationContent === "--") {
                    it.AuthenticationContent = "";
                  }
                });
              }
              if (ProblemName === "企业法人信息") {
                reportKeys = {};
              }
              if (ProblemName === "有限民事诉讼案件概览") {
                skipCols = ["PreservationSearch"];
              }

              if (getType(subData) === "array") {
                if (subData[0]) {
                  for (const k in subData[0]) {
                    if (skipCols.includes(k)) continue;
                    let na = reportKeys[k] || ReportKeys[k] || k;
                    child.columns.push({
                      title: na,
                      dataIndex: k,
                      align: "center",
                    });
                  }
                }
                subData.forEach((it) => {
                  child.data.push({ ...it });
                });
              } else {
                const isRiskTrue = { isRisk: "存在风险" };
                const isRiskFalse = { isRisk: "无风险" };
                child.columns.push({
                  title: "",
                  dataIndex: "isRisk",
                  align: "center",
                });
                for (const k in subData) {
                  if (skipCols.includes(k)) continue;
                  let na = reportKeys[k] || ReportKeys[k] || k;
                  child.columns.push({
                    title: na,
                    dataIndex: k,
                    align: "center",
                  });
                  if (subData[k] === true) {
                    isRiskTrue[k] = <span style="color: #F03738;font-weight: 600;">√</span>;
                  } else {
                    isRiskFalse[k] = <span style="color: #52c41a;font-weight: 600;">√</span>;
                  }
                }
                child.data.push(isRiskTrue);
                child.data.push(isRiskFalse);
              }
              obj.childrenTable.push(child);
              // 有子集的不再显示列
              group.tableColumns[0] = {
                ...group.tableColumns[0],
                customRender: (text, row, idx) => {
                  const obj = {
                    children: text,
                    attrs: {},
                  };
                  if (!row.isFoot) {
                    if (row.childrenTable && row.childrenTable.length) {
                      obj.children = row.childrenTable.map((child) => (
                        <a-table
                          title={index === 0 ? undefined : () => row.problemName}
                          bordered
                          columns={child.columns}
                          data-source={child.data}
                          pagination={false}
                          className="order-report-group-table-sub"
                          key={child.key}
                        />
                      ));
                    }
                    obj.attrs.colSpan = 3;
                  }
                  return obj;
                },
              };
              group.tableColumns[1] = {
                ...group.tableColumns[1],
                customRender: (text, row, idx) => {
                 return {
                   children: text,
                   attrs: {
                     colSpan: row.isFoot ? 2 : 0,
                   },
                 };
                },
              };
              group.tableColumns[2] = {
                ...group.tableColumns[2],
                customRender: (text, row, idx) => {
                  return {
                    children: text,
                    attrs: {
                      colSpan: 0,
                    },
                  };
                },
              };
              // 第一行就是表格的 不再显示表头
              if (index === 0) {
                group.tableColumns[0].colSpan = 3;
                group.tableColumns[0].title = ProblemName;
                group.tableColumns[1].colSpan = 0;
                group.tableColumns[2].colSpan = 0;
              }
              obj.summaryValue = "";
            }
            else {
              if (!obj.summaryValue) {
                obj.summaryValue = this.$t("backCheck.report.empty");
              }
            }
            group.tableData.list.push(obj);
          });
        }
      }
      this.info = info;
      this.loading = false;
    },
    onBack() {
      this.$router.back();
    },
    async onDownload() {
      if (this.downloadLoading) return;
      this.downloadLoading = true;
      await showMsg({
        flag: FETCH_CODE.ERROR.KEY,
        msg: this.$t("showMsg.warning.unDev"),
      });
      this.downloadLoading = false;
    },
  },
  mounted() {
    this.getReport();
  },
};
</script>

<style lang="less" scoped>
.order-report {
  background: #fff;
  padding-left: 0;
  padding-right: 0;
  .order-report-base-info {
    /deep/.ant-descriptions-view {
      border-radius: 8px;
    }
  }
  .order-report-group-table-sub {
    /deep/ .ant-table-bordered .ant-table-body > table {
      border-top: 1px solid #e8e8e8 !important;
      border-left: 1px solid #e8e8e8 !important;
    }
  }
  .order-report-group-table {
    /deep/ .ant-table-tbody .ant-table-row:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }
      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    /deep/ .ant-table-bordered .ant-table-title {
      font-weight: 600 !important;
    }
    /deep/ .ant-table-tbody > tr:nth-child(even) > td {
      background: unset;
    }
    /deep/ .ant-table-thead th {
      background: #f2f5fb;
    }
  }
  .order-report-group {
    margin: 15px;
  }
  .card-title {
    color: @font-color;
    font-size: 18px;
    font-weight: bold;
    .relative();
    &-real {
      margin-left: 15px;
    }
    &:before {
      display: block;
      content: " ";
      width: 3px;
      height: 18px;
      background: @primary-color;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }
  /deep/.list-card {
    margin-bottom: @ec-gutter;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .base-item {
    .base-title {
      width: 35%;
      text-align: right;
      padding: @ec-gutter @ec-gutter * 2;
      background: #f5f5f5;
      border: 1px solid #e9e9e9;
      border-top: none;
      .left();
      min-height: 42px;
    }
    .base-value {
      width: 65%;
      .left();
      border-bottom: 1px solid #e9e9e9;
      .no-wrap();
      padding: @ec-gutter @ec-gutter * 2;
      min-height: 42px;
    }
  }
  .report-btn {
    margin-top: 60px;
    .btn-group(center);
  }
  .report-table {
    /deep/.ant-table-thead {
      background: #f5f5f5;
    }
  }
  .shield-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    vertical-align: middle;
  }
}
.card-body {
  padding: @ec-gutter20;
}
/deep/.ant-col {
  &:first-child,
  &:nth-child(2) {
    .base-item {
      border-top: 1px solid #e9e9e9;
    }
  }
  &:nth-child(2n) {
    .base-item {
      .base-value {
        border-right: 1px solid #e9e9e9;
      }
    }
  }
}
</style>
