<template>
  <div class="ec-detail background-order">
    <!-- 基础信息 -->
    <BaseInfo ref="baseInfoRef" :type="createType" />

    <!-- 新建订单进度 -->
    <ECard
      :title="`${$t('backCheck.order')}`"
      :isShowIcon="false"
      class="background-order-body"
    >
      <ul class="background-order-progress">
        <li
          v-for="it in progress"
          :key="it.key"
          class="progress-item"
          :class="progressActive >= it.key ? 'active' : ''"
        >
          <div class="progress-num">{{ it.key }}</div>
          <div class="progress-label">
            {{ isEnglish ? it.nameEn : it.name }}
          </div>
        </li>
      </ul>
      <div class="background-order-content">
        <transition name="slide-fade">
          <router-view
            :type="type"
            @updateType="onUpdateType"
          />
        </transition>
      </div>
    </ECard>
  </div>
</template>

<script>
import BaseInfo from "@/pages/home/components/BaseInfo";
import ECard from "@/components/ECard.vue";
import {
  BACK_ORDER_PROGRESS,
  BACK_ORDER_TYPE
} from "@/pages/workspace/backgroundCheck/order/config";
import { mapState } from "vuex";
import { langMixin } from "@/mixins";

export default {
  name: "BackOrderAdd",
  components: {
    ECard,
    BaseInfo,
  },
  mixins: [langMixin],
  data() {
    return {
      pending: false,
      createType: "0",
      progress: [...BACK_ORDER_PROGRESS],
      type: BACK_ORDER_TYPE[0].name,
    };
  },
  computed: {
    ...mapState({
      progressActive: (state) => state.back.progressActive,
    }),
  },
  methods: {
    // 根据url初始化当前进度
    initProgress() {
      const { type } = this.$route.params;
      const curr = BACK_ORDER_PROGRESS.find((it) => {
        return it.routeName === this.$route.name;
      });
      // url参数不符合预设值 重定向到上传方式选择页面
      if (!BACK_ORDER_TYPE.some((it) => it.name === type) || !curr) {
        this.$router.replace({
          name: "BackOrderAdd",
        });
        return;
      }
      // 符合预设值 读取路由名字判断当前在第几步
      this.$store.commit("back/setProgressActive", curr.key);
      this.type = type;
    },
    onUpdateType(type) {
      this.type = type;
    },
  },
  created() {
    this.initProgress();
  },
};
</script>

<style lang="less" scoped>
.background-order {
  &-body {}
  &-progress {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 112px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      width: 786px;
      height: 0;
      border-top: 2px dotted #CCD8F0;
      left: 50%;
      transform: translateX(-50%);
      top: 25px;
    }

    .progress-item {
      width: 150px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      position: relative;
      z-index: 1;

      &.active {
        .progress-num {
          background: #266FE8;
          border-color: #CCD8F0;
          color: #ffffff;
        }
        .progress-label {
          color: #293B58;
        }
      }
    }
    .progress-num {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 4px solid #A6AAB2;
      text-align: center;
      line-height: 42px;
      font-size: 22px;
      font-weight: 400;
      color: #A6AAB2;
    }
    .progress-label {
      font-size: 14px;
      font-weight: 400;
      color: #A6AAB2;
    }
  }
  &-content {
    margin-top: 72px;
  }
  /deep/&-foot {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 31px;
    margin-top: 62px;

    .foot-btn {
      padding: 0 52px;
    }
  }
}
</style>
