<template>
  <div class="bc-wrap">
    <a-row :gutter="gutter">
      <a-col
        :span="i.span || span"
        v-for="i in columnsList"
        :key="i.key"
        class="bc-item"
      >
        <div :is="i.component" :class="[`${i.class || 'ec-card'}`]" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import CardCreate from "./CardCreate";
import CardBrief from "./CardBrief";
import CardMsg from "./CardMsg";
import CardOrderNum from "./CardOrderNum";
import CardShield from "./CardShield";
import CardTrend from "./CardTrend";
import CardReport from "./CardReport";
import CardCandidate from "./CardCandidate";
import CardTrack from "./CardTrack";

export default {
  components: {
    CardTrend,
    CardCreate,
    CardBrief,
    CardMsg,
    CardOrderNum,
    CardShield,
    CardReport,
    CardCandidate,
    CardTrack
  },
  data() {
    return {
      gutter: [16, 16],
      span: 12,
      columnsList: [
        {
          key: "2",
          component: `CardCreate`,
          span: 24
        },
        {
          key: "3",
          component: `CardBrief`,
          span: 18,
          class: "card-height"
        },
        {
          key: "4",
          component: `CardMsg`,
          span: 6,
          class: "card-height"
        },
        {
          key: "5",
          component: `CardOrderNum`,
          span: 12
        },
        {
          key: "6",
          component: `CardShield`,
          span: 12
        },
        {
          key: "7",
          component: `CardTrend`,
          span: 12
        },
        {
          key: "8",
          component: `CardReport`,
          span: 12
        },
        {
          key: "9",
          component: `CardCandidate`,
          span: 24
        },
        {
          key: "10",
          component: `CardTrack`,
          span: 24
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.bc-wrap {
  padding: 20px;
  .card-height {
    height: 395px;
  }
  .bc-item {
    position: relative;
  }
}
</style>
