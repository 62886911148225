<template>
  <div class="ec-card template-select">
    <a-spin :spinning="loading">
      <div class="ec-card-header">
        <span class="ec-card-title">
          {{ $t("backCheck.orPackageSelect") }}
        </span>
        <div class="icon-tip"></div>
      </div>
      <a-row :gutter="[16, 16]">
        <a-col :span="5" v-for="(i, index) in templatesList" :key="i.id">
          <div
            :class="[
              selectIndex === index
                ? 'template-select-card selected'
                : 'template-select-card'
            ]"
            @click="selectTemp(i, index)"
          >
            <div class="template-select-card-text">{{ i.template }}</div>
            <i
              class="iconfont icon-check-select"
              v-if="selectIndex === index"
            />
            <i class="iconfont icon-check-default" v-else />
          </div>
        </a-col>
      </a-row>
    </a-spin>
    <PackageModal
      :isPackageModal.sync="isShowModal"
      v-if="isShowModal"
      :title="currentItem.template"
      :item="currentItem.surveyMods || []"
      @ok="getSelectTemplate"
    />
  </div>
</template>

<script>
import PackageModal from "./PackageModal";
import { getEntrustTemplate } from "@/pages/home/order/api";
import { showMsg } from "@/utils";
import { FETCH_CODE } from "@/config";

export default {
  name: "TemplateSelect",
  components: {
    PackageModal
  },
  data() {
    return {
      templatesList: [],
      isShowModal: false,
      title: "",
      currentItem: {},
      openIndex: "",
      selectIndex: "",
      loading: false
    };
  },
  methods: {
    onValid() {
      if (
        (!this.selectIndex && this.selectIndex !== 0) ||
        this.selectIndex < 0 ||
        !this.templatesList[this.selectIndex]
      ) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("order.detail.emptyTemplate")
        });
        return false;
      }
      return this.templatesList[this.selectIndex];
    },
    selectTemp(item, index) {
      this.currentItem = item;
      this.openIndex = index;
      this.isShowModal = true;
    },
    async getTemplates() {
      this.loading = true;
      const res = await getEntrustTemplate();
      this.templatesList = res.data || [];
      this.loading = false;
    },
    getSelectTemplate() {
      this.selectIndex = this.openIndex;
      this.$emit("change", this.templatesList[this.selectIndex]);
    }
  },
  mounted() {
    this.getTemplates();
  }
};
</script>

<style lang="less" scoped>
.template-select {
  &-card {
    .pointer();
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px rgba(15, 113, 222, 0.06);
    border-radius: 8px;
    border: 1px solid #ccd8f0;
    display: flex;
    align-items: center;
    padding: 25px @ec-gutter20;
    justify-content: space-between;
    &-text {
      .single-hidden();
      margin-right: @ec-gutter;
      font-size: @font-size-large;
      color: @font-color;
    }
    .iconfont {
      font-size: 20px;
    }
  }
}
.selected {
  border: 1px solid @primary-color;
}
/deep/.ant-col-5 {
  width: 20%;
}
</style>
