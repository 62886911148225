<template>
  <div>
    <p>{{ $t("order.filter.name") }}： {{ record.candidateName }}</p>
    <p>{{ $t("order.filter.number") }}： {{ record.reportNumbering }}</p>
    <a-table
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: this.onSelectChange
      }"
    >
      <template slot="action" slot-scope="text, record">
        <a-button type="link" class="table-btn" @click="onDownload(record)">
          {{ $t("button.downloadS") }}
        </a-button>
      </template>
    </a-table>
    <a-button
      type="primary"
      icon="download"
      class="batch-btn"
      v-if="list.length"
      @click="onDownloadBatch()"
      :loading="pending"
    >
      {{ $t("button.download") }}
    </a-button>
  </div>
</template>
<script>
import {
  downloadZip,
  singleFileDownload
} from "@/pages/workspace/backgroundCheck/presentRecord/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { getCandidateFiles } from "@/pages/home/api";

export default {
  name: "CandidateFile",
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      columns: [
        {
          title: this.$t("order.dataForm.filename"),
          key: "file",
          dataIndex: "file",
          align: "left",
          width: 250
        },
        {
          title: this.$t("order.dataForm.type"),
          key: "fileType",
          dataIndex: "fileType",
          align: "center",
          width: 70,
          ellipsis: true
        },
        {
          title: this.$t("order.dataForm.updateTime"),
          key: "lastChangeTime",
          dataIndex: "lastChangeTime",
          align: "center",
          width: 160,
          ellipsis: true
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 100
        }
      ],
      selectedRowKeys: [],
      pending: false
    };
  },
  methods: {
    async onDownload({ fileNumbering }) {
      if (this.pending) return;
      this.pending = true;
      const res = await singleFileDownload({
        fileNo: fileNumbering
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.pending = false;
        return;
      }
      this.pending = false;
    },
    async onDownloadBatch() {
      if (!this.selectedRowKeys.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: `${this.$t("file.downloadSelect")}`,
        });
        return;
      }
      if (this.pending) return;
      this.pending = true;
      const res = await downloadZip({
        fileNos: this.selectedRowKeys.join(",")
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.pending = false;
        return;
      }
      this.pending = false;
    },
    onSelectChange(selected) {
      this.selectedRowKeys = selected;
    },
    async getList() {
      const res = await getCandidateFiles({ taskId: this.record.taskId });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      const list = res.data || [];
      this.list = list.map(it => {
        return {
          ...it,
          key: it.fileNumbering
        };
      });
    }
  },
  mounted() {
    this.getList();
  }
};
</script>
<style lang="less" scoped>
.batch-btn {
  margin-top: @ec-gutter20;
}
</style>
