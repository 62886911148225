<!-- 委托单量 -->
<template>
  <div class="card-order">
    <a-spin :spinning="loading">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      <CardHeader :title="$t('index.wtdl')" :icon="'icon-annular'">
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="companyId"
          class="brief-select"
          @change="onCompanyChange"
          v-if="multipleCompany"
        >
          <a-select-option
            :value="i.companyId"
            v-for="i in companyList"
            :key="i.companyId"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="selectedTime"
          class="brief-select"
          @change="onDateChange"
        >
          <a-select-option :value="i.key" v-for="i in datetimeFilterList" :key="i.key">
            {{ i.label }}
          </a-select-option>
        </a-select>
      </CardHeader>
      <Echarts
        ref="eChartsRef"
        :width="echarts.width"
        :height="echarts.height"
        :id="echarts.id"
        :options="echarts.options"
      />
    </a-spin>
  </div>
</template>

<script>
import Echarts from "@/components/Echarts";
import CardHeader from "./CardHeader.vue";
import { getTemplateCount } from "@/pages/workspace/backgroundCheck/index/api";
import { DateFormat, FETCH_CODE } from "@/config";
import { datetimeFilter } from "@/mixins";
import moment from "moment/moment";
import { mapGetters } from "vuex";

export default {
  name: "CardOrderNum",
  components: {
    Echarts,
    CardHeader,
  },
  mixins: [datetimeFilter],
  data() {
    return {
      title: this.$t("index.wtdl"),
      companyId: null,
      currentDate: {
        startTimeStr: moment().format(DateFormat),
        endTimeStr: moment().format(DateFormat),
      },
      selectedTime: 1,
      list: [],
      loading: false,
    };
  },
  computed: {
    echarts() {
      return {
        height: "300px",
        id: "order",
        options: {
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              return `${params.marker}${params.name}: ${params.value}${this.$t("index.unit")}`;
            },
            borderColor: "transparent",
          },
          legend: {
            top: "bottom",
            icon: "circle",
          },
          label: {
            formatter: "{b}: {d}%",
          },
          series: [
            {
              type: "pie",
              radius: ["30%", "60%"],
              avoidLabelOverlap: false,
              center: ["50%", "45%"],
              data: this.list,
            },
          ],
        },
      };
    },
    ...mapGetters("user", ["multipleCompany", "companyList"]),
  },
  methods: {
    async getData () {
      this.loading = true;
      const params = {
        startTime: this.currentDate.startTimeStr,
        endTime: this.currentDate.endTimeStr,
        companyId: this.companyId,
      };
      const res = await getTemplateCount(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
        try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
        return;
      }
      this.list = res.data;
      try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
      this.loading = false;
    },
    async getCompanyList() {
      this.companyId = this.companyList[0]?.companyId;
    },
    onCompanyChange(companyId) {
      this.companyId = companyId;
      this.getData();
    },
    onDateChange(value) {
      const index = this.datetimeFilterList.findIndex(i => i.key === value);
      if (index !== -1) {
        this.currentDate = this.datetimeFilterList[index];
      }
      this.getData();
    },
  },
  async mounted() {
    if (this.multipleCompany) {
      await this.getCompanyList();
    }
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.card-order {
  padding: 20px;
  .order-select {
    width: 120px;
    margin-left: 10px;
  }
  .brief-select {
    width: 192px;
    margin-left: 10px;
  }
}
</style>
