<template>
  <div>
    <ListFilter :form-list="formList" @reset="onReset" @filter="onSearch" />
    <ECTable
      class="qr-code-auth-table"
      rowKey="id"
      key="id"
      :showTitle="false"
      title=" "
      :loading="loading"
      :data-source="tableList"
      :columns="columns"
      :pagination="{ current, total, pageSize }"
      @change="onPageChange"
      :row-selection="{
        columnWidth: 50,
        selectedRowKeys: selectedList,
        onChange: params => onSelectChange(params),
        getCheckboxProps: record => {
          return {
            props: {
              disabled: record.archived
            }
          };
        }
      }"
    >
      <template slot-scope="text, record" slot="action">
        <div class="table-operations">
          <a-button
            type="link"
            class="table-btn"
            @click="onDownload(record)"
            :disabled="record.archived"
          >
            <a-icon type="download" />
            {{ $t("backCheck.qrCodeAuthList.downloadBook") }}
          </a-button>
          <a-button
            type="link"
            class="table-btn"
            @click="onAddOrder(record)"
            :disabled="record.archived"
          >
            <a-icon type="solution" />
            {{ $t("backCheck.order") }}
          </a-button>
        </div>
      </template>
      <!-- 电话 -->
      <template slot="phone" slot-scope="text, record">
        {{
          record.phoneDialingCode
            ? `+${record.phoneDialingCode} ${record.mobilePhoneNumber}`
            : record.mobilePhoneNumber
        }}
      </template>
      <div slot="footerCustom" class="qr-code-auth-table-footer">
        <span class="selected-total">
          {{
            $t("backCheck.qrCodeAuthList.selectedTotal", {
              count: this.selectedList.length
            })
          }}
        </span>
        <a-button type="primary" @click="onAddOrderBatch">
          {{ $t("showMsg.confirm.batchTitle", { msg: $t("backCheck.order") }) }}
        </a-button>
      </div>
    </ECTable>
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter.vue";
import ECTable from "@/components/ECTable";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import Vue from "vue";
import { candidateListPageData } from "@/pages/workspace/backgroundCheck/qrCodeAuth/api";
import { rangeDateFormatter } from "@/utils/tools";
import { singleFileDownload } from "@/pages/workspace/backgroundCheck/presentRecord/api";

const FilterForm = {
  candidateName: "",
  candidateId: "",
  candidateMobileNumber: "",
  authTime: ""
};

export default {
  name: "QrCodeAuthList",
  components: { ECTable, ListFilter },
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      tableList: [],
      loading: false,
      columns: [
        {
          dataIndex: "candidateName",
          title: this.$t("backCheck.cname"),
          align: "left",
          width: 120,
          ellipsis: true
        },
        {
          dataIndex: "candidateId",
          title: this.$t("backCheck.cidcard"),
          align: "left",
          width: 120,
          ellipsis: true
        },
        {
          dataIndex: "mobilePhoneNumber",
          title: this.$t("backCheck.hconcat"),
          align: "left",
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: "phone" }
        },
        {
          title: Vue.prototype.$t("backCheck.qrCodeAuthList.authTime"),
          dataIndex: "authTimeString",
          align: "center",
          width: 140,
          ellipsis: true
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "action" }
        }
      ],
      form: {
        ...FilterForm
      },
      selectedList: [],
      downloading: false
    };
  },
  computed: {
    formList() {
      return [
        {
          label: this.$t("backCheck.cname"),
          key: "candidateName",
          type: "input"
        },
        {
          label: this.$t("backCheck.cidcard"),
          key: "candidateId",
          type: "input"
        },
        {
          label: this.$t("backCheck.hconcat"),
          key: "candidateMobileNumber",
          type: "input"
        },
        {
          label: this.$t("backCheck.qrCodeAuthList.authTime"),
          key: "authTime",
          type: "timeRange"
        }
      ];
    }
  },
  methods: {
    onPageChange(page) {
      // 跨页，数据移除 不支持跨页多选
      this.onSelectChange([]);
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onReset() {
      this.form = { ...FilterForm };
    },
    onSearch(form) {
      this.form = form;
      this.current = 1;
      this.getList();
    },
    candidateFormatter(record) {
      return {
        name: record.candidateName,
        idNumbering: record.candidateId,
        phone: record.mobilePhoneNumber,
        key: undefined,
        authorizationRequired: false,
        isContact: false,
        phoneDialingCode: record.phoneDialingCode || "86",
        qrAuthFileId: record.id
      };
    },
    onAddOrder(record) {
      const candidateList = [this.candidateFormatter(record)];
      this.$store.commit("back/setCandidateList", candidateList);
      this.$router.push({
        name: "BackOrderAddTemplate",
        params: { type: "single" },
        query: { selected: "1" }
      });
    },
    async onDownload(record) {
      if (this.downloading) return;
      this.downloading = true;
      const res = await singleFileDownload({ fileNo: record.id });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.downloading = false;
        return;
      }
      this.downloading = false;
    },
    onAddOrderBatch() {
      if (!this.selectedList.length) {
        showMsg({
          flag: FETCH_CODE.ERROR.KEY,
          msg: this.$t("backCheck.qrCodeAuthList.unSelectedMsg")
        });
        return;
      }
      const selected = this.tableList.filter(it =>
        this.selectedList.includes(it.id)
      );
      const candidateList = selected.map(record =>
        this.candidateFormatter(record)
      );
      this.$store.commit("back/setCandidateList", candidateList);
      this.$router.push({
        name: "BackOrderAddTemplate",
        params: { type: "multi" },
        query: { batch: "1" }
      });
    },
    async getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        index: this.current,
        size: this.pageSize,
        candidateId: this.form.candidateId,
        candidateName: this.form.candidateName,
        mobilePhoneNumber: this.form.candidateMobileNumber,
        authTimeBegin: rangeDateFormatter(this.form.authTime).start,
        authTimeEnd: rangeDateFormatter(this.form.authTime).end
      };
      const res = await candidateListPageData(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      const tableList = res.data?.list || [];
      this.tableList = tableList.map((it, idx) => {
        it.key = it.id || idx + "";
        return it;
      });
      this.current = res.data.index;
      this.pageSize = res.data.size;
      this.total = res.data.total;
      this.loading = false;
    },
    onSelectChange(selectedList) {
      this.selectedList = selectedList;
    }
  },
  mounted() {
    this.getList();
  }
};
</script>

<style lang="less" scoped>
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;
    }
  }
}
.qr-code-auth-table {
}
</style>
