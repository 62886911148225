<template>
  <div class="card-report">
    <a-spin :spinning="loading">
      <CardHeader :title="title" :icon="'icon-bar'">
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="companyId"
          class="brief-select"
          @change="onCompanyChange"
          v-if="multipleCompany"
        >
          <a-select-option
            :value="i.companyId"
            v-for="i in companyList"
            :key="i.companyId"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
      </CardHeader>
      <Echarts
        ref="eChartsRef"
        :id="echarts.id"
        :height="echarts.height"
        :options="echarts.options"
      />
    </a-spin>
  </div>
</template>

<script>
import Echarts from "@/components/Echarts";
import CardHeader from "./CardHeader.vue";
import { FETCH_CODE } from "@/config";
import { reportCount } from "@/pages/workspace/backgroundCheck/index/api";
import BigNumber from "bignumber.js";
import { mapGetters } from "vuex";

export default {
  name: "CardReport",
  components: {
    Echarts,
    CardHeader,
  },
  data() {
    return {
      title: this.$t('index.jdbgsl'),
      companyId: null,
      echartData: [],
      loading: false,
    };
  },
  computed: {
    echarts() {
      return {
        id: "report",
        height: "300px",
        options: {
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              return `${params.marker}${params.name}&emsp;&emsp; ${params.value}, ${params.percent}%`;
            },
            borderColor: "transparent",
          },
          label: {
            formatter: "{b}: {d}%",
          },
          toolbox: {
            orient: "vertical",
            top: "center",
          },
          series: [
            {
              type: "funnel",
              left: 0,
              data: this.echartData,
            },
          ],
        },
      };
    },
    ...mapGetters("user", ["multipleCompany", "companyList"]),
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await reportCount({
        companyId: this.companyId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
        try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
        return;
      }
      const echartData = res.data || [];
      const total = echartData.reduce((a, b) => a + b.number, 0);
      this.echartData = echartData.map((it) => {
        return {
          value: it.number,
          name: it.fameWorkName,
          percent: total ? BigNumber(it.number || 0).div(total).toFixed(2) : "0.00",
        };
      });

      try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
      this.loading = false;
    },
    async getCompanyList() {
      this.companyId = this.companyList[0]?.companyId;
    },
    onCompanyChange(companyId) {
      this.companyId = companyId;
      this.getData();
    },
  },
  async mounted() {
    if (this.multipleCompany) {
      await this.getCompanyList();
    }
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.card-report {
  overflow: hidden;
}
.brief-select {
  width: 192px;
  margin-left: 10px;
}
@media screen and (max-width: 1300px) {
  .brief-select {
    width: 140px;
  }
}
</style>
