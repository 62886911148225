<template>
  <div>
    <a-modal
      :visible="isPackageModal"
      :title="title"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
      class="package-modal"
      :width="800"
    >
      <a-row :gutter="32" class="package">
        <a-col :span="12" v-for="i in modulesList" :key="i.id" class="package-item">
          <div class="package-item-title">{{ i.module }}</div>
          <div class="package-item-num">{{ i.segmentsCount }}段</div>
          <!-- <a-checkbox :value="i" class="left align font-small">
              {{ i }}
            </a-checkbox> -->
          <!-- <a-input-number
              id="inputNumber"
              :min="1"
              :max="10"
              class="right font-small width-small"
              :value="1"
            /> -->
        </a-col>
      </a-row>
      <!-- <div
        :style="{
          borderBottom: '1px solid #E9E9E9',
          paddingBottom: '10px',
          marginBottom: '10px',
        }"
      >
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
        >
          {{ $t("selectAll") }}
        </a-checkbox>
      </div> -->
      <!-- <a-checkbox-group
        @change="onChange"
        v-model="checkedList"
        class="checkbox"
      >
        <a-row :gutter="[32, 0]">
          <a-col :span="12" v-for="i in moduleList" :key="i" class="clear-fix">
            <a-checkbox :value="i" class="left align font-small">
              {{ i }}
            </a-checkbox>
            <a-input-number
              id="inputNumber"
              :min="1"
              :max="10"
              class="right font-small width-small"
              :value="1"
            />
          </a-col>
        </a-row>
      </a-checkbox-group> -->
      <a-alert
        type="warning"
        banner
        :message="$t('backCheck.alertTitle')"
        class="package-alert"
      >
        <template slot="description">
          <p>1.{{ $t("backCheck.alertDesc1") }}</p>
          <p>2.{{ $t("backCheck.alertDesc2") }}</p>
        </template>
      </a-alert>
    </a-modal>
  </div>
</template>
<script>
const plainOptions = ["Apple", "Pear", "Orange"];
const defaultCheckedList = ["Apple", "Orange"];
export default {
  name: "PackageModal",
  props: {
    isPackageModal: Boolean,
    title: {
      type: String,
      default: "请选择核验模块"
    },
    item: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    modulesList() {
      return this.item;
    }
  },
  data() {
    return {
      indeterminate: true,
      checkAll: false,
      checkedList: defaultCheckedList,
      plainOptions
    };
  },
  components: {},
  methods: {
    cancel() {
      this.$emit("update:isPackageModal", false);
    },
    confirm() {
      this.$emit('ok')
      this.cancel()
    },
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < plainOptions.length;
      this.checkAll = checkedList.length === plainOptions.length;
    },
    onCheckAllChange(e) {
      console.log(e.target.checked);
      Object.assign(this, {
        checkedList: e.target.checked ? plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      });
    }
  }
};
</script>
<style lang="less" scoped>
.package-modal {
  .package-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: @ec-gutter20;
    &-title {
      .single-hidden();
      margin-right: 30px;
    }
    &-num {
      background: #fff3e3;
      border-radius: 20px;
      border: 1px solid @warning-color;
      color: @warning-color;
      padding: 0 @ec-gutter;
      .no-wrap();
      align-self: flex-start;
    }
  }
  .package-alert {
    margin-top: 30px;
  }
  // .checkbox {
  //   width: 100%;
  //   margin-bottom: @ec-gutter * 2;
  // }
  // .align {
  //   line-height: 2.2;
  // }
  // .font-small {
  //   font-size: @font-size-small;
  // }
  // .width-small {
  //   width: 51px;
  // }
}
</style>
