import { render, staticRenderFns } from "./ECardButton.vue?vue&type=template&id=71708aad&scoped=true"
import script from "./ECardButton.vue?vue&type=script&lang=js"
export * from "./ECardButton.vue?vue&type=script&lang=js"
import style0 from "./ECardButton.vue?vue&type=style&index=0&id=71708aad&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71708aad",
  null
  
)

export default component.exports