<!-- 盾牌等级分布 -->
<template>
  <div class="card-shield">
    <a-spin :spinning="loading">
      <CardHeader :title="$t('index.dpdj')" :icon="'icon-circle'">
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="companyId"
          class="brief-select"
          @change="onCompanyChange"
          v-if="multipleCompany"
        >
          <a-select-option
            :value="i.companyId"
            v-for="i in companyList"
            :key="i.companyId"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
      </CardHeader>
      <Echarts
        ref="eChartsRef"
        :width="echarts.width"
        :height="echarts.height"
        :id="echarts.id"
        :options="echarts.options"
      />
    </a-spin>
  </div>
</template>

<script>
import Echarts from "@/components/Echarts";
import CardHeader from './CardHeader.vue'
import { getShield } from "@/pages/workspace/backgroundCheck/index/api";
import { FETCH_CODE } from "@/config";
import { mapGetters } from "vuex";

export default {
  name: "CardShield",
  components: {
    Echarts,
    CardHeader
  },
  data() {
    return {
      title: this.$t("index.dpdj"),
      companyId: null,
      echartData: [],
      loading: false,
    };
  },
  computed: {
    echarts() {
      return {
        height: "300px",
        id: "shield",
        options: {
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              return `${params.marker}${params.name}:${params.value}${this.$t("index.unit")}`;
            },
            borderColor: "transparent",
          },
          color: this.echartData.map((it) => it.color),
          legend: {
            orient: "vertical",
            right: "right",
            top: "center",
            formatter: (name) => {
              let value = 0,
                id = 0;
              this.echartData.map((e) => {
                if (e.name === name) {
                  value = e.value;
                  id = e.id;
                }
              });
              const arr = [
                "{a|" + `${name}` + "}",
                "{b|" + `${value}${this.$t("index.unit")}` + "}",
              ];
              return arr.join("");
            },
            textStyle: {
              width: 80,
              rich: {
                a: {
                  fontSize: 12,
                  align: "left",
                  color: "#656565",
                },
                b: {
                  fontSize: 12,
                  color: "#989898",
                  align: "right",
                },
              },
            },
          },
          label: {
            formatter: "{b}:{d}%",
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              avoidLabelOverlap: false,
              center: ["30%", "45%"],
              data: this.echartData,
            },
          ],
        },
      };
    },
    ...mapGetters("user", ["multipleCompany", "companyList"]),
  },
  methods: {
    async getData () {
      this.loading = true;
      const res = await getShield({
        companyId: this.companyId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
        try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
        return;
      }
      this.echartData = res.data;
      try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
      this.loading = false;
    },
    async getCompanyList() {
      this.companyId = this.companyList[0]?.companyId;
    },
    onCompanyChange(companyId) {
      this.companyId = companyId;
      this.getData();
    },
  },
  async mounted() {
    if (this.multipleCompany) {
      await this.getCompanyList();
    }
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.card-shield {
  padding: 20px;
  &-head {
    display: flex;
    align-items: center;
    .icon-circle {
      font-size: 32px;
      margin-right: 10px;
    }
    .head-title {
      flex: 1 0;
      font-size: 18px;
    }
  }
}
.shield-select {
  width: 120px;
}
.brief-select {
  width: 192px;
  margin-left: 10px;
}
</style>
