<template>
  <div class="grant-join-gift">
    <a-form-model
      ref="formRef"
      class="grant-join-gift-form"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <!--员工姓名-->
        <a-col :span="24">
          <a-form-model-item prop="staff" class="staff">
            <template slot="label">
              <span>{{ $t('welfarePlat.manualRelease.form.userIds') }}</span>
            </template>
            <a-select
              :getPopupContainer="trigger => trigger.parentNode"
              v-model="form.joinGift"
              :placeholder="$t('welfarePlat.manualRelease.form.userIdsPlaceholder')"
              allowClear
            >
              <a-select-option
                v-for="op in staffList"
                :key="op.value"
                :value="op.value"
              >
                {{ op.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-row class="authorization-required-tips">
            <a-col :offset="6" :span="18">
              <p>
                {{ $t("order.gift.selected") }}: 0
              </p>
            </a-col>
          </a-row>
        </a-col>
        <!--入职礼-->
        <a-col :span="24">
          <a-form-model-item prop="joinGift" class="join-gift">
            <template slot="label">
              <span>{{ $t('order.detail.joinGift') }}</span>
            </template>
            <a-select
              :getPopupContainer="trigger => trigger.parentNode"
              v-model="form.joinGift"
              :placeholder="$t('order.detail.joinGift')"
              allowClear
            >
              <a-select-option
                v-for="op in giftList"
                :key="op.value"
                :value="op.value"
              >
                {{ op.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-row class="authorization-required-tips">
            <a-col :offset="6" :span="18">
              <p>
                {{ $t("order.gift.number") }}: 8
              </p>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: "GrantGift",
  data() {
    return {
      form: {},
      rules: {},
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      staffList: [],
      giftList: [
        { label: "暂不发放", value: 0 },
        { label: "套餐A", value: 1 },
        { label: "套餐B", value: 2 }
      ],
    };
  },
}
</script>

<style lang="less" scoped></style>
