<template>
  <div class="background-order-form-wrapper">
    <!--已选择模板-->
    <div class="background-order-selected-template">
      <div class="selected-template-inner">
        <div class="selected-template-name">
          {{ $t("order.detail.selectedTemplate") }}: {{ selected.name }}
        </div>
        <a-button
          class="selected-template-btn"
          type="link"
          @click="templateModuleVisible = true"
        >
          {{ `${$t("button.view")}${$t("backCheck.template")}` }}
        </a-button>
      </div>
    </div>
    <!--候选人表单-->
    <a-form-model
      ref="formRef"
      class="background-order-form"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      v-if="[BACK_ORDER_TYPE[0].name, BACK_ORDER_TYPE[1].name].includes(type)"
    >
      <a-row>
        <!--授权方式-->
        <template v-if="selected.type === CREATE_TYPE.QUICK">
          <a-col :span="12" :offset="6">
            <a-form-model-item
              :label="$t('backCheck.authType')"
              prop="authorizationRequired"
              class="form-item-authorization-required"
            >
              <a-radio-group
                v-model="form.authorizationRequired"
                :options="baseParams.authorizationRequired"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12" :offset="6">
            <a-row class="authorization-required-tips">
              <a-col
                :offset="7"
                :span="24"
                v-if="form.authorizationRequired === true"
              >
                <p>
                  <a-icon type="exclamation-circle" theme="filled" />
                  {{ $t("backCheck.authOnlineTip1") }}
                </p>
                <p>
                  <a-icon type="exclamation-circle" theme="filled" />
                  {{ $t("backCheck.authOnlineTip2") }}
                </p>
              </a-col>
              <a-col
                :offset="7"
                :span="24"
                v-if="form.authorizationRequired === false"
              >
                <p>
                  <a-icon type="exclamation-circle" theme="filled" />
                  {{ $t("backCheck.authOfflineTip1") }}
                </p>
                <p>
                  <a-icon type="exclamation-circle" theme="filled" />
                  {{ $t("backCheck.authOfflineTip2") }}
                </p>
              </a-col>
            </a-row>
          </a-col>
        </template>
        <!--候选人姓名-->
        <a-col :span="12" :offset="6">
          <a-form-model-item :label="$t('order.detail.name')" prop="name">
            <a-input
              v-model="form.name"
              :placeholder="
                messageFormatter($t('input'), $t('order.detail.name'))
              "
            />
          </a-form-model-item>
        </a-col>
        <!--候选人手机号码-->
        <a-col :span="12" :offset="6">
          <a-form-model-item
            :label="$t('backCheck.hphone')"
            prop="phoneValue"
            :required="fieldsRequired.includes('phoneNumber')"
          >
            <EcMobile
              :value="form.phoneValue"
              @change="onMobileChange"
              :placeholder="
                messageFormatter($t('input'), $t('backCheck.hphone'))
              "
            />
          </a-form-model-item>
        </a-col>
        <!-- 候选人证件号码-->
        <a-col :span="12" :offset="6">
          <a-form-model-item
            :label="$t('echeck.table.idCard')"
            prop="idNumbering"
          >
            <a-input
              v-model="form.idNumbering"
              :placeholder="
                messageFormatter($t('input'), $t('echeck.table.idCard'))
              "
            />
          </a-form-model-item>
        </a-col>
        <!-- 候选人邮箱-->
        <a-col :span="12" :offset="6">
          <a-form-model-item :label="$t('order.detail.email')" prop="email">
            <a-input
              :placeholder="
                messageFormatter($t('input'), $t('order.detail.email'))
              "
              v-model="form.email"
            />
          </a-form-model-item>
        </a-col>
        <!--预计入职时间-->
        <a-col :span="12" :offset="6">
          <a-form-model-item
            :label="$t('order.detail.entry')"
            prop="expectedEntryTime"
          >
            <a-date-picker
              :allowClear="true"
              v-model="form.expectedEntryTime"
              class="width"
            />
          </a-form-model-item>
        </a-col>
        <!--默认隐藏表单-->
        <a-col :span="24" v-show="expand">
          <a-row>
            <!--候选人性别-->
            <a-col :span="12" :offset="6">
              <a-form-model-item
                :label="$t('order.detail.sex')"
                prop="genderId"
              >
                <a-select
                  :getPopupContainer="trigger => trigger.parentNode"
                  v-model="form.genderId"
                  :placeholder="$t('order.detail.sex')"
                  allowClear
                >
                  <a-select-option
                    v-for="op in baseParams.gender"
                    :key="op.value"
                    :value="op.value"
                  >
                    {{ op.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!--拟入职机构-->
            <a-col :span="12" :offset="6">
              <a-form-model-item
                :label="$t('order.detail.agency')"
                prop="intendedAgency"
              >
                <a-input
                  v-model="form.intendedAgency"
                  :placeholder="$t('order.detail.agency')"
                />
              </a-form-model-item>
            </a-col>
            <!--工作地点-->
            <a-col :span="12" :offset="6">
              <a-form-model-item
                :label="$t('order.detail.address')"
                prop="workPlace"
              >
                <a-input
                  :placeholder="$t('order.detail.address')"
                  v-model="form.workPlace"
                />
              </a-form-model-item>
            </a-col>
            <!--成本中心-->
            <a-col :span="12" :offset="6">
              <a-form-model-item
                :label="$t('order.detail.costCenter')"
                prop="costCenter"
              >
                <a-input
                  :placeholder="$t('order.detail.costCenter')"
                  v-model="form.costCenter"
                />
              </a-form-model-item>
            </a-col>
            <!--入职礼-->
            <a-col :span="12" :offset="6" style="display: none;">
              <a-form-model-item prop="joinGift" class="join-gift">
                <template slot="label">
                  <a-icon
                    type="exclamation-circle"
                    theme="filled"
                    @click="joinGiftVisible = true"
                  />
                  <span>{{ $t("order.detail.joinGift") }}</span>
                </template>
                <a-select
                  :getPopupContainer="trigger => trigger.parentNode"
                  v-model="form.joinGift"
                  :placeholder="$t('order.detail.joinGift')"
                  allowClear
                >
                  <a-select-option
                    v-for="op in baseParams.joinGift"
                    :key="op.value"
                    :value="op.value"
                  >
                    {{ op.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" :offset="6" style="display: none;">
              <a-row class="authorization-required-tips">
                <a-col :offset="7" :span="24">
                  <p>
                    <a-icon type="exclamation-circle" theme="filled" />
                    {{ $t("order.detail.joinGiftTips") }}
                  </p>
                </a-col>
              </a-row>
            </a-col>
            <!--调查问卷-->
            <a-col :span="12" :offset="6" style="display: none;">
              <a-form-model-item prop="survey" class="join-gift">
                <template slot="label">
                  <a-icon
                    type="exclamation-circle"
                    theme="filled"
                    @click="questionnaireVisible = true"
                  />
                  <span>{{ $t("survey.order.label") }}</span>
                </template>
                <a-radio-group
                  v-model="form.survey"
                  :options="baseParams.isContact"
                />
                <a-button type="link" @click="onQuestionnaireDemo" v-if="form.survey">
                  {{ $t("survey.order.link") }}
                </a-button>
              </a-form-model-item>
            </a-col>
            <!--上传附件-->
            <a-col :span="12" :offset="6">
              <a-form-model-item
                :label="$t('order.detail.upload')"
                prop="fileList"
              >
                <EcUpload
                  formName="file"
                  action="/api/sys/file/upload"
                  :fileList.sync="fileList"
                  :params="fileUploadParams"
                  @change="onFileChange"
                  :accept="FILE_MIME_TYPES"
                  :limit="1024 * 1024 * 50"
                  :toast="false"
                  :customRequest="onFileUpload"
                />
                <template slot="extra">
                  <template v-for="fileIt in fileList">
                    <a-alert
                      style="margin-bottom: 5px;"
                      :key="fileIt.uid"
                      :message="fileErrorTitleFormatter(fileIt)"
                      :description="fileErrorMsgFormatter(fileIt)"
                      type="error"
                      show-icon
                      closable
                      v-if="fileIt.status === 'error'"
                      @close="onFileErrorClose(fileIt)"
                    />
                  </template>
                </template>
              </a-form-model-item>
            </a-col>
            <!--是否联系候选人-->
            <a-col :span="12" :offset="6">
              <a-form-model-item
                :label="$t('order.detail.concat')"
                prop="isContact"
              >
                <a-radio-group
                  v-model="form.isContact"
                  :options="baseParams.isContact"
                />
              </a-form-model-item>
            </a-col>
            <!--是否加急-->
            <a-col
              :span="12"
              :offset="6"
              v-if="selected.type === CREATE_TYPE.TRADITION"
            >
              <a-form-model-item
                :label="$t('order.detail.urgent')"
                prop="urgentState"
              >
                <a-radio-group
                  v-model="form.urgentState"
                  :options="baseParams.urgentState"
                />
              </a-form-model-item>
            </a-col>
            <!--候选人招聘来源-->
            <a-col :span="12" :offset="6">
              <InviteSource ref="sourceRef" />
            </a-col>
            <!--备注-->
            <a-col :span="12" :offset="6">
              <a-form-model-item :label="$t('order.detail.note')" prop="remark">
                <a-textarea
                  :placeholder="$t('order.detail.note')"
                  :auto-size="{ minRows: 9, maxRows: 9 }"
                  v-model="form.remark"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-col>
        <!--展开更多-->
        <a-col :span="24">
          <div class="expand-more" :class="expand ? 'expanded' : ''">
            <div class="expand-more-content" @click="onExpand">
              <span class="expand-more-text">
                {{
                  expand
                    ? $t("order.detail.shrinkMore")
                    : $t("order.detail.expandMore")
                }}
              </span>
              <a-icon
                class="expand-more-icon"
                :type="expand ? 'up-circle' : 'down-circle'"
                theme="filled"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form-model>
    <!--批量上传候选人-->
    <div
      class="background-order-form-batch"
      v-if="[BACK_ORDER_TYPE[2].name].includes(type)"
    >
      <div class="batch-form">
        <div class="batch-authorization-required">
          <a-form-model
            ref="formBatchRef"
            class="background-order-form-batch"
            :model="batchForm"
            :label-col="batchLabelCol"
            :wrapper-col="batchWrapperCol"
          >
            <a-row>
              <!--授权方式-->
              <template v-if="selected.type === CREATE_TYPE.QUICK">
                <a-col :span="24">
                  <a-form-model-item
                    :label="$t('backCheck.authType')"
                    prop="authorizationRequired"
                    class="form-item-authorization-required"
                  >
                    <a-radio-group
                      v-model="batchForm.authorizationRequired"
                      :options="baseParams.authorizationRequired"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-row class="authorization-required-tips batch">
                    <a-col
                      :offset="3"
                      :span="24"
                      v-if="batchForm.authorizationRequired === true"
                    >
                      <p>
                        <a-icon type="exclamation-circle" theme="filled" />
                        {{ $t("backCheck.authOnlineTip1") }}
                      </p>
                      <p>
                        <a-icon type="exclamation-circle" theme="filled" />
                        {{ $t("backCheck.authOnlineTip2") }}
                      </p>
                    </a-col>
                    <a-col
                      :offset="3"
                      :span="24"
                      v-if="batchForm.authorizationRequired === false"
                    >
                      <p>
                        <a-icon type="exclamation-circle" theme="filled" />
                        {{ $t("backCheck.authOfflineTip1") }}
                      </p>
                      <p>
                        <a-icon type="exclamation-circle" theme="filled" />
                        {{ $t("backCheck.authOfflineTip2") }}
                      </p>
                    </a-col>
                  </a-row>
                </a-col>
              </template>
            </a-row>
          </a-form-model>
        </div>
        <div class="batch-download">
          请先下载模板，填写后上传即可
          <a
            href="/template/新建委托模板0721.xlsx"
            :download="$t('backCheck.templateWorkOrder')"
            class="batch-download-btn"
          >
            {{ $t("order.detail.downloadBatchTemplate") }}
          </a>
        </div>
        <div class="batch-download-tips">
          {{ $t("order.detail.downloadBatchTemplateTips") }}
        </div>
        <EcUpload
          class="batch-upload"
          formName="file"
          action="/api/rc/order/data/candidate/import"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :fileList.sync="batchList"
          :params="batchUploadParams"
          :tips="$t('order.detail.downloadBatchTemplateTitle')"
          :showUploadList="false"
          @change="onBatchUploadChange"
          :limit="1024 * 1024 * 10"
        >
          <template slot="icon">
            <img
              class="batch-icon"
              src="@/assets/images/order/office-excel.svg"
              alt=""
            />
          </template>
          <template slot="hint">
            <div class="batch-upload-tips">
              <div class="tips-top">
                {{ $t("order.detail.downloadBatchTemplateTips2") }}
              </div>
              <div class="tips-mid">
                {{ $t("order.detail.downloadBatchTemplateTips3") }}
                {{ fieldsRequiredName.join("、") }}
              </div>
              <div class="tips-bot">
                {{ $t("order.detail.downloadBatchTemplateTips4") }}
              </div>
            </div>
          </template>
        </EcUpload>
      </div>
      <div class="batch-clear">
        <a-button class="batch-clear-btn" type="primary" @click="onRemoveBatch">
          {{ $t("order.detail.batchClear") }}
        </a-button>
      </div>
      <div class="batch-tips">
        <div class="batch-tips-title">
          <a-icon type="exclamation-circle" theme="filled" />
          <span>{{ $t("order.detail.batchDesc") }}</span>
        </div>
        <p>
          {{ $t("order.detail.batchDescP1") }}
        </p>
        <p>
          {{ $t("order.detail.batchDescP2") }}
        </p>
        <p>
          {{ $t("order.detail.batchDescP3") }}
        </p>
      </div>
    </div>
    <!--多位候选人保存-->
    <div
      class="background-order-form-save"
      v-if="[BACK_ORDER_TYPE[1].name].includes(type)"
    >
      <a-button
        class="background-order-form-btn"
        type="primary"
        @click="onSave(true)"
      >
        {{ $t("button.save") }}
      </a-button>
    </div>
    <!--多位候选人表格-->
    <a-table
      :columns="columns"
      :data-source="dataList"
      bordered
      class="background-order-form-table"
      :pagination="false"
      :scroll="{ y: 400, x: 1240 }"
      v-if="[BACK_ORDER_TYPE[1].name, BACK_ORDER_TYPE[2].name].includes(type)"
    >
      <template slot="title">
        {{ $t("backCheck.hc") }}
      </template>
      <template slot="phone" slot-scope="text, record">
        <span v-if="record.phoneDialingCode">
          +{{ record.phoneDialingCode }}&nbsp;
        </span>
        <span v-if="record.phone">
          {{ record.phone }}
        </span>
      </template>
      <template slot="authorizationRequired" slot-scope="text, record">
        {{
          showBaseParamName(
            record.authorizationRequired,
            baseParams.authorizationRequired
          )
        }}
      </template>
      <template slot="isContact" slot-scope="text, record">
        {{ showBaseParamName(record.isContact, baseParams.isContact) }}
      </template>
      <template slot="urgentState" slot-scope="text, record">
        {{ showBaseParamName(record.urgentState, baseParams.urgentState) }}
      </template>
      <template slot="action" slot-scope="text, record, index">
        <div class="table-operations">
          <a-button
            type="link"
            class="table-btn"
            @click="onEdit(record, index)"
          >
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record, index)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <!--按钮-->
    <div class="background-order-foot">
      <a-button class="foot-btn" @click="onPrev">
        {{ $t("button.previous") }}
      </a-button>
      <a-button class="foot-btn" type="primary" @click="onNext">
        {{ $t("button.next") }}
      </a-button>
    </div>
    <!--入职礼说明弹窗-->
    <GiftDescription :visible.sync="joinGiftVisible" />
    <!--问卷调查说明弹窗-->
    <SurveyDescription :visible.sync="questionnaireVisible" />
    <!--已选择模板弹窗-->
    <TemplateModuleModal :visible.sync="templateModuleVisible" />
    <!--候选人上传附件失败后 弹出的帮助窗口-->
    <UploadHelper
      :visible.sync="uploadHelperVisible"
      :option="uploadHelperData"
      :type="type"
      :authorizationRequired="authorizationRequired"
      @review="onUploadCheckCandidate"
    />
  </div>
</template>

<script>
import api from "@/utils/fetch";
import { camelCase, cloneDeep, trim } from "lodash";
import EcMobile from "@/components/EcMobile.vue";
import {
  DateFormat,
  FETCH_CODE,
  REG_EMAIL,
  REG_IDCARD,
  REG_MOBILE,
  RES_CODE
} from "@/config";
import { mapState } from "vuex";
import { createOrder } from "@/pages/home/order/api";
import { showMsg } from "@/utils";
import { AuthorizationRequired } from "@/pages/home/config";
import EcUpload from "@/components/EcUpload.vue";
import InviteSource from "@/pages/home/components/InviteSource.vue";
import moment from "moment/moment";
import { confirmModal, file2Base64, getFakeId } from "@/utils/tools";
import {
  BACK_ORDER_PROGRESS,
  BACK_ORDER_TYPE,
  FILE_MIME_TYPES,
  FILE_TYPES,
  URGENT_STATES,
  YES_OR_NO,
  CANDIDATE_INFO_WRONG_TYPE
} from "@/pages/workspace/backgroundCheck/order/config";
import { CREATE_TYPE } from "@/pages/home/order/config";
import GiftDescription from "@/pages/workspace/backgroundCheck/gift/Description.vue";
import TemplateModuleModal from "@/pages/workspace/backgroundCheck/order/components/ModuleModal.vue";
import UploadHelper from "@/pages/workspace/backgroundCheck/order/components/UploadHelper.vue";
import { langMixin } from "@/mixins";
import SurveyDescription from "@/pages/workspace/survey/components/Description.vue";

const formEmpty = {
  // 候选人姓名
  name: "",
  // 候选人手机号码
  phoneValue: {
    mobile: "",
    code: "86"
  },
  phone: "",
  phoneDialingCode: "",
  // 候选人证件号码
  idNumbering: "",
  // 候选人邮箱
  email: "",
  // 预计入职时间
  expectedEntryTime: null,
  // 候选人性别
  genderId: undefined,
  // 拟入职机构
  intendedAgency: "",
  // 工作地点
  workPlace: "",
  // 成本中心
  costCenter: "",
  // 入职礼
  joinGift: undefined,
  // 调查问卷
  survey: false,
  // 授权方式
  authorizationRequired: false,
  // 是否联系候选人
  isContact: true,
  // 是否加急
  urgentState: null,
  // 备注
  remark: "",
  // 报告语言
  reportLanguageId: undefined,
  // 邮件语言
  emailLanguageId: undefined,
  // 报告跟进
  progressFollowUp: null
};

const batchFormEmpty = {
  // 授权方式
  authorizationRequired: false
};

export default {
  name: "BackOrderAddForm",
  components: {
    TemplateModuleModal,
    GiftDescription,
    InviteSource,
    EcUpload,
    EcMobile,
    UploadHelper,
    SurveyDescription
  },
  props: {
    type: String
  },
  mixins: [langMixin],
  data() {
    return {
      form: cloneDeep(formEmpty),
      baseParams: {
        // 邮件语言
        emailLanguages: [],
        // 性别
        gender: [],
        // 中药程度
        importance: [],
        // 是否联系候选人
        isContact: [],
        // 报告跟进
        progressFollowUp: [],
        // 报告语言
        reportLanguages: [],
        // 紧急程度
        urgentState: [],
        // 授权方式
        authorizationRequired: [],
        // 入职礼
        joinGift: []
      },
      labelCol: { xxl: 6, span: 7 },
      wrapperCol: { xxl: 18, span: 17 },
      expand: false,
      dataList: [],
      fileList: [],
      batchList: [],
      editIndex: -1,
      BACK_ORDER_TYPE,
      FILE_MIME_TYPES,
      joinGiftVisible: false,
      questionnaireVisible: false,
      loading: false,
      templateModuleVisible: false,
      batchForm: cloneDeep(batchFormEmpty),
      batchLabelCol: { span: 3 },
      batchWrapperCol: { span: 21 },
      checkCandidateVisible: false,
      uploadHelperVisible: false,
      // 上传助手是否打开过
      uploadHelperOpened: false,
      uploadHelperData: {
        name: "",
        msg: "",
        tid: ""
      }
    };
  },
  computed: {
    authorizationRequired() {
      if ([BACK_ORDER_TYPE[2].name].includes(this.type)) {
        return this.batchForm.authorizationRequired;
      }
      return this.form.authorizationRequired;
    },
    CREATE_TYPE() {
      return CREATE_TYPE;
    },
    ...mapState({
      selected: state => state.back.selectedTemplate,
      userInfo: state => state.user.info,
      serverTime: state => state.back.serverTime,
      defaultExpectedEntryTimeAfterDays: state => state.back.defaultExpectedEntryTimeAfterDays,
      formConfig: state => state.back.formConfig
    }),
    rules() {
      // 手机号+区号验证
      const validatePhone = (rule, value, callback) => {
        if (this.fieldsRequired.includes("phoneNumber")) {
          if (!value.code) {
            callback(this.$t("select") + this.$t("order.detail.phoneCode"));
            return;
          }
          if (!trim(value.mobile)) {
            callback(
              this.messageFormatter(
                this.$t("input"),
                this.$t("order.detail.phone")
              )
            );
            return;
          }
        }
        // 仅验证中国大陆手机号
        if (
          value.code === "86" &&
          trim(value.mobile) &&
          !REG_MOBILE.test(trim(value.mobile))
        ) {
          callback(
            this.messageFormatter(
              this.$t("input"),
              this.$t("right"),
              this.$t("order.detail.phone")
            )
          );
          return;
        }
        callback();
      };

      return {
        // 候选人姓名
        name: [
          {
            required: this.fieldsRequired.includes("name"),
            message: this.messageFormatter(
              this.$t("input"),
              this.$t("order.detail.name")
            )
          },
          {
            max: 50,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.name"),
              length: 50
            })
          }
        ],
        // 候选人手机号码
        phoneValue: [{ validator: validatePhone }],
        // 候选人证件号码
        idNumbering: [
          {
            required: this.fieldsRequired.includes("idNumber"),
            pattern: REG_IDCARD,
            message: this.messageFormatter(
              this.$t("input"),
              this.$t("right"),
              this.$t("backCheck.report.idCard")
            )
          }
        ],
        // 候选人邮箱
        email: [
          {
            required: this.fieldsRequired.includes("email"),
            pattern: REG_EMAIL,
            message: this.messageFormatter(
              this.$t("input"),
              this.$t("right"),
              this.$t("order.detail.email")
            )
          },
          {
            max: 100,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.email"),
              length: 100
            })
          }
        ],
        // 拟入职机构
        intendedAgency: [
          {
            max: 100,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.agency"),
              length: 100
            })
          }
        ],
        // 工作地点
        workPlace: [
          {
            max: 100,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.address"),
              length: 100
            })
          }
        ],
        // 备注
        remark: [
          {
            max: 255,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.note"),
              length: 255
            })
          }
        ],
        // 成本中心
        costCenter: [
          {
            max: 255,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.costCenter"),
              length: 255
            })
          }
        ]
      };
    },
    columns() {
      const colArr = [
        // 0 姓名
        {
          title: this.$t("order.detail.name"),
          dataIndex: "name",
          key: "name",
          width: 110,
          align: "left"
        },
        // 1 身份证
        {
          title: this.$t("echeck.table.idCard"),
          dataIndex: "idNumbering",
          key: "idNumbering",
          width: 180,
          align: "left"
        },
        // 2 电话
        {
          title: this.$t("backCheck.hphone"),
          dataIndex: "phone",
          key: "phone",
          width: 160,
          align: "left",
          scopedSlots: { customRender: "phone" }
        },
        // 3 邮箱
        {
          title: this.$t("order.detail.email"),
          dataIndex: "email",
          key: "email",
          width: 210,
          align: "left"
        },
        // 4 入职机构
        {
          title: this.$t("order.detail.agency"),
          dataIndex: "intendedAgency",
          key: "intendedAgency",
          width: 140,
          align: "left"
        },
        // 5 工作地点
        {
          title: this.$t("order.detail.address"),
          dataIndex: "workPlace",
          key: "workPlace",
          width: 140,
          align: "left"
        },
        // 6 成本中心
        {
          title: this.$t("order.detail.costCenter"),
          dataIndex: "costCenter",
          key: "costCenter",
          width: 140,
          align: "left"
        },
        // 7 备注
        {
          title: this.$t("order.detail.note"),
          dataIndex: "remark",
          key: "remark",
          width: 140,
          align: "left"
        },
        // 8 授权方式
        {
          title: this.$t("backCheck.authType"),
          dataIndex: "authorizationRequired",
          key: "authorizationRequired",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "authorizationRequired" }
        },
        // 9 联系候选人
        {
          title: this.$t("order.detail.isCanConcat"),
          dataIndex: "isContact",
          key: "isContact",
          width: 110,
          align: "center",
          scopedSlots: { customRender: "isContact" }
        },
        // 10 加急
        {
          title: this.$t("order.detail.urgent"),
          dataIndex: "urgentState",
          key: "urgentState",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "urgentState" }
        },
        // 11 操作
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          width: 160,
          align: "center",
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ];
      // 批量上传候选人不显示操作列
      if (this.type === BACK_ORDER_TYPE[2].name) {
        colArr.splice(11, 1);
      }
      // 工作单不显示授权方式
      if (this.selected.type !== CREATE_TYPE.QUICK) {
        colArr.splice(8, 1);
      }
      return colArr;
    },
    // 必填字段动态判断
    fieldsRequired() {
      // 字段对应关系：
      // name=候选人姓名
      // phoneNumber=候选人电话号码
      // idNumber=候选人证件号码
      // email=候选人邮箱
      const fields = [];
      const { module = [], type, moduleIds = [] } = this.selected;
      // 1. 工作单 【姓名】【电话】必填
      if (type === CREATE_TYPE.TRADITION) {
        fields.push("name");
        fields.push("phoneNumber");
        //fields.push("email");
        return fields;
      }
      // 2. 易查单 根据授权方式和所选模块判断必填字段
      // 2.1 线上授权-【电话】【邮箱】始终必填 其它根据所选模块返回的必填状态判断
      if ([BACK_ORDER_TYPE[2].name].includes(this.type)) {
        if (this.batchForm.authorizationRequired) {
          fields.push("phoneNumber");
          // fields.push("email");
        }
      } else {
        if (this.form.authorizationRequired) {
          fields.push("phoneNumber");
          // fields.push("email");
        }
      }
      // 2.2 线下授权-根据所选模块返回的必填状态判断
      const moduleList = module.filter(it => moduleIds.includes(it.id));
      for (const module of moduleList) {
        const { candidateDataRequirement = [] } = module;
        candidateDataRequirement.forEach(re => {
          if (!fields.includes(camelCase(re.code))) {
            fields.push(camelCase(re.code));
          }
        });
      }
      return fields;
    },
    fieldsRequiredName() {
      const list = [
        { code: "name", name: "候选人姓名" },
        { code: "phoneNumber", name: "候选人电话号码" },
        { code: "idNumber", name: "候选人证件号码" },
        { code: "email", name: "候选人邮箱" }
      ];
      return list
        .filter(it => this.fieldsRequired.includes(it.code))
        .map(it => it.name);
    },
    // 批量上传入参
    batchUploadParams() {
      const { type, moduleIds = [] } = this.selected;
      const params = {};
      if (type === CREATE_TYPE.QUICK) {
        params.onlineAuth = this.batchForm.authorizationRequired;
        params.packages = moduleIds.join(",");
      }
      return params;
    },
    // 文件上传参数
    fileUploadParams() {
      const params = {
        userId: this.userInfo.userId,
        candidateEmail: this.form.email,
        candidateName: this.form.name,
        candidatePhoneNumber: this.form.phone,
        candidateId: this.form.idNumbering,
        echeckModuleNoList: "",
        templateName: "",
        templateNo: ""
      };
      // 易查宝
      if (this.selected.type === CREATE_TYPE.QUICK) {
        params.echeckModuleNoList = this.selected.moduleIds.join(",");
      }
      // 工作单
      else {
        params.templateName = this.selected.name;
        params.templateNo = this.selected.id;
      }
      return params;
    }
  },
  methods: {
    showBaseParamName(val, ls) {
      return ls.find(it => it.value === val)?.label ?? "";
    },
    onMobileChange(values) {
      this.form.phoneValue = { ...values };
    },
    onExpand() {
      this.expand = !this.expand;
    },
    async getBaseParams() {
      this.baseParams = {
        gender: cloneDeep(this.$store.state.back.genderList),
        isContact: this.listLabelFormatter(YES_OR_NO),
        urgentState: this.listLabelFormatter(URGENT_STATES),
        progressFollowUp: [],
        authorizationRequired: cloneDeep(AuthorizationRequired),
        // FIXME 假编码
        joinGift: [
          { label: "暂不发放", value: 0 },
          { label: "套餐A", value: 1 },
          { label: "套餐B", value: 2 }
        ],
        // 无用字段
        reportLanguages: [],
        emailLanguages: [],
      };
    },
    onSave(isClear) {
      return new Promise(resolve => {
        // 招聘来源
        const source = this.$refs.sourceRef.getValue();
        if (!source) {
          resolve("请选择招聘来源");
          return;
        }
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            resolve(Object.values(errors)[0]?.[0]?.message);
            return;
          }
          const record = {
            ...this.form,
            urgentStateName:
              this.baseParams.urgentState.find(
                it => it.value === this.form.urgentState
              )?.label ?? "",
            reportLanguageStr:
              this.baseParams.reportLanguages.find(
                it => it.key === this.form.reportLanguageId
              )?.value ?? "",
            emailLanguageStr:
              this.baseParams.emailLanguages.find(
                it => it.key === this.form.emailLanguageId
              )?.value ?? "",
            expectedEntryTime: this.form.expectedEntryTime
              ? moment(this.form.expectedEntryTime).format(DateFormat)
              : "",
            recruitSource: source.value,
            recruitSourceName: source.label,
            phone: this.form.phoneValue.mobile,
            phoneDialingCode: this.form.phoneValue.code,
            phoneValue: undefined
          };
          const uploadList = [];
          this.fileList
            .filter(file => {
              return file.response && file.response.data;
            })
            .forEach(file => {
              uploadList.push({
                key: file.name,
                value: file.response.data,
                description: file.type
              });
            });
          record.fileList = uploadList;
          if (!record.key) {
            record.key = getFakeId();
            // 如果是添加单个候选人 始终覆盖赋值
            if (this.type === BACK_ORDER_TYPE[0].name) {
              this.dataList = [record];
            } else {
              this.dataList.push(record);
            }
          } else {
            this.dataList[this.editIndex] = record;
            this.editIndex = -1;
          }
          // 清空表单
          if (isClear) {
            this.form = { ...this.getDefaultFormData() };
            this.fileList = [];
          }
          resolve(true);
        });
      });
    },
    onEdit(record, index) {
      this.form = {
        ...cloneDeep(record),
        phoneValue: {
          mobile: record.phone,
          code: record.phoneDialingCode
        }
      };
      this.editIndex = index;
      if (record.recruitSource) {
        this.$refs.sourceRef.setValue(record.recruitSource);
      }
      if (record.fileList) {
        this.fileList = record.fileList.map((it, idx) => ({
          uid: `edit-${idx}`,
          response: {
            code: RES_CODE.SUCCESS.KEY,
            data: it.value,
            success: true
          },
          name: it.key,
          type: it.description,
          status: "done"
        }));
      }
    },
    onDelete(record, index) {
      this.dataList.splice(index, 1);
      if (index === this.editIndex) {
        this.editIndex = -1;
        this.form = { ...this.getDefaultFormData() };
        this.fileList = [];
      }
    },
    async onFileUpload(option) {
      const {
        file,
        filename,
        action,
        data,
        onError,
        onProgress,
        onSuccess
      } = option;
      // 请求上传接口
      let res = await api.upload(
        action.replace(/^\/api/, ""),
        {
          ...data,
          [filename]: file
        },
        { defaultData: {} }
      );
      // 上传成功
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        onSuccess(res.data, res.xhr);
        return;
      }
      // 上传失败
      // 尝试第二次上传 - 通过base46
      const base64Res = await file2Base64(file);
      // 转码成功才尝试二次上传 转码失败直接进入错误处理
      if (base64Res.flag === 0) {
        const secondParams = {
          ...this.fileUploadParams,
          name: file.name,
          fileBase64: base64Res.data.encode
        };
        res = await api.upload("/sys/file/upload/base64", secondParams, {
          defaultData: {}
        });
        // 第二次上传成功
        if (res.flag === FETCH_CODE.SUCCESS.KEY) {
          onSuccess(res.data, res.xhr);
          return;
        }
      }
      // 最终上传失败-触发上传失败
      const error = {
        method: "POST",
        status: res.xhr.status,
        url: action,
        message: res.msg
      };
      onError(error, res.data);
      //#region 上传助手
      // 当前页面已经打开过一次 不再打开
      if (this.uploadHelperOpened) return;
      // 后端已知错误不弹出
      if (res.xhr.status >= 500 && res.xhr.status < 600) {
        if ([1007, 1008, 1009].includes(res.data.code)) return;
      }
      // 其它错误询问弹出
      this.$confirm({
        title: this.$t("order.detail.uploadHelper.confirm"),
        content: h => {
          return (
            <div>
              <p>{this.$t("order.detail.uploadHelper.confirmTips")}</p>
              <a-alert
                className="helper-alert"
                message={this.$t("order.detail.uploadFailed")}
                description={this.fileErrorMsgFormatter({
                  name: file.name,
                  error,
                  response: res.data
                })}
                type="error"
                show-icon
                closable={false}
              />
            </div>
          );
        },
        cancelText: this.$t("order.detail.uploadHelper.confirmCancel"),
        okText: this.$t("order.detail.uploadHelper.confirmOk"),
        onOk: () => {
          let otherMsg = `${res.data.msg || res.msg}`;
          if (res.xhr.status) {
            otherMsg += `, status: ${res.xhr.status}`;
          }
          if (res.data.code) {
            otherMsg += `, code: ${res.data.code}`;
          }
          this.uploadHelperData = {
            name: file.name,
            msg: otherMsg,
            tid: res.data.tid
          };
          this.uploadHelperOpened = true;
          this.uploadHelperVisible = true;
        },
        onCancel: () => {
          this.uploadHelperOpened = true;
        }
      });
    },
    onFileChange(fileList) {},
    onFileErrorClose(file) {
      const fileList = this.fileList.slice();
      for (let i = fileList.length - 1; i >= 0; i--) {
        if (fileList[i].uid === file.uid) {
          fileList.splice(i, 1);
        }
      }
      this.fileList = fileList;
    },
    onReset() {
      this.form = { ...this.getDefaultFormData() };
      this.fileList = [];
      this.dataList = [];
    },
    onValid() {
      if (!this.dataList.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("order.detail.empty")
        });
        return false;
      }
      if (this.editIndex > -1) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("echeck.detail.unsavedCandidate")
        });
        return false;
      }
      return this.dataList;
    },
    onBatchUploadChange(fileList) {
      const file = fileList[fileList.length - 1];
      if (!file) return;
      const { response } = file;
      if (!response) return;
      const { data } = response;

      if(!data || data.length<1) {
        this.$notification['warning']({
          message: this.$t("echeck.detail.candidateInfoImportResultTitle"),
          description: this.$t("echeck.detail.candidateInfoImportEmptyMessage"),
        });

        return false;
      }

      //清空列表
      this.dataList = [];

      //检查错误信息
      const wrongCandidateMessage = [];
      const wrongCandidaties = data.filter((item) => item.wrongType.length > 0);
      const htmlCreateElement = this.$createElement;

      for (const wrongCandidate of wrongCandidaties) {
        const wrongTypeArray = [];

        for (const wrongCandidateType of wrongCandidate.wrongType) {
          let typeFinded = CANDIDATE_INFO_WRONG_TYPE.find((item) => item.type === wrongCandidateType);

          if(typeFinded) {
            wrongTypeArray.push(this.isEnglish ? typeFinded.descEn : typeFinded.desc);
          }
        }

        wrongCandidateMessage.push(htmlCreateElement('div', this.$t("echeck.detail.candidateInfoImportFailedType").replace("{line}", wrongCandidate.index).replace("{name}", wrongCandidate.name).replace("{msg}", wrongTypeArray.join(", "))));
      }

      if(wrongCandidateMessage.length>0) {
        this.$warning({
          width: 670,
          title: this.$t("echeck.detail.candidateInfoImportFailedTitle"),
          content: htmlCreateElement('div', {}, wrongCandidateMessage),
        });
        return false;
      }

      const list = this.dataList.slice();
      for (const item of data) {
        const obj = {
          ...item,
          isContact: item.allowContact,
          expectedEntryTime: item.estimateEntryDate,
          intendedAgency: item.estimateEntryDepartment,
          genderId: item.gender,
          idNumbering: item.identityCardNo,
          phone: item.phoneNumber,
          phoneDialingCode: item.phoneNumberPrefix,
          workPlace: item.workplace,
          urgentState: item.expeditedService
        };
        // 同身份证号更新信息
        const idx = list.findIndex(it => it.idNumbering === obj.idNumbering);
        if (idx > -1 && obj.idNumbering) {
          list[idx] = {
            ...list[idx],
            ...obj
          };
        } else {
          list.push({
            key: getFakeId(),
            ...obj
          });
        }
      }
      this.dataList = list;

      if (list.length > 0) {
        this.$notification['success']({
          message: this.$t("echeck.detail.candidateInfoImportResultTitle"),
          description: this.$t("echeck.detail.candidateInfoImportSuccessMessage").replace("{count}", list.length),
        });
      } else {
        this.$notification['warning']({
          message: this.$t("echeck.detail.candidateInfoImportResultTitle"),
          description: this.$t("echeck.detail.candidateInfoImportEmptyMessage"),
        });
      }
    },
    onBeforeBatchUpload(file) {
      if (file.size > 1024 * 1024 * 10) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("homeModal.import.step1.limitSize", { size: "10M" })
        });
        return Promise.reject();
      }
      return Promise.resolve(file);
    },
    onRemoveBatch() {
      confirmModal(
        this.$t("order.detail.batchClear"),
        this.$t("order.detail.batchClearConfirm"),
        () => {
          this.dataList = [];
          this.batchList = [];
        },
        () => {}
      );
    },
    onPrev() {
      // 3. 信息完善 -> 2. 模板选择
      this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[1].key);
      this.$router.replace({
        name: "BackOrderAddTemplate",
        params: {
          type: this.type
        },
        query: this.$route.query
      });
    },
    async onNext() {
      if (this.loading) return;
      this.loading = true;
      // 添加单个候选人 没有表格 验证表单并追加进列表中
      if (this.type === BACK_ORDER_TYPE[0].name) {
        const res = await this.onSave();
        if (res !== true) {
          showMsg({ flag: FETCH_CODE.WARNING.KEY, msg: res });
          this.loading = false;
          return;
        }
      }
      // 添加多个候选人
      // 批量上传候选人
      const res = this.onValid();
      if (!res) {
        this.loading = false;
        return;
      }
      const params = {
        candidates: res.map(it => {
          const obj = {
            ...it,
            // 是否允许联系候选人，默认是
            allowContact: it.isContact,
            // 预计入职日期，保持格式为 yyyy-MM-dd
            estimateEntryDate: it.expectedEntryTime,
            // 拟入职机构
            estimateEntryDepartment: it.intendedAgency,
            // 是否加急，默认否
            expeditedService: it.urgentState,
            // 性别
            gender: it.genderId,
            // 证件号（易查宝订单时必填）
            identityCardNo: it.idNumbering,
            // 手机号
            phoneNumber: it.phone,
            // 手机号码前缀（国际区号），默认86
            phoneNumberPrefix: it.phoneDialingCode,
            // 工作地点
            workplace: it.workPlace,
            // 附件列表
            files: []
          };
          if (it.fileList) {
            obj.files = it.fileList.map(_it => {
              return {
                id: _it.value,
                name: _it.key
              };
            });
          }
          // 传统背调默认是线上授权
          if (this.selected.type === CREATE_TYPE.TRADITION) {
            obj.authorizationRequired = true;
          }
          return obj;
        }),
        reportEmailCc: this.$store.state.back.ccEmailSelectedList || []
      };
      // 易查宝 传入packages参数
      if (this.selected.type === CREATE_TYPE.QUICK) {
        params.packages = this.selected.moduleIds;
      }
      // 传统被调 传入模板id和段数
      if (this.selected.type === CREATE_TYPE.TRADITION) {
        params.template = {
          id: this.selected.id,
          module: this.selected.module?.filter(x => x.sectionable === true)?.map(item => {
            const obj = {
              id: item.moduleId,
              segments: item.number
            };

            return obj;
          })
        };
      }

      const urlParams = {};

      //选择其它企业下单时带上企业ID
      if (this.$store.state.user.info.currentCompany.companyId !== this.$store.state.back.companyId) {
        urlParams.companyId = this.$store.state.back.companyId;
      }

      const apiRes = await createOrder(params, urlParams);
      if (apiRes.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(apiRes);
        this.loading = false;
        return;
      }
      // 3. 信息完善 -> 4. 确认完成
      this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[3].key);
      // 下单完成 清空所选模板
      this.$store.commit("back/setSelectedTemplate", {});
      await this.$router.replace({
        name: "BackOrderAddResult",
        params: {
          type: this.type
        }
      });
      this.loading = false;
    },
    fileErrorTitleFormatter(file) {
      const { error } = file;
      if (error.status === 601) {
        return this.$t("order.detail.uploadFailedAcceptError");
      }
      if (error.status === 602) {
        return this.$t("order.detail.uploadFailedLimitError");
      }
      return this.$t("order.detail.uploadFailed");
    },
    fileErrorMsgFormatter(file) {
      const { name } = file;
      const error = file.error || {};
      const response = file.response || {};
      const msgFmt = msg => {
        return `${this.$t("order.detail.uploadFailedMsg", { name })}${msg}`;
      };
      // Informational responses (100 – 199)
      // Successful responses (200 – 299)
      // Redirection messages (300 – 399)
      // Client error responses (400 – 499)
      // Server error responses (500 – 599)
      // Custom error 601=格式错误
      // Custom error 602=大小错误

      // ⑤ 前端校验错误 - 格式错误
      if (error.status === 601) {
        return (
          <div>
            <p>{`【${name}】${this.$t(
              "order.detail.uploadFailedAcceptError"
            )}`}</p>
            <p>{`${this.$t(
              "order.detail.uploadFailedAccept"
            )}${FILE_TYPES}`}</p>
          </div>
        );
      }

      // ⑥ 前端校验错误 - 大小错误
      if (error.status === 602) {
        let message = this.$t('order.detail.uploadFailedLimit', {
          limit: '50MB'
        });

        return (
          <div>
            <p>{`【${name}】`}</p>
            <p>{`${message}`}</p>
          </div>
        );
      }

      // ① 服务端错误优先展示服务端失败信息
      if (error.status >= 500 && error.status < 600) {
        // 文件类型、文件大小、其他提供了解决办法的
        // 目前根据接口文档仅有
        // 1007=不允许上传此类文件（空文件名、空扩展名、扩展名不允许）
        // 1008=不能上传空文件
        // 1009=上传的文件过大
        // 500=其它错误-打开上传助手
        if ([1007, 1008, 1009].includes(response.code)) {
          return (
            <div>
              <p>{msgFmt(response.msg)}</p>
              <p>{this.$t("order.detail.uploadFailedHelp")}</p>
              <p style="word-break: break-all;">
                {this.$t("order.detail.uploadFailedTid")}
                {response.tid}
              </p>
            </div>
          );
        }
      }
      // ② 客户端错误可能是网络问题
      // if (error.status >= 400 && error.status < 500) {
      //   return msgFmt(this.$t("order.detail.uploadFailed400"));
      // }
      // ③ 请求被重定向
      // if (error.status >= 300 && error.status < 400) {
      //   return msgFmt(this.$t("order.detail.uploadFailed300"));
      // }
      // ④ 其它错误
      let otherMsg = `${response.msg || error.message}, status: ${
        error.status
      }`;
      if (response.code) {
        otherMsg += `, code: ${response.code}`;
      }
      return (
        <div>
          <p style="word-break: break-all;">
            {msgFmt(
              this.$t("order.detail.uploadFailedOther", { msg: otherMsg })
            )}
          </p>
          <p>{this.$t("order.detail.uploadFailedHelp")}</p>
          {response.tid ? (
            <p style="word-break: break-all;">
              {this.$t("order.detail.uploadFailedTid")}
              {response.tid}
            </p>
          ) : null}
        </div>
      );
    },
    // 上传助手核对候选人信息
    onUploadCheckCandidate(callback) {
      callback(cloneDeep(this.form));
    },
    // 查看样本报告
    onQuestionnaireDemo() {
    },
    // 设置默认表单
    getDefaultFormData() {
      const obj = {
        ...formEmpty,
        // feat#20231229 预计入职日期放到展开的外面，同时这个字段有默认值，当前日期+14天
        expectedEntryTime: moment(this.serverTime).add(this.defaultExpectedEntryTimeAfterDays, "days")
      };
      // 易查宝
      if (this.selected.type === CREATE_TYPE.QUICK) {
        // 线上授权-是否默认选中
        obj.authorizationRequired = this.formConfig.onlineAuthForQuickOrder;
        // 联系候选人-是否默认选中
        obj.isContact = this.formConfig.contactCandidateForQuickOrder;
      }
      // 工作订单
      if (this.selected.type === CREATE_TYPE.TRADITION) {
        // 线上授权-是否默认选中
        obj.authorizationRequired = this.formConfig.onlineAuthForWorkOrder;
        // 联系候选人-是否默认选中
        obj.isContact = this.formConfig.contactCandidateForWorkOrder;
      }
      return obj;
    },
    // 获取默认批量表单
    getDefaultBatchFormData() {
      const obj = { ...batchFormEmpty };
      // 易查宝
      if (this.selected.type === CREATE_TYPE.QUICK) {
        // 线上授权-是否默认选中
        obj.authorizationRequired = this.formConfig.onlineAuthForQuickOrder;
      }
      // 工作订单
      if (this.selected.type === CREATE_TYPE.TRADITION) {
        // 线上授权-是否默认选中
        obj.authorizationRequired = this.formConfig.onlineAuthForWorkOrder;
      }
      return obj;
    }
  },
  mounted() {
    // 判断是否选择了模板（在当前页面刷新）
    if (!this.selected.id) {
      this.onPrev();
      return;
    }
    //非易查宝下单不检查证件号格式
    if(this.selected.type === CREATE_TYPE.TRADITION) {
      delete this.rules.idNumbering;
    }
    this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[2].key);
    this.getBaseParams();
    this.form = { ...this.getDefaultFormData() };
    this.batchForm = { ...this.getDefaultBatchFormData() };
    //  从store读取候选人
    if (this.$route.query.selected === "1") {
      const { candidateList } = this.$store.state.back;
      if (!candidateList || !candidateList.length) {
        return;
      }
      this.form = {
        ...this.form,
        ...candidateList[0],
        phoneValue: {
          mobile: candidateList[0].phone,
          code: candidateList[0].phoneDialingCode
        }
      };
    }
    if (this.$route.query.batch === "1") {
      const { candidateList } = this.$store.state.back;
      if (!candidateList || !candidateList.length) {
        return;
      }
      this.dataList = candidateList;
    }
  }
};
</script>

<style lang="less" scoped>
.background-order-selected-template {
  width: 100%;
  background: #f4f5fc;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-weight: normal;
  color: #757e8c;
  line-height: 32px;
  padding: 10px 21px;
  margin-bottom: 20px;

  .selected-template-inner {
    width: 618px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
.form-item-authorization-required {
  margin-bottom: 0;
}
.authorization-required-tips {
  color: #a6aab2;
  margin-bottom: 20px;

  p {
    margin: 0;
  }

  .anticon-exclamation-circle {
    color: @warning-color;
  }
}
.background-order-form {
  .expand-more {
    height: 32px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: @primary-color;
    margin-top: 12px;

    &.expanded {
      .expand-more-content {
        width: 73px;
      }
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 1px;
      background: #d8e1f3;
      top: 16px;
      left: 0;
    }

    &-content {
      width: 101px;
      height: 100%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      position: relative;
      z-index: 1;
      cursor: pointer;
    }
  }
  /deep/.join-gift.ant-form-item {
    > .ant-form-item-label > label {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .anticon-exclamation-circle {
        color: @warning-color;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  &-save {
    display: flex;
    justify-content: center;
    padding: 33px 0;
  }
  &-btn {
    width: 146px;
  }
  &-table {
    margin-top: 6px;
  }
  &-batch {
    .batch-form {
      width: 618px;
      margin: 0 auto;
    }
    .batch-download {
      font-size: 16px;
      font-weight: normal;
      color: #293b58;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
    }
    .batch-download-btn {
      display: block;
      padding: 0 20px;
      text-align: center;
      height: 30px;
      line-height: 28px;
      background: rgba(2, 122, 255, 0.08);
      border-radius: 30px 30px 30px 30px;
      opacity: 1;
      border: 1px solid @primary-color;
      color: @primary-color;
      font-size: 14px;
    }
    .batch-download-tips {
      font-size: 14px;
      font-weight: 400;
      color: #a6aab2;
      margin-top: 14px;
    }
    .batch-upload {
      /deep/.ant-upload.ant-upload-drag {
        margin-top: 18px;
        border: 1px dashed @primary-color;
        background: #f4f5fc;
        border-radius: 4px 4px 4px 4px;

        .ant-upload {
          padding-top: 35px;
          padding-bottom: 17px;
          padding-left: 40px;
          padding-right: 40px;
        }

        .ant-upload-text {
          font-size: 14px;
          font-weight: 400;
          color: #293b58;
          margin-top: 8px;
        }
        .batch-upload-tips {
          font-size: 14px;
          font-weight: 400;
          color: #a6aab2;
          line-height: 26px;
          margin-top: 13px;

          .tips-top {
            padding: 0 40px;
          }
          .tips-mid {
            color: @error-color;
            text-align: left;
            margin-top: 20px;
          }
          .tips-bot {
            text-align: left;
          }
        }
      }
    }
    .batch-clear {
      display: flex;
      justify-content: center;
      margin-top: 23px;

      &-btn {
        width: 146px;
      }
    }
    .batch-icon {
      width: 50px;
      height: 43px;
    }
    .batch-tips {
      margin-top: 15px;
      margin-bottom: 27px;
      background: #fff5e6;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ffbc36;
      padding: 18px 21px;

      &-title {
        font-size: 18px;
        font-weight: 400;
        color: #293b58;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 11px;

        .anticon-exclamation-circle {
          font-size: 22px;
          color: @warning-color;
        }
      }
      p {
        font-size: 14px;
        font-weight: normal;
        color: #757e8c;
        line-height: 32px;
        margin: 0;
      }
    }
  }
}
</style>
