<template>
  <div>
    <a-spin :spinning="loading">
      <CardHeader :title="title" :icon="'icon-bar'">
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="companyId"
          class="brief-select"
          @change="onCompanyChange"
          v-if="multipleCompany"
        >
          <a-select-option
            :value="i.companyId"
            v-for="i in companyList"
            :key="i.companyId"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
      </CardHeader>
      <Echarts
        ref="eChartsRef"
        :id="echarts.id"
        :height="echarts.height"
        :options="echarts.options"
      />
    </a-spin>
  </div>
</template>

<script>
import Echarts from "@/components/Echarts";
import CardHeader from "./CardHeader.vue";
import { FETCH_CODE } from "@/config";
import { zhaopin } from "@/pages/workspace/backgroundCheck/index/api";
import { getDic } from "@/api";
import { mapGetters } from "vuex";

export default {
  name: "CardCandidate",
  components: {
    Echarts,
    CardHeader,
  },
  data() {
    return {
      title: this.$t('index.fxfx'),
      companyId: null,
      echartData: [],
      series: [],
      loading: false,
    };
  },
  computed: {
    echarts() {
      return {
        id: "candidate",
        height: "400px",
        options: {
          legend: {
            icon: "circle",
          },
          tooltip: {
            borderColor: "transparent",
          },
          xAxis: {
            type: "category",
            name: "月份",
            data: Object.keys(this.echartData).map((it) => `${it}${this.$t("datetime.unit.month")}`),
          },
          yAxis: {
            type: "value",
            name: "总量",
            nameRotate: 90,
          },
          series: this.series,
        },
      };
    },
    ...mapGetters("user", ["multipleCompany", "companyList"]),
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await zhaopin({
        companyId: this.companyId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
        try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
        return;
      }
      const recruitSource = await getDic({
        code: "recruit_source",
      });
      const sourceList = recruitSource.data || [];
      const echartData = res.data || [];
      const series = sourceList.map((it) => ({
        code: it.dictCode,
        name: it.dictName,
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: [],
      }));
      this.echartData = echartData;
      for (const month of Object.values(echartData)) {
        for (const data of month) {
          for (const src of series) {
            if (data.code === src.code) {
              src.data.push(data.number);
              break;
            }
          }
        }
      }
      this.series = series;
      try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
      this.loading = false;
    },
    async getCompanyList() {
      this.companyId = this.companyList[0]?.companyId;
    },
    onCompanyChange(companyId) {
      this.companyId = companyId;
      this.getData();
    },
  },
  async mounted() {
    if (this.multipleCompany) {
      await this.getCompanyList();
    }
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.brief-select {
  width: 192px;
  margin-left: 10px;
}
</style>
