<template>
  <a-modal
    :title="$t('survey.description.title')"
    v-model="visibleInner"
    class="survey-desc-modal"
    :width="650"
    :maskClosable="false"
    destroyOnClose
  >
    <template slot="footer">
      <a-button type="primary" @click="onClose">
        {{ $t("button.confirm") }}
      </a-button>
    </template>
    <div class="survey-desc-content">
      <p>{{ $t("survey.description.p1") }}</p>
      <p>{{ $t("survey.description.p2") }}</p>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "SurveyDescription",
  props: { visible: Boolean },
  data() {
    return {};
  },
  computed: {
    visibleInner: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less">
.survey-desc-content {
  h6 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0.5em;
  }
  strong {
    display: block;
    margin-top: 2em;
    margin-bottom: 0.5em;
  }
}
</style>
