<template>
  <a-spin class="source-wrap" :spinning="loading">
    <a-row :gutter="[16, 48]">
      <a-col :span="calcFormItemLayout([6, 7]).labelCol.span">
        <div class="source-title">{{ $t("backCheck.source") }}</div>
      </a-col>
      <a-col :span="calcFormItemLayout([6, 7]).wrapperCol.span">
        <a-radio-group v-model="value" class="source-radio">
          <a-radio
            :value="i.dictCode"
            v-for="i in sourceList"
            :key="i.dictCode"
          >
            {{ i.dictName }}
          </a-radio>
          <!--<a-button @click="addSource" type="primary" ghost>
            {{ $t("order.detail.addSource") }}
          </a-button>-->
        </a-radio-group>
      </a-col>
    </a-row>
    <!-- 添加招聘来源 -->
    <template v-if="isSourceModal">
      <SourceModal :isSourceModal.sync="isSourceModal" @reload="getData" />
    </template>
  </a-spin>
</template>

<script>
import SourceModal from "./SourceModal";
import { getDic } from "@/api";
import { showMsg } from "@/utils";
import { FETCH_CODE } from "@/config";
import { langMixin } from "@/mixins";

export default {
  name: "InviteSource",
  components: {
    SourceModal
  },
  mixins: [langMixin],
  data() {
    return {
      isSourceModal: false,
      sourceList: [],
      value: "",
      loading: false
    };
  },
  methods: {
    addSource() {
      this.isSourceModal = true;
    },
    getValue() {
      if (!this.value) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("order.detail.emptySource")
        });
        return false;
      }
      const label =
        this.sourceList.find(i => i.dictCode === this.value)?.dictName || "";
      return { value: this.value, label };
    },
    async getData() {
      this.loading = true;
      const res = await getDic({ code: "recruit_source" });
      this.sourceList = res.data || [];
      this.loading = false;
    },
    setValue(value) {
      this.value = value;
    }
  },
  async mounted() {
    await this.getData();
    if (this.sourceList.length) {
      let defaultValue = this.sourceList.find((it) => it.dictCode === "other");
      if (!defaultValue) {
        defaultValue = this.sourceList[0];
      }
      this.value = defaultValue.dictCode;
    }
  }
};
</script>

<style lang="less" scoped>
.source-wrap {
  font-size: @font-size-large;
  .source-title {
    white-space: nowrap;
    color: @font-color;
    text-align: right;
    margin-right: 18px;
  }
  .source-radio {
    /deep/.ant-radio-wrapper {
      font-size: @font-size-large;
      width: 20%;
      margin-bottom: @ec-gutter20;
      min-width: 100px;
    }
  }
}
</style>
