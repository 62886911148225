<template>
  <div>
    <template v-if="isShow">
      <div class="ec-card report-card">
        <ListTitleCard :title="$t('message.report.process.title')">
          <a-steps
            :current="current"
            labelPlacement="vertical"
            size="small"
            class="task-steps"
          >
            <a-step
              v-for="i in taskScheduleList"
              :key="i.ParmName"
              :title="i.ParmName || '-'"
              :description="i.ScheduleDateTime || ''"
              :status="i.ScheduleState > 0 ? 'process' : 'wait'"
            >
            </a-step>
          </a-steps>
          <ul class="task-step-tips">
            <li class="tips-item">
              <div class="tips-icon active">0</div>
              <div class="tips-text">
                {{ $t('message.report.process.tip1') }}
              </div>
            </li>
            <li class="tips-item">
              <div class="tips-icon">0</div>
              <div class="tips-text">
                {{ $t('message.report.process.tip2') }}
              </div>
            </li>
          </ul>
        </ListTitleCard>
      </div>
      <div class="ec-card report-card">
        <!-- 委托单信息 -->
        <ListTitleCard :title="$t('message.report.info.order')">
          <a-descriptions bordered :column="2" size="middle">
            <a-descriptions-item
              :label="i.label"
              v-for="i in list"
              :key="i.key"
            >
              {{ orderInfo[i.key] || "-" }}
            </a-descriptions-item>
          </a-descriptions>
        </ListTitleCard>
        <!-- 候选人信息 -->
        <ListTitleCard :title="$t('message.report.canInfo')">
          <a-descriptions bordered :column="2" size="middle">
            <a-descriptions-item
              :label="i.label"
              v-for="i in candidateList"
              :key="i.key"
            >
              {{ candidateInfor[i.key] || "-" }}
            </a-descriptions-item>
          </a-descriptions>
        </ListTitleCard>
        <!-- 核实套餐   -->
        <ListTitleCard :title="$t('message.report.order')">
          <div class="meal">
            <div class="meal-head">
              {{ orderInfo.ReportVersion }}
            </div>
            <div class="meal-body">
              <div
                class="meal-body_item"
                v-for="i in surveyInfoList"
                :key="i.ProductName"
              >
                <span>{{ i.ProductName }}</span>
                <span class="item-section"
                  >{{ i.Sections }}{{ $t("section") }}</span
                >
              </div>
            </div>
          </div>
        </ListTitleCard>
        <!-- 任务栏备注 -->
        <ListTitleCard title="">
          <!-- 底部栏 -->
          <div class="footer">
            <a-button @click="onBack">
              {{ $t("button.back") }}
            </a-button>
          </div>
        </ListTitleCard>
      </div>
    </template>
    <AddressModal :isShowModal.sync="isAddressModal" v-if="isAddressModal" />
  </div>
</template>

<script>
import ListTitleCard from "@/pages/home/components/ListTitleCard";
import AddressModal from "@/pages/home/components/AddressModal";
import { getTaskDetail } from "@/pages/workspace/backgroundCheck/index/api";
import { DateFormat, FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import moment from "moment";

export default {
  name: "ReportProcess",
  components: {
    ListTitleCard,
    AddressModal
  },
  data() {
    return {
      current: 1,
      list: [
        {
          key: "CompanyName",
          label: this.$t("order.detail.info.custom")
        },
        {
          key: "ReportLanguageName",
          label: this.$t("order.detail.lang")
        },
        {
          key: "TaskNum",
          label: this.$t("message.report.info.number")
        },
        {
          key: "ContactName",
          label: this.$t("message.report.info.person")
        },
        {
          key: "Parm_AreaName",
          label: this.$t("order.detail.info.area")
        },
        {
          key: "ReportVersion",
          label: this.$t("message.report.info.version")
        },
        {
          key: "CCAddress",
          label: this.$t("order.detail.info.cc")
        }
      ],
      candidateList: [
        {
          key: "CandidateName",
          label: this.$t("order.detail.name")
        },
        {
          key: "CandidateSexName",
          label: this.$t("order.detail.sex")
        },
        {
          key: "CandidateEmail",
          label: this.$t("order.detail.email")
        },
        {
          key: "CandidateExpectedEntryTime",
          label: this.$t("order.detail.entry")
        }
      ],
      address: "lucy",
      items: ["jack", "lucy"],
      isShow: false,
      isAddressModal: false,
      candidateInfor: {
        CandidateName: "",
        CandidateSexName: "",
        CandidateEmail: "",
        CandidateExpectedEntryTime: ""
      }, // 候选人信息
      operationRecordList: [], // 操作记录列表
      surveyInfoList: [], // 核实套餐
      orderInfo: {
        CompanyName: "",
        ReportLanguageName: "",
        TaskNum: "",
        Parm_AreaName: "",
        CCAddress: "",
        ContactName: "",
        ReportVersion: ""
      }, // 委托单信息
      taskScheduleList: [] // 任务进度
    };
  },
  methods: {
    // 添加抄送地址
    addItem() {
      this.isAddressModal = true;
    },
    async getDetail() {
      const res = await getTaskDetail({ taskId: this.$route.params.id });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.isShow = false;
        return;
      }
      res.data = res.data || {};
      this.candidateInfor = res.data.CandidateInfor || {};
      if (this.candidateInfor.CandidateExpectedEntryTime) {
        this.candidateInfor.CandidateExpectedEntryTime = moment(
          this.candidateInfor.CandidateExpectedEntryTime
        ).format(DateFormat);
      }
      this.operationRecordList = res.data.OperationRecordList || [];
      this.surveyInfoList = res.data.SurveyInfoList || [];
      this.orderInfo = {
        ...res.data.OrderInfo,
        TaskNum: res.data.CandidateInfor?.TaskNum
      };
      this.taskScheduleList = res.data.TaskScheduleList || [];
      this.taskScheduleList.forEach(i => {
        if (
          i.ScheduleDateTime !== "0001-01-01T00:00:00" &&
          moment(i.ScheduleDateTime).isValid()
        ) {
          i.ScheduleDateTime = moment(i.ScheduleDateTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          i.ScheduleDateTime = "";
        }
      });
      this.isShow = true;
    },
    onBack() {
      this.$router.back();
    }
  },
  mounted() {
    this.getDetail();
  }
};
</script>

<style lang="less" scoped>
.task-steps {
  flex-wrap: wrap;
  width: 98%;
  /deep/.ant-steps-item {
    margin-bottom: @ec-gutter * 2;
    //flex-grow: 0;
  }
}
.task-step-tips {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0;

  .tips-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    .tips-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.25);
      color: rgba(0, 0, 0, 0.25);

      &.active {
        background: @primary-color;
        color: #ffffff;
        border-color: @primary-color;
      }
    }
  }
}
.footer {
  margin-top: @ec-gutter20;
  .btn-group(center);
}
/deep/.ant-steps-item-title {
  white-space: nowrap !important;
}
.report-card {
  padding-left: 0;
  padding-right: 0;
}
/deep/.list-card {
  padding: 0;
}
.meal {
  border-radius: 4px;
  border: 1px solid #e9ebfb;
  &-head {
    padding: 16px 23px 13px 23px;
    background: #ccd8f0;
    font-weight: bold;
  }
  &-body {
    padding: 24px 23px 36px 23px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    &_item {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-section {
        background: #fff3e3;
        border-radius: 20px;
        border: 1px solid #ffbc36;
        padding: 2px 10px;
        font-size: 14px;
        color: #ffbc36;
        white-space: nowrap;
      }
    }
  }
}
</style>
