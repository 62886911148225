<template>
  <div class="present-add">
    <div class="present-title">
      {{ $t("backCheck.dcTitle") }}
    </div>
    <ECard
      class="present-card ec-card"
      :title="$t('backCheck.dcAddTitle')"
      :isShowIcon="false"
    >
      <div class="present-card-body">
        <DetailForm
          :formList="formList"
          :rules="rules"
          :formValue.sync="form"
          :labelCol="calcFormItemLayout([8, 10]).labelCol.span"
          :wrapperCol="calcFormItemLayout([8, 10]).wrapperCol.span"
          :span="24"
          class="present-card-form"
          ref="form"
        />
        <InviteSource ref="source" />
      </div>
      <ECardButton
        :save="save"
        :batchSubmit="onBatchSubmit"
        upload-url="/interview/importInterview"
        download-url="/template/PresenceRecordImportTemplate.xlsx"
        download-local
        :download-name="$t('present.info.template')"
      />
    </ECard>
    <ECTable
      :columns="columns"
      :data-source="dataList"
      :pagination="false"
      :scroll="{ y: 800 }"
    >
      <template slot="footerCustom">
        <div></div>
      </template>
      <!-- 授权状态 -->
      <template slot="statusNo" slot-scope="text, record">
        <span
          :class="[record.statusNo ? 'ec-color-success' : 'ec-color-danger']"
          >{{
            record.statusNo ? $t("backCheck.done") : $t("backCheck.undo")
          }}</span
        >
      </template>
      <!--   登记HR    -->
      <template slot="hrName">
        {{ info.name }}
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <a-button type="link" class="table-btn" @click="edit(record)">
          <a-icon type="edit" />
          {{ $t("button.edit") }}
        </a-button>
        <a-popconfirm
          :title="$t('button.confirmDelete')"
          :ok-text="$t('button.yes')"
          :cancel-text="$t('button.no')"
          @confirm="remove(record.id)"
        >
          <a-button type="link" class="table-btn danger">
            <a-icon type="delete" />
            {{ $t("button.remove") }}
          </a-button>
        </a-popconfirm>
      </template>
    </ECTable>
    <div class="table-operations table-block">
      <a-button type="primary" @click="add" class="table-btn">{{
        $t("index.ksxj.daochang")
      }}</a-button>
      <a-button type="primary" @click="onBack" class="table-btn">{{
        $t("button.back")
      }}</a-button>
    </div>
  </div>
</template>

<script>
import InviteSource from "@/pages/home/components/InviteSource";
import DetailForm from "@/pages/home/components/DetailForm";
import ECTable from "@/components/ECTable";
import ECard from "@/components/ECard";
import ECardButton from "@/components/ECardButton";
import { FETCH_CODE } from "@/config/fetch";
import { showMsg } from "@/utils";
import { addInterviewBatch } from "@/pages/workspace/backgroundCheck/presentRecord/api";
import { REG_IDCARD, REG_MOBILE } from "@/config";
import { getFakeId } from "@/utils/tools";
import { mapState } from "vuex";
import { trim } from "lodash";
import { langMixin } from "@/mixins";

export default {
  name: "AddPresent",
  components: {
    InviteSource,
    DetailForm,
    ECTable,
    ECard,
    ECardButton
  },
  mixins: [langMixin],
  data() {
    return {
      formList: [
        {
          key: "name",
          label: this.$t("order.filter.name"),
          type: "input"
        },
        {
          key: "identityNum",
          label: this.$t("echeck.table.idCard"),
          type: "input"
        },
        {
          key: "mobile",
          label: this.$t("present.verify.cphone"),
          type: "input"
        }
      ],
      isAdd: true,
      dataList: [],
      columns: [
        {
          title: this.$t("backCheck.cname"),
          dataIndex: "name",
          align: "center"
        },
        {
          title: this.$t("backCheck.cidcard"),
          dataIndex: "identityNum",
          align: "center"
        },
        {
          title: this.$t("backCheck.cphone"),
          dataIndex: "mobile",
          align: "center"
        },
        {
          title: this.$t("backCheck.hr"),
          dataIndex: "hrName",
          align: "center",
          scopedSlots: { customRender: "hrName" }
        },
        {
          title: this.$t("backCheck.source"),
          dataIndex: "recruitSourceName",
          align: "center"
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ],
      isUploadModal: false,
      isCandidateModal: false,
      current: 1,
      total: 0,
      pageSize: 10,
      pending: false,
      form: {}
    };
  },
  methods: {
    // 保存
    save() {
      const valid = this.$refs.form.valid();
      if (typeof valid === "object") {
        const { value, label } = this.$refs.source.getValue();
        if (value) {
          this.$refs.form.getForm();
          // 编辑
          if (this.form.id) {
            const index = this.dataList.findIndex(i => i.id === this.form.id);
            this.dataList.splice(index, 1, {
              ...this.form,
              recruitSource: value,
              recruitSourceName: label
            });
            // 新建
          } else {
            this.form = {
              id: getFakeId(),
              recruitSource: value,
              recruitSourceName: label,
              identityNum: trim(valid.identityNum),
              mobile: trim(valid.mobile),
              name: trim(valid.name)
            };
            this.dataList.push({ ...this.form });
          }
          this.form = {};
          this.$refs.form.clear();
        }
      }
    },
    // 创建到场
    add() {
      if (!this.dataList.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("showMsg.warning.empty")
        });
        return;
      }
      this.addPresent();
    },
    async addPresent() {
      if (this.pending) return;
      this.pending = true;
      const list = this.dataList.map(it => {
        return {
          ...it
        };
      });
      const res = await addInterviewBatch(list);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.pending = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.add")
      });
      // 清空列表
      this.dataList = [];
      this.pending = false;
    },
    remove(id) {
      const index = this.dataList.findIndex(i => i.id === id);
      this.dataList.splice(index, 1);
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.delete")
      });
    },
    edit(record) {
      this.form = record;
      this.$refs.form.setForm(record);
      this.$refs.source.setValue(record.recruitSource);
    },
    onBack() {
      this.$router.back();
    },
    async onBatchSubmit(list) {
      return await addInterviewBatch(list);
    }
  },
  computed: {
    rules() {
      let rule = {};
      this.formList.map(f => {
        rule[f.key] = [
          {
            required: true,
            message: `${this.$t("input")}${f.label}`
          },
          {
            max: 50,
            message: this.$t("maxInput", { label: f.label, length: 50 })
          }
        ];
        if (f.key === "mobile") {
          rule[f.key].push({
            pattern: REG_MOBILE,
            message: `${this.$t("input")}${this.$t("right")}${this.$t(
              "homeModal.account.mobile"
            )}`
          });
        }
        if (f.key === "identityNum") {
          rule[f.key].push({
            pattern: REG_IDCARD,
            message: `${this.$t("input")}${this.$t("right")}${this.$t(
              "backCheck.report.idCard"
            )}`
          });
        }
      });
      return rule;
    },
    ...mapState({
      info: state => state.user.info,
    })
  }
};
</script>

<style lang="less" scoped>
.present-add {
  .present-title {
    color: @font-color;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .present-card {
    &-body {
      display: flex;
    }
    &-title {
      margin-bottom: 20px;
    }
    &-form {
      width: 50%;
      margin-right: @ec-gutter * 6;
    }
    &-btn {
      .btn-group(center);
    }
  }
  .candicate-card {
    min-height: 390px;
    position: relative;
    padding: 0;
    .no-data {
      .background("~@/assets/images/no-data.png");
      width: 260px;
      height: 165px;
      .center();
    }
    &-title {
      padding: 20px;
      border-bottom: 1px solid #e2e8ee;
      margin-bottom: 0;
    }
  }
  .danger {
    color: @error-color;
  }
  .table-block {
    gap: @ec-gutter20;
  }
}
</style>
