<!-- 消息记录 -->
<template>
  <div class="card-msg">
    <!-- 标题 -->
    <CardHeader :title="$t('index.xjjl')" :icon="'icon-message'">
      <a-button type="link" @click="goToList" v-if="list.length">{{ $t("index.more") }}</a-button>
    </CardHeader>
    <!-- 列表 -->
    <div class="card-msg-body">
      <template v-if="list.length">
      <div
        class="card-msg-list"
        v-for="(item, index) in list"
        :key="index"
        @click="goToDetail(item.taskId)"
      >
        <i class="iconfont icon-mess"></i>
        <div class="card-msg-item">
          <div class="msg-title">
            <span class="company">{{
              info.currentCompany.nameShort || info.currentCompany.name
            }}</span
            ><span v-html="item.stateName" />
          </div>
          <div class="msg-time">{{ item.time }}</div>
        </div>
      </div>
      </template>
      <div v-else class="card-msg_text">{{ $t('index.noMsg') }}</div>
    </div>
  </div>
</template>

<script>
import CardHeader from "./CardHeader.vue";
import { messageList } from "@/pages/workspace/backgroundCheck/index/api";
import { FETCH_CODE } from "@/config";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "CardMsg",
  components: {
    CardHeader
  },
  data() {
    return {
      list: []
    };
  },
  methods: {
    goToList() {
      this.$router.push("/message/list");
    },

    goToDetail(taskId) {
      this.$router.push({
        name: "MessageProcess",
        params: {
          id: taskId
        }
      });
    },
    async getMsgList() {
      const res = await messageList();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.list = res.data || [];
      this.list.map(l => {
        l.time = l.messageDateTime
          ? moment(l.messageDateTime).format("YYYY-MM-DD HH:mm:ss")
          : "";
      });
    }
  },
  computed: {
    ...mapState({
      info: state => state.user.info
    })
  },
  mounted() {
    this.getMsgList();
  }
};
</script>

<style lang="less" scoped>
.card-msg {
  box-shadow: 0px 4px 4px 1px rgba(15, 113, 222, 0.06);
  border: 1px solid #e9ebfb;
  //padding: 15px 20px;
  border-radius: 8px;
  .card-head {
    padding: 10px 20px;
    margin-bottom: 0;
  }
  &-body {
    max-height: calc(100% - 68px);
    overflow-y: auto;
  }
  &_text {
    text-align: center;
  }
  &-list {
    display: flex;
    align-items: center;
    .icon-mess {
      font-size: 40px;
      flex-shrink: 0;
      margin-right: 16px;
    }
  }
  &-item {
    overflow: hidden;
    .msg-title {
      cursor: pointer;
      .single-hidden();
      /deep/.company {
        font-weight: bold;
        margin: 0 4px;
      }
    }
    .msg-time {
      color: #a6aab2;
      white-space: nowrap;
      font-size: @font-size-small;
    }
  }
}
</style>
