<!-- 数据简报 -->
<template>
  <div class="card-brief">
    <a-spin :spinning="loading">
    <!-- 卡片头部 -->
    <CardHeader :title="$t('index.sjjb.title')" :icon="'icon-manage'">
      <a-select
        :getPopupContainer="trigger => trigger.parentNode"
        v-model="companyId"
        class="brief-select"
        @change="onCompanyChange"
        v-if="multipleCompany"
      >
        <a-select-option
          :value="i.companyId"
          v-for="i in companyList"
          :key="i.companyId"
        >
          {{ i.name }}
        </a-select-option>
      </a-select>
      <a-select
        :getPopupContainer="trigger => trigger.parentNode"
        v-model="selectedTime"
        class="brief-select"
        @change="onDateChange"
      >
        <a-select-option :value="i.key" v-for="i in datetimeFilterList" :key="i.key">
          {{ i.label }}
        </a-select-option>
      </a-select>
    </CardHeader>
    <!-- 卡片body -->
    <div class="card-brief-body">
      <div class="brief-item-wrap" v-for="i in list" :key="i.title">
        <div class="brief-item">
          <i class="iconfont icon-data"></i>
          <div class="brief-num">{{ i.num || 0 }}</div>
          <div class="brief-title">{{ i.title }}</div>
        </div>
      </div>
    </div>
      </a-spin>
  </div>
</template>

<script>
import CardHeader from "../index/CardHeader.vue";
import { getBrief } from "@/pages/workspace/backgroundCheck/index/api";
import { DateFormat, FETCH_CODE } from "@/config";
import moment from "moment"
import { datetimeFilter } from "@/mixins";
import { showMsg } from "@/utils";
import { mapGetters } from "vuex";

export default {
  name: "CardBrief",
  components: {
    CardHeader
  },
  mixins: [datetimeFilter],
  data() {
    return {
      list: [
        // {
        //   title: this.$t("index.sjjb.dzd"),
        //   url: "/bill/list",
        //   num: 0,
        //   key: "waitStatementQuantity"
        // },
        {
          title: this.$t("index.sjjb.wdbg"),
          num: 0,
          key: "unreadReportQuantity",
          url: ""
        },
        // {
        //   title: this.$t("index.sjjb.wtd"),
        //   num: 0,
        //   url: "",
        //   key: "waitEditEntrustmentQuantity"
        // },
        {
          title: this.$t("index.sjjb.wtdl"),
          num: 0,
          url: "",
          key: "newEntrustmentQuantity"
        }
      ],
      selectedTime: 1,
      currentDate: {
        startTimeStr: moment().format(DateFormat),
        endTimeStr: moment().format(DateFormat),
      },
      loading: false,
      companyId: '',
    };
  },
  computed: {
    ...mapGetters("user", ["multipleCompany", "companyList"]),
  },
  methods: {
    navigateTo(url) {
      this.$router.push(url);
    },
    async getData() {
      if (this.loading) {
        return
      }
      this.loading = true
      const params = {
        startTime: this.currentDate.startTimeStr,
        endTime: this.currentDate.endTimeStr,
        companyId: this.companyId,
      }
      const res = await getBrief(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
         showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.loading = false
        return;
      }
      this.list.map(l => {
        l.num = res.data[l.key] || 0;
      });
      this.loading = false
    },
    onDateChange (value) {
      const index = this.datetimeFilterList.findIndex(i => i.key === value);
      if (index !== -1) {
        this.currentDate = this.datetimeFilterList[index];
      }
      this.getData()
    },
    onCompanyChange () {
      this.getData()
    },
    async getCompanyList() {
      this.companyId = this.companyList[0]?.companyId;
    },
  },
  async mounted() {
    if (this.multipleCompany) {
      await this.getCompanyList();
    }
    this.getData();
  }
};
</script>

<style lang="less" scoped>
.card-brief {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(15, 113, 222, 0.06);
  border-radius: 8px;
  &-body {
    display: flex;
    flex-wrap: wrap;
    .brief-item-wrap {
      flex: 0 0 33.33%;
      &:not(:nth-child(3)) {
        padding-right: 20px;
      }
      margin-bottom: 20px;
      .brief-item {
        position: relative;
        .icon-data {
          font-size: 16px;
          position: absolute;
          top: 10px;
          left: 10px;
        }
        width: 100%;
        padding: 25px 0;
        background: rgba(#ccd8f0, 0.2);
        border-radius: 8px 8px 8px 8px;
        &:hover {
          background: #ccd8f0;
        }
        text-align: center;
        .pointer();
        .brief-title {
          color: #757e8c;
          margin-bottom: 10px;
          .no-wrap();
        }
        .brief-num {
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
  }

  .brief-select {
    width: 192px;
    margin-left: 10px;
  }
}
</style>
