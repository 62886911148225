<template>
  <div class="create-wrap">
    <div class="create-item-wrap">
      <div
        class="create-item"
        v-for="i in list"
        :key="i.title"
        @click="navigateTo(i)"
      >
        <div class="create-item-title">{{ i.title }}</div>
        <i :class="`iconfont ${i.icon} create-item-icon`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCreate",
  data() {
    return {
      list: [
        // {
        //   icon: "icon-bcdc",
        //   title: this.$t("backCheck.daochang"),
        //   routerName: "AddPresent",
        // },
        {
          icon: "icon-bcwt",
          title: this.$t("backCheck.order"),
          routerName: 'BackOrderAdd',
        },
        // {
        //   icon: "icon-bcbd",
        //   title: this.$t("backCheck.qsbd"),
        //   routerName: "BackOrderAdd",
        // },
        {
          icon: "icon-bcbd",
          title: this.$t("backCheck.qrCodeAuthList.title"),
          routerName: "QrCodeAuthList",
        },
        {
          icon: "icon-bdhx",
          title: this.$t("backCheck.hxrgl"),
          routerName: "CddManage",
        },
      ],
    };
  },
  methods: {
    // 跳转路由
    navigateTo({ routerName }) {
      const params = { name: routerName };
      this.$router.push(params);
    },
  },
};
</script>

<style lang="less" scoped>
.create-wrap {
  .create-item-wrap {
    display: flex;
    flex: 0 0 25%;
    align-items: center;
    .create-item {
      &:not(&:last-child) {
        margin-right: 20px;
      }
      .pointer();
      &:hover {
        box-shadow: 0px 4px 4px 1px rgba(15, 113, 222, 0.06);
      }
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ccd8f0;
      width: 100%;
      padding: 10px 30px;
      background: url("~@/assets/images/bc-cb.png") center no-repeat;
      background-size: cover;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        font-size: 22px;
      }
      &-icon {
        font-size: 60px;
      }
    }
  }
}
</style>
