<template>
  <div class="ec-card-track">
    <a-spin :spinning="loading">
      <CardHeader :title="title" :icon="'icon-circle'">
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="companyId"
          class="brief-select"
          @change="onCompanyChange"
          v-if="multipleCompany"
        >
          <a-select-option
            :value="i.companyId"
            v-for="i in companyList"
            :key="i.companyId"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
      </CardHeader>
      <Echarts
        ref="eChartsRef"
        :id="echarts.id"
        :height="echarts.height"
        :options="echarts.options"
        :width="echarts.width"
        class="echart"
      />
    </a-spin>
    <div class="echart-table">
      <a-table
        :columns="tableColumns"
        :data-source="tableData"
        bordered
        :pagination="false"
        size="small"
      ></a-table>
    </div>
  </div>
</template>

<script>
import Echarts from "@/components/Echarts";
import CardHeader from "./CardHeader.vue";
import { FETCH_CODE } from "@/config";
import { taskOrderCount } from "@/pages/workspace/backgroundCheck/index/api";
import BigNumber from "bignumber.js";
import { mapGetters } from "vuex";

const eChartsData = [];

export default {
  name: "CardTrack",
  components: {
    Echarts,
    CardHeader,
  },
  data() {
    return {
      title: this.$t('index.gzsl'),
      companyId: null,
      echartData: [],
      total: 0,
      tableColumns: [
        {
          title: this.$t("backCheck.indexECharts.total"),
          dataIndex: "total",
          align: "center",
          key: 7,
        },
        {
          title: this.$t("backCheck.indexECharts.collecting"),
          dataIndex: "collecting",
          align: "center",
          key: 2,
        },
        {
          title: this.$t("backCheck.indexECharts.collectOver"),
          dataIndex: "collectOver",
          align: "center",
          key: 1,
        },
        {
          title: this.$t("backCheck.indexECharts.conductSum"),
          dataIndex: "conductSum",
          align: "center",
          key: 3,
        },
        {
          title: this.$t("backCheck.indexECharts.overSum"),
          dataIndex: "overSum",
          align: "center",
          key: 4,
        },
        {
          title: this.$t("backCheck.indexECharts.stopSum"),
          dataIndex: "stopSum",
          align: "center",
          key: 6,
        },
        {
          title: this.$t("backCheck.indexECharts.revokeSum"),
          dataIndex: "revokeSum",
          align: "center",
          key: 5,
        },
      ],
      tableData: [],
      loading: false,
    };
  },
  computed: {
    echarts() {
      let total = this.total;
      return {
        id: "track",
        height: "300px",
        width: "80%",
        options: {
          tooltip: {
            trigger: "item",
            borderColor: "transparent",
          },
          legend: {
            top: "15%",
            right: "right",
            orient: "vertical",
            formatter: (name) => {
              let value = 0,
                percent = 0;
              this.echartData.map((e) => {
                if (e.name === name) {
                  value = e.value;
                  percent = e.percent;
                }
              });
              const arr = [
                "{a|" + `${name}(${value})` + "}",
                "{b|" + `${percent}%` + "}",
              ];
              return arr.join("");
            },
            textStyle: {
              width: 200,
              lineHeight: 10,
              rich: {
                a: {
                  fontSize: 12,
                  align: "left",
                  color: "#656565",
                  lineHeight: 5,
                },
                b: {
                  fontSize: 12,
                  color: "#989898",
                  align: "right",
                  lineHeight: 5,
                },
              },
            },
          },
          series: [
            {
              type: "pie",
              radius: ["50%", "70%"],
              avoidLabelOverlap: false,
              center: ["35%", "50%"],
              label: {
                show: true,
                position: "center",
                formatter: ["{a| " + total + "}", "{b|" + this.$t("index.wtdl") + "}"].join("\n"),

                rich: {
                  a: {
                    color: "#666",
                    fontSize: 32,
                  },
                  b: {
                    color: "#989898",
                    fontSize: 12,
                  },
                },
              },
              emphasis: {
                label: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: this.echartData,
            },
          ],
        },
      };
    },
    ...mapGetters("user", ["multipleCompany", "companyList"]),
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await taskOrderCount({
        companyId: this.companyId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
        try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
        return;
      }
      let total = 0;
      const keys = [
        "collecting", // 信息收集中
        "collectOver", // 信息收集完成
        "conductSum", // 进行中
        "overSum", // 已完成
        "stopSum", // 已停止
        "revokeSum", // 已暂停
      ];
      const list = [];
      res.data = res.data || [];
      const obj = {
        collectOver: 0,
        collecting: 0,
        conductSum: 0,
        overSum: 0,
        revokeSum: 0,
        stopSum: 0,
      };
      res.data.forEach((it) => {
        obj.collectOver += it.collectOver;
        obj.collecting += it.collecting;
        obj.conductSum += it.conductSum;
        obj.overSum += it.overSum;
        obj.revokeSum += it.revokeSum;
        obj.stopSum += it.stopSum;
      });
      const getName = (key) => {
        let name = "";
        switch (key) {
          case "collectOver":
            name = this.$t("backCheck.indexECharts.collectOver");
            break;
          case "collecting":
            name = this.$t("backCheck.indexECharts.collecting");
            break;
          case "conductSum":
            name = this.$t("backCheck.indexECharts.conductSum");
            break;
          case "overSum":
            name = this.$t("backCheck.indexECharts.overSum");
            break;
          case "revokeSum":
            name = this.$t("backCheck.indexECharts.revokeSum");
            break;
          case "stopSum":
            name = this.$t("backCheck.indexECharts.stopSum");
            break;
          default:
            break;
        }
        return name;
      };
      // 先计算总量
      for (const key of keys) {
        if (obj[key] !== void 0) {
          total += obj[key];
        }
      }
      // 序列化
      for (const key of keys) {
        if (obj[key] !== void 0) {
          let percent = BigNumber(obj[key]).div(total);
          if (percent.isNaN()) {
            percent = BigNumber(0);
          }
          list.push({
            value: obj[key],
            name: getName(key),
            percent: percent.times(100).toFixed(2),
          });
        }
      }
      this.total = total;
      this.echartData = list;
      this.tableData = [{
        ...obj,
        total: Object.values(obj).reduce((a, b) => a + b),
      }];
      try { this.$refs.eChartsRef.init() } catch(err) { console.log(err) }
      this.loading = false;
    },
    async getCompanyList() {
      this.companyId = this.companyList[0]?.companyId;
    },
    onCompanyChange(companyId) {
      this.companyId = companyId;
      this.getData();
    },
  },
  async mounted() {
    if (this.multipleCompany) {
      await this.getCompanyList();
    }
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.ec-card-track {
  position: relative;
  overflow: hidden;
}
.track-select {
  width: 120px;
}
.echart {
  position: relative;
}
.echart-table {
  position: absolute;
  right: 6%;
  bottom: 15px;
  transform: translate(8%, 10%);
}
.brief-select {
  width: 192px;
  margin-left: 10px;
}
</style>
