<template>
  <div class="background-order-result">
    <div class="result-container">
      <img class="result-icon" src="@/assets/images/order/succPic.png" alt="">
      <h5 class="result-title">{{ $t("order.detail.success") }}</h5>
      <p class="result-tips">{{ $t("order.detail.successAutoJump", { s: seconds }) }}</p>
    </div>
    <div class="background-order-foot">
      <a-button
        class="foot-btn"
        @click="onPrev"
      >
        {{ `${$t("button.continue")}${$t("backCheck.order")}` }}
      </a-button>
      <a-button
        class="foot-btn"
        type="primary"
        @click="onNext"
      >
        {{ $t("button.confirm") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import {
  BACK_ORDER_PROGRESS
} from "@/pages/workspace/backgroundCheck/order/config";

export default {
  name: "BackOrderAddResult",
  props: {
    type: String,
  },
  data() {
    return {
      seconds: 5,
      timer: 0,
    };
  },
  methods: {
    startCountdown() {
      this.seconds = 5;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.seconds -= 1;
        if (this.seconds <= 0) {
          clearInterval(this.timer);
          // 跳转
          this.onNext();
        }
      }, 1000);
    },
    onPrev() {
      // 4. 确认完成 -> 继续新建订单
      this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[0].key);
      // 按当前所属企业重新加载下单数据
      this.$store.dispatch("back/getBasicData", {
        companyId: this.$store.state.user.info.currentCompany.companyId
      });

      this.$router.replace({
        name: "BackOrderAdd",
      });
    },
    onNext() {
      // 4. 确认完成 -> 确定
      this.$router.replace({
        name: "CddManage",
      });
    },
  },
  mounted() {
    this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[3].key);
    this.startCountdown();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.background-order-result {
  .result-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .result-icon {
    width: 300px;
    height: 159px;
  }
  .result-title {
    font-size: 20px;
    font-weight: 500;
    color: #293B58;
    line-height: 30px;
    margin-top: 22px;
  }
  .result-tips {
    font-size: 14px;
    font-weight: 400;
    color: #A6AAB2;
    line-height: 30px;
    margin-top: 9px;
  }
}
</style>
