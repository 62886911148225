<template>
  <div class="background-order-entry">
    <ul class="entry-list">
      <li class="entry-item" :class="type === it.name ? 'active' : ''" v-for="it in entryList" :key="it.name"
        @click="onSelect(it)">
        <div class="entry-icon" :class="`entry-icon-${it.name}`"></div>
        <div class="entry-label">
          {{ isEnglish ? it.labelEn : it.label }}
        </div>
      </li>
    </ul>
    <div>
      <a-col :span="12" :offset="6">
        <a-row class="authorization-required-tips">
          <a-col :span="24">
            <p style="text-align: center;">
              <a-icon type="exclamation-circle" theme="filled" style="color: #FFBC36;" @click="onOverseasTip" :title="$t('backCheck.overseasCandidateDataTipHrefTitle')" />
              &nbsp;
              <span
                v-html="$t('backCheck.overseasCandidateDataTip', { email: '<a href=mailto:' + overseasCandidateOrderMailConfig + '><b>' + overseasCandidateOrderMailAddress + '</b></a>' })"></span>
            </p>
          </a-col>
        </a-row>
      </a-col>
    </div>
    <div class="background-order-foot">
      <a-button class="foot-btn" type="primary" @click="onNext">
        {{ $t("button.next") }}
      </a-button>
    </div>

    <a-modal v-model="overseasCandidateOrderTipVisible">
      <template v-slot:title>
      </template>

      <div v-if="this.isChinese">
        <div class="ec-overseas-desc-ul-title">1. 针对无身份证信息的境外候选人下单流程：</div>
        <ul class="ec-overseas-desc-ul">
          <li>境外候选人由于涉及不同的个人保护法，下单流程需特别注意。</li>
          <li>核验项目会根据相关法律进行适当调整，以确保合规性。</li>
          <li>涉及不同的授权方式和价格，具体需根据候选人所在地区的法律和要求确定。</li>
        </ul>

        <div class="ec-overseas-desc-ul-title">2. 下单方式：</div>
        <ul class="ec-overseas-desc-ul">
          <li>如需下单，请发送邮件至 <a :href="'mailto:' + overseasCandidateOrderMailConfig"><b>{{
            overseasCandidateOrderMailAddress
                }}</b></a> ，邮件中请包含候选人的相关信息和下单需求。</li>
          <li>或者，您也可以直接联系我们的客服团队，他们将为您提供专业的下单指导和服务。</li>
        </ul>

        <div class="ec-overseas-desc-ul-title">3. 专业沟通与服务：</div>
        <ul class="ec-overseas-desc-ul">
          <li>我们会有专人与您沟通，确保下单流程的顺利进行。</li>
          <li>专人将解答您关于下单、核验项目、授权方式及价格等方面的所有疑问。</li>
        </ul>

        <div>请按照上述流程操作，以确保境外候选人的下单过程既合规又高效。</div>
      </div>

      <div v-else>
        <div class="ec-overseas-desc-ul-title">1. Order Process for Overseas Candidates Without Mainland ID:</div>
        <ul class="ec-overseas-desc-ul">
          <li>Due to varying data privacy laws across different countries and regions, the order process for overseas candidates without Mainland ID requires special attention.</li>
          <li>Verification Item Adjustment: To ensure compliance, we will adjust the verification items based on the relevant laws and regulations of your country or region.</li>
          <li>Authorization Method and Price Determination: The authorization method and final price will be determined based on the specific legal requirements and market conditions of your region.</li>
        </ul>

        <div class="ec-overseas-desc-ul-title">2. How to Place an Order:</div>
        <ul class="ec-overseas-desc-ul">
          <li>Email Order: Please send your name, contact information, and order requirements to <a :href="'mailto:' + overseasCandidateOrderMailConfig"><b>{{ overseasCandidateOrderMailAddress }}</b></a>.</li>
          <li>Customer Service Inquiry: You can also directly contact our customer service team, and we will provide you with one-on-one order guidance and service.</li>
        </ul>

        <div class="ec-overseas-desc-ul-title">3. Professional Servic:</div>
        <ul class="ec-overseas-desc-ul">
          <li>Our professional team will serve you throughout the entire order process. </li>
          <li>You can consult us at any time for any questions about orders, verification, authorization, or prices.</li>
        </ul>

        <div>Tips:</div>
        <div>To ensure a smooth and efficient order process, please provide as much detailed information as possible in your email or inquiry.</div>
      </div>

      <template v-slot:footer>
        <a-button type="primary" block @click="() => { overseasCandidateOrderTipVisible = false; }">
          {{ $t('button.close') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { BACK_ORDER_PROGRESS, BACK_ORDER_TYPE } from "./config";
import { langMixin } from "@/mixins";

export default {
  name: "BackOrderAddEntry",
  props: {
    type: String
  },
  mixins: [langMixin],
  data() {
    return {
      entryList: [...BACK_ORDER_TYPE],
      overseasCandidateOrderTipVisible: false,
      overseasCandidateOrderMailAddress: 'jingwai@51echeck.com',
      overseasCandidateOrderMailConfig: ''
    };
  },
  methods: {
    onSelect(entry) {
      this.$emit("updateType", entry.name);
    },
    onNext() {
      // 1. 上传方式 -> 2. 模板选择
      this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[1].key);
      this.$router.replace({
        name: "BackOrderAddTemplate",
        params: {
          type: this.type
        }
      });
    },

    onOverseasTip() {
      this.overseasCandidateOrderTipVisible = true
    }
  },
  mounted() {
    this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[0].key);

    if (this.isChinese) {
      this.overseasCandidateOrderMailConfig = `${this.overseasCandidateOrderMailAddress}?subject=${this.$t('backCheck.overseasCandidateDataEmailSubject')}&body=姓名:%0d%0a%0d%0a证件号:%0d%0a%0d%0a备注:%0d%0a%0d%0a`  
    }else {
      this.overseasCandidateOrderMailConfig = `${this.overseasCandidateOrderMailAddress}?subject=${this.$t('backCheck.overseasCandidateDataEmailSubject')}&body=Name:%0d%0a%0d%0aID%20Number:%0d%0a%0d%0aRemark:%0d%0a%0d%0a`
    }
  }
};
</script>

<style lang="less" scoped>
.entry-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 120px;

  .entry-item {
    width: 165px;
    height: 165px;
    background: #f4f5fc;
    border-radius: 4px;
    border: 1px solid #a6aab2;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 24px;
    cursor: pointer;
    transition: all 0.3s linear;

    &:nth-child(1) {
      .entry-icon {
        width: 40px;
      }
    }

    &:nth-child(2) {
      .entry-icon {
        width: 60px;
        height: 48px;
      }
    }

    &.active,
    &:hover {
      border-color: @primary-color;

      .entry-label {
        color: #293b58;
      }

      .entry-icon {
        &-single {
          background-image: url("~@/assets/images/order/candidateIco01.png");
        }

        &-multi {
          background-image: url("~@/assets/images/order/candidateIco02.png");
        }

        &-batch {
          background-image: url("~@/assets/images/order/excelIco.png");
        }
      }
    }
  }

  .entry-icon {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s linear;

    &-single {
      background-image: url("~@/assets/images/order/candidateIco01Disab.png");
    }

    &-multi {
      background-image: url("~@/assets/images/order/candidateIco02Disab.png");
    }

    &-batch {
      background-image: url("~@/assets/images/order/excelIcoDisab.png");
    }
  }

  .entry-label {
    font-size: 14px;
    font-weight: 400;
    color: #a6aab2;
    transition: all 0.3s linear;
  }
}

.ec-overseas-desc-ul-title {
  font-weight: bold;
}

.ec-overseas-desc-ul {
  list-style: inside;
}
</style>
