<template>
  <div class="ec-mobile">
    <a-select
      :getPopupContainer="trigger => trigger.parentNode"
      :value="code"
      show-search
      option-filter-prop="children"
      :filter-option="onFilterOption"
      @change="onCodeChange"
      class="ec-mobile-code"
      :disabled="disabled"
    >
      <a-select-option
        v-for="item in codeList"
        :value="item.id"
        :key="item.id"
        :code="item.code"
      >
        {{ item.code }}({{ item.name }})
      </a-select-option>
    </a-select>
    <a-input
      type="text"
      :value="mobile"
      @change="onMobileChange"
      :placeholder="placeholder"
      class="ec-mobile-input"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import api from "@/utils/fetch";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";

export default {
  name: "EcMobile",
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    modelValue: {
      type: Object,
      default: () => {}
    },
    placeholder: String,
    disabled: Boolean
  },
  data() {
    const value = this.value || {};

    return {
      code: value.code || "",
      mobile: value.mobile || "",
      codeList: []
    };
  },
  watch: {
    value(val = {}) {
      this.code = val.code || "";
      this.mobile = val.mobile || "";
    }
  },
  methods: {
    onMobileChange(e) {
      const val = e.target.value || "";
      this.triggerChange({ mobile: val });
    },
    onCodeChange(id, opt) {
      this.triggerChange({ code: opt.data.attrs.code });
    },
    triggerChange(changedValue) {
      // Should provide an event to pass value to Form.
      const values = Object.assign(
        {},
        { code: this.code, mobile: this.mobile },
        changedValue
      );
      this.$emit("change", values);
      this.$emit("update:modelValue", values);
      this.$emit("update:value", values);
    },
    onFilterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async getCodeList() {
      const res = await api.get(`/common/list`);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      const codeList = res.data || [];
      this.codeList = codeList.map(it => ({
        ...it
      }));
    }
  },
  created() {
    this.getCodeList();
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.ec-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  &-code {
    flex-basis: 130px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &-input {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
</style>
