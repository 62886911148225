<template>
  <div>
    <ECard :title="$t('backCheck.order')">
      <!-- 表单 -->
      <div class="order-form">
        <a-form-model
          :colon="false"
          labelAlign="right"
          class="detail-form"
          :model="form"
          ref="form"
          :labelCol="{ span: 6 }"
          :wrapperCol="{ span: 15 }"
          :rules="rules"
        >
          <a-row>
            <a-col :span="12">
              <!-- 候选人姓名 -->
              <a-form-model-item :label="$t('order.detail.name')" prop="name">
                <a-input
                  v-model="form.name"
                  :placeholder="$t('order.detail.name')"
                />
              </a-form-model-item>
              <!-- 候选人性别 -->
              <a-form-model-item
                :label="$t('order.detail.sex')"
                prop="genderId"
              >
                <a-select
                  :getPopupContainer="trigger => trigger.parentNode"
                  v-model="form.genderId"
                  :placeholder="$t('order.detail.sex')"
                  allowClear
                >
                  <a-select-option
                    v-for="op in baseParams.gender"
                    :key="op.key"
                    :value="op.key"
                  >
                    {{ op.value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!-- 上传附件 -->
            <a-col :span="12">
              <a-form-model-item
                :label="$t('order.detail.upload')"
                prop="fileList"
              >
                <EcUpload
                  formName="file"
                  action="/api/sys/file/upload"
                  :fileList.sync="fileList"
                  :params="{}"
                  @change="onFileChange"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <!-- 候选人手机号码 -->
              <a-form-model-item
                :label="$t('backCheck.hphone')"
                prop="phoneValue"
                required
              >
                <EcMobile
                  :value="form.phoneValue"
                  @change="onMobileChange"
                  :placeholder="$t('backCheck.hphone')"
                />
              </a-form-model-item>
              <!-- 候选人证件号码 -->
              <a-form-model-item
                :label="$t('echeck.table.idCard')"
                prop="idNumbering"
              >
                <a-input
                  v-model="form.idNumbering"
                  :placeholder="$t('echeck.table.idCard')"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="type === CREATE_TYPE.QUICK">
              <!-- 授权方式 -->
              <a-form-model-item
                :label="$t('backCheck.authType')"
                prop="authorizationRequired"
              >
                <a-radio-group
                  v-model="form.authorizationRequired"
                  :options="baseParams.authorizationRequired"
                />
              </a-form-model-item>
              <a-row class="authorization-required-tips">
                <a-col
                  :offset="6"
                  :span="24"
                  v-if="form.authorizationRequired === true"
                >
                  <p class="ec-text-danger">
                    {{ $t("backCheck.authOnlineTip1") }}
                  </p>
                  <p class="ec-text-danger">
                    {{ $t("backCheck.authOnlineTip2") }}
                  </p>
                </a-col>
                <a-col
                  :offset="6"
                  :span="24"
                  v-if="form.authorizationRequired === false"
                >
                  <p class="ec-text-danger">
                    {{ $t("backCheck.authOfflineTip1") }}
                  </p>
                  <p class="ec-text-danger">
                    <i class="icon-tip"></i>
                    {{ $t("backCheck.authOfflineTip2") }}
                  </p>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row>
            <!-- 报告语言 -->
            <!--<a-col :span="12">
              <a-form-model-item
                :label="$t('order.detail.lang')"
                prop="reportLanguageId"
              >
                <a-select
                    :placeholder="$t('order.detail.lang')"
                    v-model="form.reportLanguageId"
                    allowClear
                >
                  <a-select-option
                      v-for="op in baseParams.reportLanguages"
                      :key="op.key"
                      :value="op.key"
                  >
                    {{ op.value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>-->
            <!-- 邮件语言 -->
            <!--<a-col :span="12">
              <a-form-model-item
                :label="$t('order.detail.langEmail')"
                prop="emailLanguageId"
              >
                <a-select
                  :placeholder="$t('order.detail.langEmail')"
                  v-model="form.emailLanguageId"
                  allowClear
                >
                  <a-select-option
                    v-for="op in baseParams.emailLanguages"
                    :key="op.key"
                    :value="op.key"
                  >
                    {{ op.value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>-->
          </a-row>
          <a-row> </a-row>
          <a-row>
            <!-- 入职时间 -->
            <a-col :span="12">
              <a-form-model-item
                :label="$t('order.detail.entry')"
                prop="expectedEntryTime"
              >
                <a-date-picker
                  :allowClear="true"
                  v-model="form.expectedEntryTime"
                  class="width"
                />
              </a-form-model-item>
            </a-col>
            <!-- 联系候选人 -->
            <a-col :span="12">
              <a-form-model-item
                :label="$t('order.detail.concat')"
                prop="isContact"
              >
                <a-radio-group
                  v-model="form.isContact"
                  :options="baseParams.isContact"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <!-- 候选人邮箱 -->
            <a-col :span="12">
              <a-form-model-item :label="$t('order.detail.email')" prop="email">
                <a-input
                  :placeholder="$t('order.detail.email')"
                  v-model="form.email"
                />
              </a-form-model-item>
            </a-col>
            <!-- 是否加急 -->
            <a-col :span="12" v-if="type === CREATE_TYPE.TRADITION">
              <a-form-model-item
                :label="$t('order.detail.urgent')"
                prop="urgentState"
              >
                <a-radio-group
                  v-model="form.urgentState"
                  :options="baseParams.urgentState"
                />
              </a-form-model-item>
            </a-col>
            <!-- 报告进度跟进 -->
            <!--<a-col :span="12">
              <a-form-model-item
                :label="$t('order.detail.process')"
                prop="progressFollowUp"
              >
                <a-radio-group
                  v-model="form.progressFollowUp"
                  :options="baseParams.progressFollowUp"
                />
              </a-form-model-item>
            </a-col>-->
          </a-row>
          <a-row>
            <a-col :span="12">
              <!-- 拟入职机构 -->
              <a-form-model-item
                :label="$t('order.detail.agency')"
                prop="intendedAgency"
              >
                <a-input
                  v-model="form.intendedAgency"
                  :placeholder="$t('order.detail.agency')"
                />
              </a-form-model-item>
              <!-- 工作地点 -->
              <a-form-model-item
                :label="$t('order.detail.address')"
                prop="workPlace"
              >
                <a-input
                  :placeholder="$t('order.detail.address')"
                  v-model="form.workPlace"
                />
              </a-form-model-item>
            </a-col>
            <!-- 招聘来源 -->
            <a-col :span="12">
              <InviteSource ref="sourceRef" />
            </a-col>
          </a-row>
          <a-row>
            <!-- 成本中心 -->
            <a-col :span="12">
              <a-form-model-item
                :label="$t('order.detail.costCenter')"
                prop="costCenter"
              >
                <a-input
                  :placeholder="$t('order.detail.costCenter')"
                  v-model="form.costCenter"
                />
              </a-form-model-item>
            </a-col>
            <!-- 备注 -->
            <a-col :span="12">
              <a-form-model-item :label="$t('order.detail.note')" prop="remark">
                <a-textarea
                  :placeholder="$t('order.detail.note')"
                  :auto-size="{ minRows: 9, maxRows: 9 }"
                  v-model="form.remark"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <!-- 按钮 -->
      <ECardButton
        :save="add"
        :batchSubmit="onBatchSubmit"
        download-url="/template/EmployeeInformationImportTemplate.xlsx"
        upload-url="/commonMgt/upload/candidate"
        :uploadData="{ orderType: CREATE_TYPE.TRADITION }"
        download-local
        :download-name="$t('backCheck.templateWorkOrder')"
        :columns="columns.slice(0, 9)"
      />
    </ECard>

    <!-- 表格 -->
    <ECTable :columns="columns" :data-source="dataList" :scroll="{ x: true }">
      <template slot="footerCustom">
        <div></div>
      </template>
      <template slot="isContact" slot-scope="text, record">
        {{ record.isContact ? $t("button.yes") : $t("button.no") }}
      </template>
      <template slot="authorizationRequired" slot-scope="text, record">
        {{
          record.authorizationRequired
            ? baseParams.authorizationRequired[0].label
            : baseParams.authorizationRequired[1].label
        }}
      </template>
      <template slot="phone" slot-scope="text, record">
        {{
          record.phoneDialingCode
            ? `+${record.phoneDialingCode} ${record.phone}`
            : record.phone
        }}
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record, index">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="edit(record, index)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="remove(index)"
          >
            <a-button type="link" class="table-btn ec-color-danger">
              <a-icon type="delete" class="ec-color-danger" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import InviteSource from "@/pages/home/components/InviteSource";
import ECTable from "@/components/ECTable";
import ECard from "@/components/ECard";
import ECardButton from "@/components/ECardButton";
import {
  DateFormat,
  FETCH_CODE,
  REG_EMAIL,
  REG_IDCARD,
  REG_MOBILE,
  RES_CODE
} from "@/config";
import { cloneDeep, trim } from "lodash";
import { showMsg } from "@/utils";
import EcUpload from "@/components/EcUpload.vue";
import moment from "moment";
import { getFakeId } from "@/utils/tools";
import { CREATE_TYPE } from "@/pages/home/order/config";
import EcMobile from "@/components/EcMobile.vue";
import { AuthorizationRequired } from "@/pages/home/config";
import {
  URGENT_STATES,
  YES_OR_NO
} from "@/pages/workspace/backgroundCheck/order/config";
import { langMixin } from "@/mixins";

const formEmpty = {
  genderId: undefined,
  name: "",
  phoneValue: {
    mobile: "",
    code: "86"
  },
  phone: "",
  phoneDialingCode: "", // 电话区号
  expectedEntryTime: null, // 入职日期
  email: "",
  isContact: true, // 是否联系候选人
  authorizationRequired: true, // 授权方式
  idNumbering: "", // 证件号码
  reportLanguageId: undefined, // 报告语言
  emailLanguageId: undefined, // 邮件语言
  urgentState: null, // 是否加急
  remark: "",
  progressFollowUp: null, // 报告跟进
  intendedAgency: "", // 拟入职机构
  workPlace: "", // 工作地点
  costCenter: "" // 成本中心
};

export default {
  name: "AddOrder",
  props: {
    type: {
      type: String,
      default: CREATE_TYPE.TRADITION
    }
  },
  computed: {
    CREATE_TYPE() {
      return CREATE_TYPE;
    },
    columns() {
      const _columns = [
        {
          title: this.$t("backCheck.cname"),
          dataIndex: "name",
          ellipsis: true,
          width: 120,
          align: "left"
        },
        {
          title: this.$t("backCheck.cidcard"),
          dataIndex: "idNumbering",
          ellipsis: true,
          width: 160,
          align: "left"
        },
        {
          title: this.$t("backCheck.cphone"),
          dataIndex: "phone",
          ellipsis: true,
          width: 140,
          align: "left",
          scopedSlots: { customRender: "phone" }
        },
        {
          title: this.$t("order.detail.email"),
          dataIndex: "email",
          ellipsis: true,
          width: 140,
          align: "left"
        },
        {
          title: this.$t("order.detail.agency"),
          dataIndex: "intendedAgency",
          ellipsis: true,
          width: 110,
          align: "left"
        },
        {
          title: this.$t("order.detail.address"),
          dataIndex: "workPlace",
          ellipsis: true,
          width: 100,
          align: "left"
        },
        {
          title: this.$t("order.detail.costCenter"),
          dataIndex: "costCenter",
          ellipsis: true,
          width: 100,
          align: "left"
        },
        {
          title: this.$t("order.detail.note"),
          dataIndex: "remark",
          ellipsis: true,
          width: 120,
          align: "left"
        },
        {
          title: this.$t("backCheck.authType"),
          dataIndex: "authorizationRequired",
          ellipsis: true,
          width: 110,
          align: "center",
          scopedSlots: { customRender: "authorizationRequired" }
        },
        {
          title: this.$t("order.detail.isCanConcat"),
          dataIndex: "isContact",
          ellipsis: true,
          width: 110,
          align: "center",
          scopedSlots: { customRender: "isContact" }
        },
        {
          title: this.$t("order.detail.urgent"),
          dataIndex: "urgentStateName",
          ellipsis: true,
          width: 90,
          align: "center"
        },
        // {
        //   title: this.$t("order.detail.lang"),
        //   dataIndex: "reportLanguageStr",
        //   ellipsis: true,
        //   width: 90,
        //   align: "center",
        // },
        // {
        //   title: this.$t("order.detail.langEmail"),
        //   dataIndex: "emailLanguageStr",
        //   ellipsis: true,
        //   width: 90,
        //   align: "center",
        // },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
          align: "center",
          fixed: "right"
        }
      ];
      if (this.type === CREATE_TYPE.QUICK) {
        _columns.splice(10, 1);
      }
      return _columns;
    },
    rules() {
      // 手机号+区号验证
      const validatePhone = (rule, value, callback) => {
        if (!value.code) {
          callback(this.$t("select") + this.$t("order.detail.phoneCode"));
          return;
        }
        if (!trim(value.mobile)) {
          callback(this.$t("input") + this.$t("order.detail.phone"));
          return;
        }
        // 仅验证中国大陆手机号
        if (value.code === "86" && !REG_MOBILE.test(trim(value.mobile))) {
          callback(
            this.$t("input") + this.$t("right") + this.$t("order.detail.phone")
          );
          return;
        }
        callback();
      };

      return {
        name: [
          {
            required: true,
            message: this.$t("input") + this.$t("order.detail.name")
          },
          {
            max: 50,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.name"),
              length: 50
            })
          }
        ],
        phoneValue: [{ validator: validatePhone }],
        // genderId: [
        //   { required: true, message: this.$t('select') + this.$t('order.detail.sex') },
        // ],
        // reportLanguageId: [
        //   { required: true, message: this.$t('select') + this.$t('order.detail.lang') },
        // ],
        // emailLanguageId: [
        //   { required: true, message: this.$t('select') + this.$t('order.detail.langEmail') },
        // ],
        // expectedEntryTime: [
        //   { required: true, message: this.$t('select') + this.$t('order.detail.entry') },
        // ],
        email: [
          {
            required: true,
            pattern: REG_EMAIL,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("order.detail.email")
          },
          {
            max: 100,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.email"),
              length: 100
            })
          }
        ],
        intendedAgency: [
          {
            max: 100,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.agency"),
              length: 100
            })
          }
        ],
        workPlace: [
          {
            max: 100,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.address"),
              length: 100
            })
          }
        ],
        remark: [
          {
            max: 255,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.note"),
              length: 255
            })
          }
        ],
        costCenter: [
          {
            max: 255,
            message: this.$t("maxInput", {
              name: this.$t("order.detail.costCenter"),
              length: 255
            })
          }
        ],
        idNumbering: [
          {
            required: this.type === CREATE_TYPE.QUICK,
            pattern: REG_IDCARD,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("backCheck.report.idCard")
          }
        ]
      };
    },
  },
  components: {
    EcMobile,
    EcUpload,
    InviteSource,
    ECTable,
    ECard,
    ECardButton
  },
  mixins: [langMixin],
  data() {
    return {
      form: { ...formEmpty },
      baseParams: {
        // 邮件语言
        emailLanguages: [],
        // 性别
        gender: [],
        // 重要程度
        importance: [],
        // 是否联系候选人
        isContact: [],
        // 报告跟进
        progressFollowUp: [],
        // 报告语言
        reportLanguages: [],
        // 紧急程度
        urgentState: [],
        // 授权方式
        authorizationRequired: [],
      },
      langList: [
        { dictCode: "zh-CN", dictName: "中文（简体）" },
        { dictCode: "en-US", dictName: "英语（美国）" },
        { dictCode: "ru-RU", dictName: "俄语" }
      ],
      dataList: [],
      selectedRowKeys: [],
      isUploadModal: false,
      editIndex: -1,
      fileList: []
    };
  },
  methods: {
    onValid() {
      if (!this.dataList.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("order.detail.empty")
        });
        return false;
      }
      if (this.editIndex > -1) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("echeck.detail.unsavedCandidate")
        });
        return false;
      }
      return this.dataList;
    },
    onReset() {
      this.form = { ...formEmpty };
      this.fileList = [];
      this.dataList = [];
    },
    add() {
      // 招聘来源
      const source = this.$refs.sourceRef.getValue();
      if (!source) {
        return;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          const record = {
            ...this.form,
            urgentStateName:
              this.baseParams.urgentState.find(
                it => it.value === this.form.urgentState
              )?.label ?? "",
            reportLanguageStr:
              this.baseParams.reportLanguages.find(
                it => it.key === this.form.reportLanguageId
              )?.value ?? "",
            emailLanguageStr:
              this.baseParams.emailLanguages.find(
                it => it.key === this.form.emailLanguageId
              )?.value ?? "",
            expectedEntryTime: this.form.expectedEntryTime
              ? moment(this.form.expectedEntryTime).format(DateFormat)
              : "",
            recruitSource: source.value,
            recruitSourceName: source.label,
            phone: this.form.phoneValue.mobile,
            phoneDialingCode: this.form.phoneValue.code,
            phoneValue: undefined
          };
          const uploadList = [];
          this.fileList
            .filter(file => {
              return file.response && file.response.data;
            })
            .forEach(file => {
              uploadList.push({
                key: file.response.data,
                value: file.name,
                description: file.type
              });
            });
          record.fileList = uploadList;
          if (!record.key) {
            record.key = getFakeId();
            this.dataList.push(record);
          } else {
            this.dataList[this.editIndex] = record;
            this.editIndex = -1;
          }
          // 清空表单
          this.form = { ...formEmpty };
          this.fileList = [];
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      // console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    batchRemove() {},
    remove(index) {
      this.dataList.splice(index, 1);
      if (index === this.editIndex) {
        this.editIndex = -1;
        this.form = { ...formEmpty };
        this.fileList = [];
      }
    },
    edit(item, index) {
      this.form = {
        ...cloneDeep(item),
        phoneValue: {
          mobile: item.phone,
          code: item.phoneDialingCode
        }
      };
      this.editIndex = index;
      if (item.recruitSource) {
        this.$refs.sourceRef.setValue(item.recruitSource);
      }
      if (item.fileList) {
        this.fileList = item.fileList.map((it, idx) => ({
          uid: `edit-${idx}`,
          response: {
            code: RES_CODE.SUCCESS.KEY,
            data: it.value,
            success: true
          },
          name: it.key,
          type: it.description,
          status: "done"
        }));
      }
    },
    async getList() {
      // const res = await entrustList()
      // if (res.flag !==FETCH_CODE.SUCCESS.KEY) {
      //   showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg })
      //   return
      // }
      // this.dataList = res.data?.records || []
    },
    getParams() {
      return { ...this.form, list: this.dataList };
    },
    async getBaseParams() {
      this.baseParams = {
        gender: cloneDeep(this.$store.state.back.genderList),
        isContact: this.listLabelFormatter(YES_OR_NO),
        urgentState: this.listLabelFormatter(URGENT_STATES),
        progressFollowUp: [],
        authorizationRequired: cloneDeep(AuthorizationRequired),
        reportLanguages: [],
        emailLanguages: [],
        importance: [],
      };
    },
    onFileChange() {},
    async onBatchSubmit(list) {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, msg: "succeed" };
      // 将列表赋值给当前候选人表格
      list.forEach(it => {
        const record = {
          key: getFakeId(),
          ...it
        };
        this.dataList.push(record);
      });
      return result;
    },
    onMobileChange(values) {
      this.form.phoneValue = { ...values };
    }
  },
  mounted() {
    this.getBaseParams();
    //  从store读取候选人
    if (this.$route.query.batch === "1") {
      const {
        orderCandidateList,
        echeckCandidateList
      } = this.$store.state.back;
      if (this.type === CREATE_TYPE.TRADITION && orderCandidateList) {
        this.dataList = orderCandidateList;
      }
      if (this.type === CREATE_TYPE.QUICK && echeckCandidateList) {
        this.dataList = echeckCandidateList;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.detail-form {
}
.authorization-required-tips {
  width: 100%;
  overflow: hidden;

  p {
    margin-bottom: 5px;
    position: relative;
  }

  .icon-tip {
    display: inline-block;
    width: 16px;
    position: absolute;
    z-index: 1;
    left: -21px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
