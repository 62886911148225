import api from "@/utils/fetch";

// 添加到场记录
export async function addInterview(params) {
  return await api.post("/interview/addInterview", params);
}

// 批量添加到场记录
export function addInterviewBatch(params) {
  return api.post("/interview/batch/addInterview", params);
}

// 到场记录列表
export async function interviewList(params) {
  return await api.get("/interview/interviewList", params);
}

// 到场记录详情
export async function interviewDetail(params) {
  return await api.get("/interview/interviewDetail", params);
}

// 易查宝套餐列表
export async function echeckList(params) {
  return await api.get("/echeck/template/module/list", params);
}

// 获取创建订单的基本信息(易查宝) 已经废弃请勿使用
export function infoBeforeCreate(params) {
  return api.get("/echeck/infoBeforeCreate", params);
}

// 提交易查宝订单
export function submitOrder(params) {
  return api.post("/echeck/submitOrder", params);
}

/**
 * 单个文件下载
 * @param params
 * @param {string} params.fileNo
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function singleFileDownload(params) {
  return await api.download("/commonMgt/download", params, { method: 'GET' });
}

/**fileNos
 * 批量下载文件
 * @param params
 * @param {string} params.fileNos
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function downloadZip(params) {
  return api.download("/commonMgt/downloadZip", params, { method: "GET" });
}
